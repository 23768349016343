.analytics {
  min-height: 80vh;
  padding-bottom: 60px;
}

.flashlight1 {
  position: fixed;
  top: 0;
  left: -300px;
  width: 1338px;
  height: 1338px;
  border-radius: 1338px;
  opacity: 0.25;
  background: radial-gradient(50% 50% at 50% 50%, #e60b0b 0%, #e72a4600 100%);
  z-index: -1;
}

.flashlight2 {
  position: fixed;
  top: -900px;
  right: -600px;
  width: 1338px;
  height: 1338px;
  border-radius: 1338px;
  opacity: 0.25;
  background: radial-gradient(50% 50% at 50% 50%, #e60b0b 0%, #e72a4600 100%);
  z-index: -1;
}

.title {
  margin-top: 150px;
  margin-bottom: 15px;

  color: #fff;
  font-family: Orto;
  font-size: 72px;
  font-weight: 800;
  line-height: 90px;
}

.image_wrap {
  /* height: calc(100vh - 120px - 191px); */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 10px;

  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 1px;
  text-align: center;
}

.image {
  max-width: 1200px;
  width: 100%;
  height: 604px;
  background-image: url('../../img/analitic.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

@media (max-width: 1280px) {
  .main {
    padding-bottom: 90px;
  }

  .title {
    margin-top: 105px;
    margin-bottom: 90px;
    font-size: 54px;
    line-height: 64px;
  }
}

@media (max-width: 1024px) {
  .title {
    font-size: 43px;
    line-height: 54px;
    margin-top: 70px;
    margin-bottom: 70px;
  }
}

@media (max-width: 768px) {
  .title {
    font-size: 32px;
    line-height: normal;
    margin-top: 50px;
    margin-bottom: 50px;
  }
}
