.deptmain {
  position: relative;
  width: 100%;
  max-width: 1278px;
}

/* .deptmain::after {
  position: absolute;
  content: '';
  top: 590px;
  right: 200px;
  width: 1340px;
  height: 1340px;
  background: radial-gradient(50% 50% at 50% 50%, #e60b0b 0%, #e72a4600 100%);
  opacity: 0.25;
  z-index: -1;
} */

.deptmain_container {
  display: flex;
  align-items: flex-start;
  gap: 34px;
}

.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: var(--color);
  margin-bottom: 50px;
}

.slide_previews_list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 34px;
  margin-bottom: 160px;
}

.btn_add {
  position: absolute;
  right: 0;
  opacity: 0.2;
  transition: opacity 0.4s ease-in-out;
}

.btn_add:hover {
  opacity: 1;
}

.overlay_show {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #0d0d1e80;
  backdrop-filter: blur(15px);
  z-index: 101;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: transform 0.2s ease-in-out, opacity 0.6s ease-in-out;
}

.overlay_hide {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #0d0d1e80;
  backdrop-filter: blur(15px);
  z-index: 101;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transform: scale(0);
  transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

@media (max-width: 1570px) {
  .btn_add {
    top: 0;
  }

  .slide_previews_list {
    grid-template-columns: repeat(2, 1fr);
    gap: 54px;
  }
}

@media (max-width: 1120px) {
  .slide_previews_list {
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
  }
}
