.mobmenu {
  display: none;
}

.wrap_mob {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 390px;
  height: 100vh;
  top: 0;
  right: -125%;
  padding: 30px;
  background-color: #0d0d1ec4;
  backdrop-filter: blur(15px);
  transition: right 0.4s ease-in-out;
  z-index: 102;
}

.wrap_mob_open {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 390px;
  height: 100vh;
  top: 0;
  right: 0;
  padding: 30px;
  background-color: #0d0d1ec4;
  backdrop-filter: blur(15px);
  transition: right 0.4s ease-in-out;
  z-index: 102;
}

.btn_open_mobmenu {
  display: block;
  width: 24px;
  height: 24px;
  padding: 16px;
  background-image: url('../../img/open-menu.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;
  margin-top: 44px;
  margin-left: -73px;
}

.btn_open_mobmenu:active {
  opacity: 0.6;
}

.close {
  display: block;
  position: absolute;
  width: 24px;
  height: 24px;
  top: 28px;
  right: 28px;
  padding: 3px;
  background-image: url('../../img/close.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;
  transition: opacity 0.4s ease-in;
}

.close:hover {
  opacity: 0.6;
}

.menu_list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 36px;
  margin-bottom: 30px;
}

.menu_item {
  position: relative;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: var(--color);
}

.menu_item_mob {
  display: block;
  margin-bottom: 70px;
  font-size: 16px;
  line-height: 20px;
}

.menu_list_inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  margin-bottom: 10px;
}

.menu_link {
  cursor: pointer;
}

.item_hover {
  position: absolute;
  top: 32px;
  left: 0;
  width: 0;
  height: 1px;
  opacity: 0;
  background-color: var(--color);
  transition: width 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

.menu_item:hover .item_hover {
  width: 100%;
  opacity: 1;
}

.btn_pers_account_mob {
  align-items: center;
  white-space: nowrap;
  width: 200px;
  height: 60px;
  border: 1px solid var(--color);
  border-radius: 30px;
  padding: 16px 28px;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.btn_pers_account_mob:hover {
  color: var(--background);
  background-color: var(--color);
}

.copyright {
  display: flex;
  width: max-content;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 40px;
}

.copyright p {
  font-weight: 500;
  font-size: 11px;
  line-height: 22px;
  text-align: left;
  color: #ffffff;
  opacity: 0.3;
}

.copyright a {
  font-weight: 500;
  font-size: 11px;
  line-height: 22px;
  text-decoration: underline;
}

@media (max-width: 1220px) {
  .mobmenu {
    display: block;
    position: fixed;
    width: 0;
    height: 100vh;
    top: 0;
    right: 0;
    /* border-left: 1px solid #ff0000; */
    z-index: 101;
  }
}

@media (max-width: 900px) {
  .btn_open_mobmenu {
    width: 16px;
    height: 16px;
    padding: 8px;
    margin-top: 22px;
    margin-left: -36px;
  }

  .close {
    width: 16px;
    height: 16px;
    padding: 8px;
  }
}

@media (max-width: 700px) {
  .wrap_mob {
    width: 320px;
  }

  .wrap_mob_open {
    width: 320px;
  }
}
