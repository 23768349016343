.footer {
  position: relative;
  height: 190px;
}

.mark {
  position: absolute;
  width: 100%;
  height: 100px;
}

.footer_content {
  width: 100%;
  position: absolute;
  contain: paint;
  z-index: 10;
  transition: transform 0.9s ease-in-out, opacity 0.9s ease-in-out;
  /* pointer-events: none; */
}

.footerReflection {
  position: absolute;
  content: '';
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 1340px;
  height: 270px;
  /* background: radial-gradient(50% 50% at 50% 50%, #e60b0b 0%, #e72a4600 100%); */
  background-color: red;
  filter: blur(200px);
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  opacity: 0.25;
  z-index: 100;
  pointer-events: none;
}

.footer_container {
  height: 190px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 20px;
  padding-bottom: 30px;
}

.menu_list {
  /* position: absolute; */
  display: flex;
  align-items: center;
  gap: 24px;
  width: max-content;
  z-index: 1;
}

.menu_item {
  position: relative;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  white-space: nowrap;
  color: #3d3d4b;
  transition: color 0.4s ease-in-out;
}

.menu_item:hover {
  color: #fff;
}

.item_hover {
  position: absolute;
  top: 26px;
  left: 0;
  width: 0;
  height: 1px;
  opacity: 0;
  background-color: var(--color);
  transition: width 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

.menu_item:hover > .item_hover {
  width: 100%;
  opacity: 1;
}

.copyright {
  width: max-content;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 40px;
}

.copyright_text p {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: right;
  color: #ffffff;
  opacity: 0.3;
}

.copyright_text_mob {
  display: none;
}

.copyright_text_mob p {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: right;
  color: #ffffff;
  opacity: 0.3;
}

.logo_artmax {
  width: 130px;
  height: 30px;
  background-image: url('../../img/logo_artmax.svg');
  opacity: 0.3;
  transition: opacity 0.4s ease-in-out, filter 0.8s ease-in-out;
}

.logo_artmax:hover {
  opacity: 1;
  filter: drop-shadow(0 0 4px red);
}

@media (max-width: 1550px) {
  .footerReflection {
    width: 1040px;
  }
}

@media (max-width: 1330px) {
  .footerReflection {
    width: 840px;
  }

  .footer_container {
    padding-top: 75px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 50px;
  }

  .copyright {
    width: 100%;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    gap: 40px;
  }

  .logo_artmax {
    order: 2;
  }
}

@media (max-width: 1160px) {
  .menu_list {
    gap: 10px;
  }
}

@media (max-width: 900px) {
  .footerReflection {
    width: 640px;
  }

  .footer_container {
    padding-top: 90px;
    padding-bottom: 30px;
  }

  .menu_list {
    display: none;
  }

  .copyright {
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
  }

  .copyright_text {
    display: none;
  }

  .copyright_text_mob {
    display: block;
  }

  .copyright_text_mob p {
    margin-bottom: 10px;
    text-align: center;
  }
}

@media (max-width: 640px) {
  .footerReflection {
    width: 490px;
  }
}

@media (max-width: 500px) {
  .copyright_text_mob p {
    font-size: 12px;
    line-height: 15px;
  }

  .footerReflection {
    width: 240px;
  }
}
