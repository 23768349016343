.overlay_show {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #0d0d1e80;
  backdrop-filter: blur(15px);
  z-index: 101;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: transform 0.2s ease-in-out, opacity 0.6s ease-in-out;
}

.overlay_hide {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #0d0d1e80;
  backdrop-filter: blur(15px);
  z-index: 101;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transform: scale(0);
  transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

.card {
  width: 410px;
  border-radius: 20px;
  border: 1px solid #3d3d4b;
  background: #0d0d1e;
  padding: 25px;
}

.header {
  padding-bottom: 15px;
  border-bottom: 1px solid #3d3d4b;
  margin-bottom: 20px;
}

.wrap_1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 10px;

  color: #9b9ba4;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}

.title {
  text-transform: uppercase;
  margin-bottom: 10px;

  color: #fff;
  font-size: 28px;
  font-weight: 700;
  line-height: normal;
}

.brand {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
}

.body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.list_info {
  max-width: 125px;
  width: 100%;
}

.item_info_icon {
  display: flex;
  align-items: center;
  gap: 7px;
  margin-bottom: 12px;

  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}

.item_info {
  margin-bottom: 15px;
}

.item_info:last-child {
  margin-bottom: 0;
}

.item_info p {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}

.item_info span {
  color: #3d3d4b;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 5px;
}

.list_links {
  max-width: 220px;
  width: 100%;
  border-radius: 20px;
  border: 1px solid #3d3d4b;
  background: #0d0d1e;
  padding: 20px;
}

.item_link {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  margin-bottom: 15px;
  cursor: pointer;
}

.item_link_link {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  cursor: pointer;
}

.item_link_notlink {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  cursor: default;
}

.item_link:last-child {
  margin-bottom: 0;
}

.item_link p {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  opacity: 0.8;
  transition: opacity 0.4s ease-in-out;
}

.icon {
  min-width: 24px;
  width: 24px;
  height: 24px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.2;
  transition: opacity 0.4s ease-in-out;
}

.item_link:hover > p {
  opacity: 1;
}

.item_link:hover > .item_link_link p {
  opacity: 1;
}

.item_link:hover > .icon {
  opacity: 1;
}

.item_link:hover > .item_link_link .icon {
  opacity: 1;
}

.opacity02 {
  opacity: 0.2 !important;
}

.specific {
  background-image: url('../../../../../../img/icon_requisites.svg');
}

.passport {
  background-image: url('../../../../../../img/icon_pdf.svg');
}

.passport_no {
  background-image: url('../../../../../../img/icon_no_pdf.svg');
}

.details {
  background-image: url('../../../../../../img/icon_details.svg');
}

.address {
  background-image: url('../../../../../../img/icon_address.svg');
}

.payments {
  background-image: url('../../../../../../img/icon_calendar.svg');
}

.icon_load {
  background-image: url('../../../../../../img/icon_load.svg');
}

.icon_security {
  background-image: url('../../../../../../img/icon_security.svg');
}

.opacity1 {
  opacity: 1;
}

@media (max-width: 1120px) {
  .card {
    width: 340px;
  }

  .list_links {
    max-width: 150px;
  }

  .item_link p {
    width: fit-content;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media (max-width: 970px) {
    .card {
      width: 500px;
    }

    .body {
      display: block;
    }

    .list_info {
      max-width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 5px 20px;
      flex-wrap: wrap;
      border-bottom: 1px solid #3d3d4b;
    }

    .item_info_icon {
      width: 100%;
    }

    .item_info:last-child {
      margin-bottom: 15px;
    }

    .list_links {
      max-width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 5px 20px;
      border: none;
      padding-right: 0;
      padding-left: 0;
    }

    .item_link:last-child {
      margin-bottom: 15px;
    }
  }
}
