.sectionextendlife {
  position: relative;
}

.sectionextendlife::after {
  display: none;
  position: absolute;
  content: '';
  top: 160px;
  right: -340px;
  width: 740px;
  height: 740px;
  background: radial-gradient(50% 50% at 50% 50%, #e60b0b 0%, #e72a4600 100%);
  opacity: 0.25;
  z-index: -1;
}

.container {
  max-width: 1680px;
  padding: 0 40px;
  margin: 0 auto;
}

.block_background {
  width: calc(100% - 40px);
  position: relative;
  padding-top: 80px;
  padding-bottom: 60px;
  background: #190434;
  border-radius: 30px;
  overflow: hidden;
}

.illuminat {
  position: absolute;
  top: -500px;
  right: -420px;
  width: 1040px;
  height: 1040px;
  background: radial-gradient(50% 50% at 50% 50%, #e60b0b 0%, rgba(231, 42, 70, 0) 100%);
  opacity: 0.25;
  z-index: 0;
}

.decorcircles {
  position: absolute;
  top: -500px;
  right: -420px;
  width: 1040px;
  height: 1040px;
  background-image: url('../../img/decorcircles.svg');
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 0;

  animation-name: decorcirclesizeReverse;
  animation-duration: 1.4s;
  animation-fill-mode: forwards;
}

.animation_statrt {
  position: absolute;
  content: '';
  top: -500px;
  right: -420px;
  width: 1040px;
  height: 1040px;
  background-image: url('../../img/decorcircles.svg');
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 0;

  animation-name: decorcirclesize;
  animation-duration: 1.4s;
  animation-fill-mode: forwards;
}

@keyframes decorcirclesize {
  30% {
    transform: scale(96%);
  }

  40% {
    transform: scale(140%);
  }

  70% {
    transform: scale(146%);
  }

  100% {
    transform: scale(140%);
  }
}

@keyframes decorcirclesizeReverse {
  0% {
    transform: scale(140%);
  }

  30% {
    transform: scale(146%);
  }

  70% {
    transform: scale(96%);
  }

  100% {
    transform: scale(100%);
  }
}

.flex_content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.wrap_content h2 {
  font-family: 'Orto';
  font-weight: 800;
  font-size: 54px;
  line-height: 68px;
  color: #ffffff;
  margin-bottom: 50px;
}

.wrap_content_p {
  max-width: 670px;
  width: 100%;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #ffffff;
  opacity: 0.6;
  margin-bottom: 30px;
}

.btn_order {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0;
  padding: 20px 0;
  width: 300px;
  background-color: #e60b0b;
  border-radius: 50px;
  text-transform: uppercase;
  transition: gap 0.4s ease-in-out, box-shadow 0.4s ease-in-out;
}

.btn_order_mob {
  display: none;
  align-items: center;
  justify-content: center;
  gap: 0;
  padding: 20px 0;
  width: 300px;
  background-color: #e60b0b;
  border-radius: 50px;
  text-transform: uppercase;
  transition: gap 0.4s ease-in-out, box-shadow 0.4s ease-in-out;
  z-index: 1;
}

.btn_order p {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  opacity: 1;
  margin-bottom: 0;
}

.btn_order_mob p {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  opacity: 1;
  margin-bottom: 0;
}

.btn_img {
  width: 0;
  transition: width 0.4s ease-in-out;
}

.btn_order:hover {
  gap: 22px;
  box-shadow: 0px 12px 50px #e60b0b59;
}

.btn_order_mob:hover {
  gap: 22px;
  box-shadow: 0px 12px 50px #e60b0b59;
}

.btn_order:hover .btn_img {
  width: 20px;
  transition: width 0.4 ease-in-out;
}

.btn_order_mob:hover .btn_img {
  width: 20px;
  transition: width 0.4 ease-in-out;
}

.wrap_stat {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 25px;
}

.stat_wrapper {
  display: flex;
  gap: 25px;
}

.stat_details {
  width: 720px;
  padding: 22px 40px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  background: #ffffff0d;
  backdrop-filter: blur(5px);
  border-radius: 20px;
}

.stat_details p {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #864391;
}

.stat_details span {
  font-family: 'Orto', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 50px;
  line-height: 68px;
  color: #fff;
}

.stat_clients {
  padding: 22px 40px;
  display: flex;
  justify-content: space-between;
  gap: 80px;
  background: #ffffff0d;
  backdrop-filter: blur(5px);
  border-radius: 20px;
}

.stat_clients p {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #864391;
}

.stat_clients span {
  font-family: 'Orto', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 50px;
  line-height: 68px;
  color: #fff;
}

@media (max-width: 1560px) {
  .stat_details {
    width: max-content;
  }
}

@media (max-width: 1400px) {
  .flex_content {
    align-items: flex-start;
  }

  .stat_details {
    width: 450px;
    flex-direction: column;
    gap: 10px;
  }

  .stat_wrapper {
    flex-direction: column;
  }
}

@media (max-width: 1220px) {
  .stat_details {
    width: max-content;
  }

  .stat_details span {
    font-size: 40px;
    line-height: 54px;
  }

  .stat_clients span {
    font-size: 40px;
    line-height: 54px;
  }
}

@media (max-width: 1160px) {
  .stat_details {
    padding: 16px 30px;
  }

  .wrap_content h2 {
    font-size: 36px;
    line-height: 52px;
    margin-bottom: 55px;
  }

  .wrap_content_p {
    width: 416px;
    margin-bottom: 25px;
  }
}

@media (max-width: 900px) {
  .sectionextendlife::after {
    display: block;
  }

  .container {
    padding: 0 20px;
  }

  .block_background {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .flex_content {
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    max-width: 550px;
    margin: 0 auto;
  }

  .wrap_content {
    width: 100%;
  }

  .wrap_content h2 {
    font-size: 22px;
    line-height: 30px;
    text-align: center;
    margin-bottom: 10px;
  }

  .wrap_content_p {
    display: none;
  }

  .btn_order {
    display: none;
  }

  .btn_order_mob {
    display: flex;
    margin: 0 auto;
  }

  .btn_order_mob p {
    font-size: 14px;
  }

  .stat_details {
    flex-direction: row;
    padding: 20px 20px 15px;
    width: 100%;
    max-width: 100%;
  }

  .stat_details p {
    font-size: 14px;
    line-height: 20px;
  }

  .stat_details span {
    font-size: 30px;
    line-height: 41px;
  }

  .stat_wrapper {
    flex-direction: row;
    width: 100%;
    margin-bottom: 10px;
  }

  .stat_clients {
    width: 50%;
    padding: 20px 20px 15px;
  }

  .stat_clients p {
    font-size: 14px;
    line-height: 20px;
  }

  .stat_clients span {
    font-size: 30px;
    line-height: 41px;
  }

  .illuminat {
    width: 710px;
    height: 710px;
    top: 210px;
    right: -370px;
  }

  .decorcircles {
    width: 710px;
    height: 710px;
    top: 210px;
    right: -370px;
  }

  .animation_statrt {
    width: 710px;
    height: 710px;
    top: 210px;
    right: -370px;
  }
}

@media (max-width: 690px) {
  .block_background {
    width: calc(100% - 20px);
  }
}

@media (max-width: 560px) {
  .stat_details {
    flex-direction: column;
    padding-bottom: 20px;
  }

  .stat_clients {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .stat_details {
    min-width: 265px;
  }

  .stat_wrapper {
    flex-direction: column;
  }

  .decorcircles {
    top: 330px;
  }

  .animation_statrt {
    top: 330px;
  }

  .btn_order_mob {
    width: 100%;
  }
}
