.sectionconsultation {
  position: relative;
}

.mark {
  position: absolute;
  top: -70px;
}

.block_background {
  width: calc(100% - 40px);
  padding-top: 100px;
  padding-bottom: 100px;
  background: #06061c;
  border-radius: 30px;
}

.flex_content {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

.wrap_scroll_count {
  width: calc(50% - 10px);
}

.count_screen {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 50px 40px;
  max-width: 700px;
  background: linear-gradient(301deg, #1d1029 34%, #1c0d299c 77%);
  border-radius: 20px;
  margin-bottom: 25px;
}

.count_screen h4 {
  width: max-content;
  font-family: Crystalrevived;
  font-weight: 400;
  font-size: 163px;
  line-height: 163px;
  letter-spacing: 1px;
  font-variant-numeric: tabular-nums;
  color: var(--color);
  overflow: hidden;
}

.count_screen span {
  opacity: 0.2;
}

.count_down {
  width: 90px;
  transform: rotate(180deg);
  opacity: 0.06;
  transition: opacity 0.4s ease-in-out;
}

.count_down_active {
  width: 90px;
  transform: rotate(180deg);
  opacity: 1;
  transition: opacity 0.4s ease-in-out;
}

.count_down:hover {
  opacity: 1;
}

.count_up {
  width: 90px;
  opacity: 0.06;
  transition: opacity 0.4s ease-in-out;
}

.count_up_active {
  width: 90px;
  opacity: 1;
  transition: opacity 0.4s ease-in-out;
}

.count_up:hover {
  opacity: 1;
}

.wrap_count_control {
  max-width: 700px;
}

.count_control {
  width: 570px;
  height: 266px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  justify-content: space-between;
  justify-items: center;
  gap: 10px;
  margin: 0 auto;
}

.count_control img {
  opacity: 0.3;
  transition: opacity 0.4s ease-in-out;
}

.count_control img:hover {
  opacity: 0.6;
}

.wrap_form {
  width: calc(50% - 10px);
}

.wrap_form h2 {
  font-family: 'Orto';
  font-weight: 800;
  font-size: 54px;
  line-height: 68px;
  color: #ffffff;
  margin-top: -8px;
  margin-bottom: 30px;
}

.wrap_content_p {
  max-width: 670px;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #ffffff;
  opacity: 0.6;
  margin-bottom: 30px;
}

.fieldset {
  border: none;
  max-width: 500px;
  width: 100%;
  padding: 0;
}

.input_wrap {
  width: 100%;
  max-width: 500px;
  position: relative;
}

.input_mail {
  display: block;
  width: 100%;
  padding: 18px 30px 15px 30px;
  background-color: transparent;
  border: 1px solid #ffffff30;
  border-radius: 30px;
  margin-bottom: 30px;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  color: #fff;
}

.input_phone {
  display: block;
  width: 100%;
  padding: 18px 30px 15px 45px;
  background-color: transparent;
  border: 1px solid #ffffff30;
  border-radius: 30px;
  margin-bottom: 30px;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  color: #fff;
}

.input_mail::placeholder {
  font-weight: 700;
  font-size: 16px;
  color: #fff;
  opacity: 0.6;
  text-transform: uppercase;
}

.input_phone::placeholder {
  font-weight: 700;
  font-size: 16px;
  color: #fff;
  opacity: 0.6;
  text-transform: uppercase;
}

/* ============================= */
.note_visible {
  position: absolute;
  top: -22px;
  left: 0;
  padding: 0 5px 4px 5px;
  height: 22px;
  overflow: hidden;
  opacity: 1 !important;

  color: #e60b0b !important;
  font-family: NotoSans !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  transition: height 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

.note_empty {
  position: absolute;
  top: -22px;
  left: 0;
  padding: 0 5px 4px 5px;
  height: 0;
  overflow: hidden;
  opacity: 0;

  color: #e60b0b !important;
  font-family: NotoSans !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  transition: height 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

.note_hide {
  position: absolute;
  top: -22px;
  left: 0;
  padding: 0 5px 4px 5px;
  height: 0;
  overflow: hidden;
  opacity: 0;

  color: #e60b0b !important;
  font-family: NotoSans !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  transition: height 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

/* .label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  width: 100%;
  border-radius: 30px;
  border: 1px solid #3d3d4b;
  padding: 18px 30px;
  background-color: transparent;
} */

.select {
  position: absolute;
  top: 21px;
  left: 22px;
  width: max-content;
  color: #fff;
  border-radius: 4px;
  background-color: transparent;
  border: none;
  appearance: none;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

/* ============================= */

.error {
  display: none;
  position: absolute;
  top: 64px;
  font-weight: 600;
  font-size: 14px;
  color: #ff5454;
  padding: 0 0 0 10px;
}

.fieldset_assent {
  border: none;
  max-width: 500px;
  width: 100%;
  padding: 0;
  margin-bottom: 30px;
}

.fieldset_assent p {
  border: none;
  max-width: 450px;
  width: 100%;

  font-family: 'NotoSans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
  opacity: 0.6;
}

.label_check {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.input_check {
  margin-top: 5px;
  position: relative;

  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  width: 24px;
  height: 24px;
  border: 1px solid #545454;
  border-radius: 5px;

  transform: translateY(0);
  background-color: transparent;
}

.input_check::before {
  position: absolute;
  content: '';
  inset: 0;
  transform: scale(0);
  background: url('../../img/checked.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 14px 14px;
  transition: transform 0.3s ease-in-out;
}

.input_check:checked::before {
  transform: scale(1);
}

.input_check:focus {
  outline: 1px solid #555555;
  outline-offset: -1px;
}

.btn_order {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0;
  padding: 20px 0;
  width: 300px;
  background-color: #e60b0b;
  border-radius: 50px;
  text-transform: uppercase;
  transition: gap 0.4s ease-in-out, box-shadow 0.4s ease-in-out;
}

.btn_order p {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  opacity: 1;
  margin-bottom: 0;
}

.btn_img {
  width: 0;
  transition: width 0.4s ease-in-out;
}

.btn_order:hover {
  gap: 22px;
  box-shadow: 0px 12px 50px #e60b0b59;
}

.btn_order:hover .btn_img {
  width: 20px;
  transition: width 0.4 ease-in-out;
}

@media (max-width: 1370px) {
  .count_screen h4 {
    width: max-content;
    font-size: 120px;
    line-height: 120px;
    letter-spacing: 1px;
  }
}

@media (max-width: 1300px) {
  .count_screen {
    padding: 25px 20px;
    max-width: 520px;
    margin-bottom: 25px;
  }

  .count_down {
    width: 80px;
  }

  .count_up {
    width: 80px;
  }

  .count_down_active {
    width: 80px;
  }

  .count_up_active {
    width: 80px;
  }

  .wrap_count_control {
    max-width: 520px;
  }

  .count_control {
    width: max-content;
    height: auto;
  }

  .count_control img {
    width: 90px;
  }
}

@media (max-width: 1250px) {
  .flex_content {
    gap: 35px;
  }

  .wrap_scroll_count {
    width: 40%;
  }

  .wrap_form {
    width: auto;
  }

  .count_screen h4 {
    width: max-content;
    font-size: 80px;
    line-height: 80px;
  }
}

@media (max-width: 1160px) {
  .flex_content {
    flex-direction: column;
    align-items: center;
  }

  .count_control {
    display: none;
  }

  .count_screen {
    width: 465px;
  }

  .count_screen h4 {
    width: 270px;
    font-size: 120px;
    line-height: 120px;
  }

  .wrap_scroll_count {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .wrap_form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .wrap_form h2 {
    font-size: 32px;
    line-height: 64px;
  }

  .wrap_content_p {
    text-align: center;
  }

  .input_check {
    margin-right: 15px;
  }

  .btn_order {
    width: 100%;
    max-width: 480px;
  }
}

@media (max-width: 900px) {
  .block_background {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .wrap_form {
    width: 100%;
    max-width: 540px;
  }

  .wrap_form h2 {
    font-size: 22px;
    line-height: 30px;
  }

  .wrap_content_p {
    font-size: 14px;
    line-height: 20px;
  }

  .btn_order p {
    font-size: 14px;
    line-height: 20px;
  }
}

@media (max-width: 690px) {
  .block_background {
    width: calc(100% - 20px);
  }
}

@media (max-width: 560px) {
  .block_background {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .count_screen {
    padding: 25px 20px;
    max-width: 520px;
    margin-bottom: 25px;
  }

  .count_screen {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 15px;
    border-radius: 8px;
    margin-bottom: 0;
  }

  .count_screen h4 {
    width: 160px;
    font-size: 65px;
    line-height: 65px;
  }

  .count_down {
    width: 44px;
  }

  .count_up {
    width: 44px;
  }

  .count_down_active {
    width: 44px;
  }

  .count_up_active {
    width: 44px;
  }

  .wrap_form h2 {
    text-align: center;
    margin-bottom: 20px;
  }
}
