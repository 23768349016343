.btn_back {
  margin: 160px auto 20px auto;
  display: block;
  width: 54px;
  height: 54px;
  border: 1px solid #fff;
  border-radius: 50%;
  background-image: url('../../../../img/arrow_left.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 26px 24px;
  opacity: 0.2;
  transition: opacity 0.4s ease-in-out;
}

.btn_back:hover {
  opacity: 1;
}

.title {
  margin-bottom: 20px;

  color: #fff;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
}

.description {
  margin-bottom: 25px;
  max-width: 415px;
  margin: 0 auto 40px;

  color: #9b9ba4;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.close_btn {
  position: absolute;
  top: 40px;
  right: 40px;
  display: block;
  width: 48px;
  height: 48px;
  background-image: url('../../../../img/btn_close2.svg');
  background-repeat: no-repeat;
  background-position: center;
}

.reg_autor_btns {
  margin: 0 auto;
  max-width: 420px;
  width: 100%;
  padding: 10px;
  border-radius: 40px;
  background-color: #000015;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 30px;
}

.reg_autor_btn {
  color: #3d3d4b;
  padding: 20px 40px;
  border-radius: 30px;
  background-color: #000015;
  transition: background-color 0.4s ease-in-out;
}

.reg_autor_btn:hover {
  background-color: #06061c;
  transition: background-color 0.4s ease-in-out;
}

.reg_autor_btn_active {
  color: #fff;
  padding: 20px 40px;
  border-radius: 30px;
  background-color: #06061c;
}

.pass_rec_form {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin: 0 auto;
  max-width: 420px;
  width: 100%;
  margin-bottom: 30px;
}

.input_wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.input_code {
  width: 58px;
  border-radius: 20px;
  border: 1px solid #3d3d4b;
  background-color: transparent;
  padding: 20px 18px;

  color: #fff;
  font-size: 32px;
  font-weight: 400;
  line-height: normal;
}

.input_code:focus {
  outline: none;
}

.input_code::placeholder {
  color: #9b9ba4;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.note_code {
  padding: 0 5px 4px 5px;
  height: 22px;
  overflow: hidden;
  opacity: 1;
  text-align: center;

  color: #e60b0b;
  font-family: NotoSans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  transition: height 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

.note_hide {
  padding: 0 5px 4px 5px;
  height: 0;
  overflow: hidden;
  opacity: 0;

  color: #e60b0b;
  font-family: NotoSans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  transition: height 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

.button_submit {
  width: 100%;
  padding: 20px 30px;
  border-radius: 50px;
  background-color: #522381;
  transition: background-color 0.4s ease-in-out;
}

.button_submit:hover {
  background-color: #1f0b32;
}

.button_submit:disabled {
  width: 100%;
  padding: 20px 30px;
  border-radius: 50px;
  color: #8d8888;
  background-color: #42364e;
  cursor: default;
}

/* 
.select_wrap {
  max-width: 400px;
  width: 100%;
  height: 60px;
  border: 1px solid #ffffff33;
  border-radius: 30px;
  padding: 10px 20px;
  background-color: #0d0d1e;
  margin-bottom: 25px;
} */

.label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  width: 100%;
  border-radius: 30px;
  border: 1px solid #3d3d4b;
  padding: 18px 30px;
  background-color: transparent;
}

.select {
  width: max-content;
  color: #fff;
  border-radius: 4px;
  background-color: transparent;
  border: none;

  appearance: none;
  /* background-image: url('../../../../img/arrow_down2.svg');
  background-repeat: no-repeat;
  background-position: right 0 top 55%;
  background-size: 10px 6px; */

  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.select:focus {
  outline: none;
  border: none;
}

.select option {
  border-radius: none;
  background-color: #1c1c2c;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #9b9ba4;
}

.button_forgot {
  display: block;
  margin: 0 auto;
  padding: 8px 18px;
  border-radius: 20px;
  /* border: 1px solid transparent; */
  transition: background-color 0.4s ease-in-out, color 0.4s ease-in-out;

  color: #9b9ba4;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.button_forgot:hover {
  color: #fff;
  /* border: 1px solid #3d3d4b; */
  background-color: #06061c;
}

.notice {
  width: 100%;
  text-align: center;
  color: #9b9ba4;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

@media (max-width: 1024px) {
  .close_btn {
    top: 20px;
    right: 20px;
  }

  .title {
    margin-top: 0px;
  }

  .btn_back {
    margin: 0 auto 20px auto;
  }
}

@media (max-width: 450px) {
  .close_btn {
    width: 32px;
    height: 32px;
  }

  .input_code {
    width: 42px;
    border-radius: 12px;
    padding: 10px 10px;
  }

  .reg_autor_btns {
    flex-direction: column;
    border-radius: 20px;
  }

  .reg_autor_btn {
    text-align: center;
    width: 100%;
    border-radius: 10px;
  }

  .reg_autor_btn_active {
    text-align: center;
    width: 100%;
    border-radius: 10px;
  }
}
