.adminpanel {
  background-color: var(--background);
  min-height: 100vh;
  padding-top: 90px;
  position: relative;
  overflow: hidden;
}

.adminpanel h2 {
  font-family: 'Orto';
  font-weight: 800;
  font-size: 32px;
  line-height: 43px;
  color: var(--color);
  margin-bottom: 50px;
}

.wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 50px;
}

.depts_list {
  max-width: 250px;
  min-width: 170px;
  padding-top: 6px;
  margin-bottom: 60px;
}

.dept_item {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: var(--color);
  margin-bottom: 30px;
  transition: opacity 0.4s ease-in-out;
}

.dept_item:nth-last-child(2) {
  margin-bottom: 60px;
}

.dept_item:last-child {
  margin-bottom: 0;
}

.dept_link {
  display: flex;
  align-items: center;
  gap: 15px;
  color: #9b9ba4;
  transition: color 0.4s ease-in-out;

  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
}

.dept_link_active {
  display: flex;
  align-items: center;
  gap: 15px;
}

.dept_link .red_circle {
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
}

.dept_link:hover {
  color: #fff;
}

.dept_link:hover > .red_circle {
  opacity: 1;
}

.red_circle_exit {
  opacity: 0;
}

.menu_btn_mob {
  display: none;
}

@media (max-width: 900px) {
  .adminpanel {
    max-width: 540px;
    width: 100%;
  }

  .adminpanel h2 {
    margin-bottom: 15px;
  }

  .wrapper {
    display: block;
  }

  .wrapper_mob {
    position: relative;
    height: 73px;
  }

  .menu_btn_mob {
    display: block;
    position: relative;
    text-align: left;
    width: 100%;
    max-width: 500px;
    border-radius: 30px;
    border: 2px solid #522381;
    background-color: #0d0d1e;
    height: 44px;

    padding: 4px 12px 4px 30px;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    z-index: 2;
  }

  .arrow_down2 {
    position: absolute;
    top: 18px;
    right: 30px;
    cursor: pointer;
    z-index: 2;
    transition: transform 0.4s ease-in-out;
  }

  .arrow_down2_180 {
    position: absolute;
    top: 18px;
    right: 30px;
    cursor: pointer;
    z-index: 2;
    transform: rotate(180deg);
    transition: transform 0.4s ease-in-out;
  }

  .depts_list {
    position: absolute;
    top: 22px;
    width: 100%;
    max-width: 500px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    border: 2px solid #522381;
    background-color: #0d0d1e;
    height: 280px;
    overflow: hidden;
    padding-top: 40px;
    z-index: 1;
    transition: height 0.4s ease-in-out, padding-top 0.4s ease-in-out, border-color 0.4s ease-in-out;
  }

  .depts_list_hide {
    position: absolute;
    top: 22px;
    width: 100%;
    max-width: 500px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    border: 2px solid transparent;
    background-color: #0d0d1e;
    height: 0;
    overflow: hidden;
    padding-top: 0;
    z-index: 1;
    transition: height 0.4s ease-in-out, padding-top 0.4s ease-in-out, border-color 0.4s ease-in-out;
  }

  .dept_link {
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
  }

  .dept_item {
    margin-bottom: 12px;
    padding: 4px 12px 4px 30px;
    color: #fff;

    font-size: 14px;
    font-weight: 700;
    line-height: 24px;

    transition: color 0.4s ease-in-out;
  }

  .dept_item:hover {
    color: #522381;
  }

  .dept_item:nth-last-child(2) {
    margin-bottom: 12px;
  }

  .dept_item:last-child {
    margin-bottom: 12px;
  }

  .red_circle {
    display: none;
  }

  .red_circle_exit {
    display: none;
  }
}

@media (max-width: 768px) {
  .adminpanel {
    padding-top: 40px;
  }

  .adminpanel h2 {
    font-size: 22px;
  }
}
