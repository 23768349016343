.section_advantages {
  padding-top: 90px;
}

.title {
  color: #fff;
  font-family: Orto;
  font-size: 32px;
  font-weight: 800;
  line-height: 64px;
  margin-bottom: 40px;
}

.after768px {
  display: block;
}

.before768px {
  display: none;
}

.advantages_list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 32px;
  padding-bottom: 150px;

  /* scrollbar-color: #ffffff9a #0000005d;
  scrollbar-width: auto !important; */
}

/* .advantages_list::-webkit-scrollbar {
  width: 6px;
  height: 10px;
}

.advantages_list::-webkit-scrollbar-track {
  background: #0000005d;
  border-radius: 10px;
}

.advantages_list::-webkit-scrollbar-thumb {
  background-color: #ffffff9a;
  border-radius: 10px;
  border: 1px solid #141425;
} */

.advantages_item {
  position: relative;
  max-width: 376px;
  width: 100%;
  border-radius: 20px;
  outline: 1px solid #3d3d4b;
  outline-offset: -1px;
  padding: 22px 25px 42px;
}

.advantages_item:hover {
  outline: 1px solid transparent;
  transition: outline 0.4s ease-in-out;
}

.item_background {
  position: absolute;
  inset: 0;
  border-radius: 20px;
  background: linear-gradient(310deg, #1d1029, #0d0d1e);
  opacity: 0;
  z-index: -1;
}

.item_border {
  position: absolute;
  top: -2px;
  left: -2px;
  bottom: -2px;
  right: -2px;
  border-radius: 20px;
  /* background-image: url('../../../../img/news_border2.svg');
  background-position: 0 0 0 0;
  background-repeat: no-repeat;
  background-size: 100% 100%; */
  background: linear-gradient(317deg, #e60b0b 0%, #ffffff 86.67%);
  opacity: 0;
  z-index: -1;
}

.advantages_item:hover .item_border {
  opacity: 1;
  transition: opacity 0.4s ease-in-out;
}

.advantages_item:hover .item_background {
  opacity: 1;
}

.advantages_image {
  width: 60px;
  height: 50px;
  object-fit: none;
  margin-bottom: 40px;
  opacity: 0.2;
  transition: opacity 0.4s ease-in-out;
}

.advantages_item:hover .advantages_image {
  opacity: 1;
}

.item_title {
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.item_text {
  color: #9b9ba4;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  cursor: default;
}

@media (max-width: 1280px) {
  .advantages_list {
    grid-template-columns: repeat(3, 1fr);
    gap: 26px;
  }
}

@media (max-width: 1024px) {
  .title {
    font-size: 24px;
    line-height: normal;
  }

  .advantages_list {
    grid-template-columns: repeat(2, 1fr);
    gap: 32px;
    padding-bottom: 100px;
  }

  .advantages_item {
    max-width: 440px;
  }
}

@media (max-width: 768px) {
  .after768px {
    display: none;
  }

  .before768px {
    display: block;
    width: 100%;
  }

  .advantages_list {
    display: block;

    padding-top: 1px;
    padding-left: 1px;
    padding-bottom: 20px;
    margin-bottom: 80px;
  }

  .advantages_item {
    position: relative;
    box-sizing: border-box;
    margin-bottom: 10px;
    height: 420px;
  }

  .item_text {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 10; /* number of lines to show */
    line-clamp: 10;
    -webkit-box-orient: vertical;
  }

  .item_text:hover {
    position: absolute;
    overflow: visible;
    background-color: #0d0d1e;
    width: calc(100% - 45px);
  }
}

@media (max-width: 400px) {
  .advantages_item {
    min-width: 280px;
  }
}
