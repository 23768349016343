.pdfpreview {
  width: 260px;
  /* height: 367px; */
  height: 336px;
  overflow: hidden;
  box-shadow: 5px 5px 16px 1px #ffffff33;

  display: flex;
  flex-direction: column;
  justify-content: center;
  /* pointer-events: none; */
}
