.news {
  min-height: 80vh;
}

.title {
  color: #fff;
  font-size: 72px;
  font-family: Orto, sans-serif;
  font-weight: 800;
  line-height: 90px;
  margin-top: 150px;
  margin-bottom: 190px;
}

.flashlight1 {
  position: fixed;
  top: 0;
  left: -300px;
  width: 1338px;
  height: 1338px;
  border-radius: 1338px;
  opacity: 0.25;
  background: radial-gradient(50% 50% at 50% 50%, #e60b0b 0%, #e72a4600 100%);
  z-index: -1;
}

.flashlight2 {
  position: fixed;
  top: -900px;
  right: -600px;
  width: 1338px;
  height: 1338px;
  border-radius: 1338px;
  opacity: 0.25;
  background: radial-gradient(50% 50% at 50% 50%, #e60b0b 0%, #e72a4600 100%);
  z-index: -1;
}

.newscard_list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 40px;
  margin-bottom: 90px;
}

.newscard_item {
  position: relative;
  max-width: 505px;
  width: 100%;
  display: flex;
  gap: 40px;
}

.newscard_item:nth-child(1) {
  margin-top: -130px;
}

.newscard_item:nth-child(3n) {
  margin-top: -180px;
  /* background-color: red; */
}

.newscard_item:nth-child(3n + 4) {
  margin-top: -130px;
}

.pagination {
  margin: 0 auto 80px;
  display: flex;
  justify-content: center;
}

.pagination_btn_prev {
  width: 44px;
  height: 44px;
  background-image: url('../../../../img/btn_right.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 10px 16px;
  transform: rotate(180deg);
  opacity: 0.2;
  transition: opacity 0.3s ease-in-out;
}

.pagination_btn_prev:hover {
  opacity: 1;
}

.pagination_btn_next {
  width: 44px;
  height: 44px;
  background-image: url('../../../../img/btn_right.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 10px 16px;
  opacity: 0.2;
  transition: opacity 0.3s ease-in-out;
}

.pagination_btn_next:hover {
  opacity: 1;
}

.pagination_list {
  display: flex;
  align-items: center;
  gap: 4px;
}

.pagination_item {
  width: 44px;
  height: 44px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  padding: 10px 0;
  border-radius: 50%;
  background-color: transparent;
  color: #9b9ba4;
  cursor: pointer;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.pagination_item:hover {
  background-color: #06061c;
  color: #fff;
}

.pagination_item_active {
  width: 44px;
  height: 44px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  padding: 10px 0;
  border-radius: 50%;
  background-color: transparent;
  color: #fff;
  cursor: pointer;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.hidden {
  display: none;
}

.preloader {
  width: 100%;
  height: 5vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1280px) {
  .flashlight2 {
    display: none;
  }

  .title {
    font-size: 54px;
    margin-top: 90px;
  }

  .newscard_list {
    grid-template-columns: 1fr 1fr;
    justify-content: flex-start;
  }

  /* добавлено целиком */
  .newscard_item:nth-child(2) {
    justify-self: end;
  }

  /* добавлено justify-self: end; */
  .newscard_item:nth-child(3n) {
    margin-top: 0;
    justify-self: end;
  }

  /* добавлено целиком */
  .newscard_item:nth-child(3) {
    justify-self: start;
  }

  /* добавлено justify-self: end; */
  .newscard_item:nth-child(3n + 4) {
    margin-top: 0;
    justify-self: end;
  }

  .newscard_item:nth-child(2n + 3) {
    margin-top: -135px;
  }
}

@media (max-width: 1024px) {
  .title {
    font-size: 43px;
    margin-top: 50px;
  }
}

@media (max-width: 760px) {
  .flashlight1 {
    left: -800px;
  }

  .title {
    text-align: center;
    font-size: 32px;
    margin-top: 30px;
    margin-bottom: 150px;
  }

  .newscard_list {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
  }

  .newscard_item:nth-child(2n + 3) {
    margin-top: 0;
  }
}
