.main {
  padding-bottom: 60px;
}

.flashlight1 {
  position: fixed;
  top: 0;
  left: -300px;
  width: 1338px;
  height: 1338px;
  border-radius: 1338px;
  opacity: 0.25;
  background: radial-gradient(50% 50% at 50% 50%, #e60b0b 0%, #e72a4600 100%);
  z-index: -1;
}

.flashlight2 {
  position: fixed;
  top: -900px;
  right: -600px;
  width: 1338px;
  height: 1338px;
  border-radius: 1338px;
  opacity: 0.25;
  background: radial-gradient(50% 50% at 50% 50%, #e60b0b 0%, #e72a4600 100%);
  z-index: -1;
}

.title {
  margin-top: 150px;
  margin-bottom: 75px;

  color: #fff;
  font-family: Orto;
  font-size: 72px;
  font-weight: 800;
  line-height: 90px;
}

.service_container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}

.subtitle {
  margin-bottom: 40px;

  color: #fff;
  font-size: 30px;
  font-weight: 500;
  line-height: 46px;
}

.description {
  margin-bottom: 25px;

  color: #9b9ba4;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
}

.map {
  width: 100%;
  height: 700px;
  background-image: url('../../img/map.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-bottom: 90px;
}

.wrap_select {
  position: relative;
  width: 100%;
}

.hint_show {
  position: absolute;
  top: -22px;
  left: 10px;

  font-family: 'NotoSans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  opacity: 0.6;
}

.hint_hide {
  display: none;
}

.select_wrap {
  max-width: 400px;
  width: 100%;
  height: 60px;
  border: 1px solid #ffffff33;
  border-radius: 30px;
  padding: 10px 20px;
  background-color: #0d0d1e;
  margin-bottom: 25px;
}

.label {
  position: relative;
}

.select {
  width: 100%;
  padding: 8px 16px 8px 8px;
  color: #fff;
  border-radius: 20px;
  background-color: #0d0d1e;
  border: none;

  appearance: none;
  background-image: url('../../img/arrow_down2.svg');
  background-repeat: no-repeat;
  background-position: right 10px top 50%;
  background-size: 18px 10px;

  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.select:focus {
  outline: none;
  border: none;
}

.select option {
  background-color: #1c1c2c;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #9b9ba4;
}

.servicecenter_card_list {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 25px;
}

@media (max-width: 1280px) {
  .main {
    padding-bottom: 90px;
  }

  .title {
    margin-top: 105px;
    margin-bottom: 90px;
    font-size: 54px;
    line-height: 64px;
  }

  .subtitle {
    font-size: 24px;
    line-height: 34px;
  }

  .description {
    font-size: 18px;
    line-height: 24px;
  }
}

@media (max-width: 1024px) {
  .title {
    font-size: 43px;
    line-height: 54px;
    margin-top: 70px;
    margin-bottom: 70px;
  }

  .subtitle {
    font-size: 20px;
    line-height: 28px;
  }

  .description {
    font-size: 17px;
    line-height: 22px;
    margin-bottom: 10px;
  }

  .map {
    height: 420px;
  }
}

@media (max-width: 768px) {
  .title {
    font-size: 32px;
    line-height: normal;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .subtitle {
    font-size: 18px;
    line-height: 24px;
  }

  .description {
    font-size: 16px;
    line-height: 20px;
  }
}

@media (max-width: 600px) {
  .map {
    height: 290px;
  }

  .select option {
    font-size: 15px;
    /* line-height: 18px; */
  }
}

@media (max-width: 400px) {
  .map {
    height: 180px;
  }
}
