.overlay_show {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #0d0d1e80;
  backdrop-filter: blur(15px);
  z-index: 101;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: transform 0.2s ease-in-out, opacity 0.6s ease-in-out;
}

.overlay_hide {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #0d0d1e80;
  backdrop-filter: blur(15px);
  z-index: 101;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transform: scale(0);
  transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

.hidden {
  display: none;
}

.docs_item {
  width: 100%;
  display: grid;
  grid-template-columns:
    minmax(100px, 110px)
    minmax(70px, 80px)
    minmax(160px, 165px)
    minmax(110px, 120px)
    minmax(300px, 310px);
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding: 8px 0 8px 0;
  cursor: default;
  border-radius: 10px;
  border: 1px solid transparent;
  outline: 1px solid transparent;
  outline-offset: 12px;
  transition: outline-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
}

.docs_item:hover {
  outline-color: #fff;
}

.item_wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.item_wrap_desc {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.item_title {
  display: none;
  color: #9b9ba4;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
}

.item_date {
  padding: 2px 6px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}

.item_button {
  border-radius: 10px;
  background-color: #522381;
  padding: 10px 70px;
  transition: background-color 0.4s ease-in-out;

  color: #fff;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
}

.item_button:hover {
  background-color: #1f0b32;
}

.item_btn {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: transparent;
  background-image: url('../../../../../../img/btn_points.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  justify-self: end;
  opacity: 0.2;
  transition: opacity 0.3s ease-in-out, background-color 0.3s ease-in-out;
  cursor: pointer;
}

.item_btn:hover {
  background-color: #06061c;
  opacity: 1;
}

.item_btn_container {
  position: relative;
  justify-self: end;
}

.btn_menu_item {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  opacity: 0.6;
  
}

.btn_menu_item a {
  transition: color 0.3s ease-in-out;
}

.btn_menu_item:hover a {
  color: #e60b0b;
}

.btn_menu {
  position: absolute;
  width: 164px;
  /* height: 104px; */
  top: 40px;
  right: 35px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: space-between;
  padding: 12px 18px;
  background-color: #0d0d1e;
  border: 1px solid #cccccc;
  box-shadow: 0px 8px 15px #0000000d;
  border-radius: 5px;
  transform: scale(0);
  opacity: 0;
  cursor: default;
  transition: opacity 0.2s ease-in-out, transform 0.4s ease-in-out;
}

.btn_menu_show {
  position: absolute;
  width: 164px;
  /* height: 104px; */
  top: 40px;
  right: 35px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: space-between;
  padding: 12px 18px;
  background-color: #0d0d1e;
  border: 1px solid #cccccc;
  box-shadow: 0px 8px 15px #0000000d;
  border-radius: 5px;
  cursor: default;
  opacity: 1;
  z-index: 2;
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
}

.btn_menu_overlay {
  position: absolute;
  width: 164px;
  height: 104px;
  top: 40px;
  right: 35px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  justify-content: space-between;
  padding: 12px 18px;
  background-color: #0d0d1e;
  border: 1px solid #cccccc;
  box-shadow: 0px 8px 15px #0000000d;
  border-radius: 5px;
  cursor: default;
  opacity: 1;
  z-index: 2000;
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
}

.btn_menu_mob {
  display: none;
}

@media (max-width: 1280px) {
  .docs_item {
    grid-template-columns:
      minmax(100px, 110px)
      minmax(70px, 80px)
      minmax(170px, 175px)
      minmax(110px, 120px)
      minmax(200px, 310px);
  }
}

@media (max-width: 1024px) {
  .docs_item {
    grid-template-columns:
      minmax(100px, 110px)
      minmax(70px, 80px)
      minmax(120px, 140px)
      minmax(110px, 120px)
      minmax(120px, 310px);
    gap: 1px;
  }

  .item_button {
    padding: 10px 30px;
  }
}

@media (max-width: 900px) {
  .docs_item {
    display: block;
    border-radius: 20px;
    border: 1px solid #3d3d4b;
    margin-bottom: 10px;
    padding: 20px;
  }

  .docs_item:hover {
    border-color: #fff;
    outline: 1px solid transparent;
  }

  .item_wrap {
    margin-bottom: 15px;
  }

  .item_button {
    margin-left: 0;
    padding: 10px 30px;
  }

  .item_title {
    display: block;
  }

  .item_date {
    width: calc(59% - 4px);
  }

  .item_btn {
    display: none;
  }

  .btn_menu_mob {
    width: 180px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .btn_menu_item {
    color: #864390;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .item_wrap_desc {
    display: block;
  }
}
