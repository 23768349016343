.autor_form {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin: 0 auto;
  max-width: 420px;
  width: 100%;
  margin-bottom: 30px;
}

.title {
  margin-top: 160px;
  margin-bottom: 40px;

  color: #fff;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
}

.close_btn {
  position: absolute;
  top: 40px;
  right: 40px;
  display: block;
  width: 48px;
  height: 48px;
  background-image: url('../../../../img/btn_close2.svg');
  background-repeat: no-repeat;
  background-position: center;
}

.button_submit {
  width: 100%;
  padding: 20px 30px;
  border-radius: 50px;
  background-color: #522381;
  transition: background-color 0.4s ease-in-out;
}

.button_submit:hover {
  background-color: #1f0b32;
}

.button_submit:disabled {
  width: 100%;
  padding: 20px 30px;
  border-radius: 50px;
  color: #8d8888;
  background-color: #42364e;
  cursor: default;
}

.img_change_success {
  margin: 0 auto;
  margin-bottom: 10px;
}

@media (max-width: 1024px) {
  .close_btn {
    top: 20px;
    right: 20px;
  }

  .title {
    margin-top: 0px;
  }
}

@media (max-width: 450px) {
  .close_btn {
    width: 32px;
    height: 32px;
  }

  .reg_autor_btns {
    flex-direction: column;
    border-radius: 20px;
  }

  .reg_autor_btn {
    text-align: center;
    width: 100%;
    border-radius: 10px;
  }

  .reg_autor_btn_active {
    text-align: center;
    width: 100%;
    border-radius: 10px;
  }
}
