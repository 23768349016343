.sectionhero {
  position: relative;
  display: flex;
  align-items: center;
  gap: 8%;
  z-index: 0;
  /* outline: 1px solid yellow; */
  height: 960px;
}

.sectionhero::after {
  position: absolute;
  content: '';
  top: -870px;
  right: -570px;
  width: 1340px;
  height: 1340px;
  background: radial-gradient(50% 50% at 50% 50%, #e60b0b 0%, rgba(231, 42, 70, 0) 100%);
  opacity: 0.25;
  z-index: -1;
}

.sectionhero::before {
  position: absolute;
  content: '';
  top: 80px;
  left: -570px;
  width: 1340px;
  height: 1340px;
  background: radial-gradient(50% 50% at 50% 50%, #e60b0b 0%, rgba(231, 42, 70, 0) 100%);
  opacity: 0.25;
  z-index: -1;
}

.wrapper_content {
  width: 42.5%;
  /* outline: 1px solid white; */
}

.wrapper_slider {
  width: 57.5%;
  overflow: hidden;
  /* outline: 1px solid blue; */
}

.slide_preload {
  width: 57.5%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh + 75px);
}

.hero_title {
  font-family: 'Orto';
  font-weight: 800;
  font-size: 72px;
  line-height: 90px;
  text-transform: uppercase;
  color: var(--color);
  margin: 0 0 90px 0;
}

.hero_description {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: var(--color);
  opacity: 0.6;
  margin-bottom: 50px;
}

.btn_order {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0;
  padding: 20px 0;
  width: 300px;
  background-color: #e60b0b;
  border-radius: 50px;
  text-transform: uppercase;
  transition: gap 0.4s ease-in-out, box-shadow 0.4s ease-in-out;

  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
}

.btn_img {
  width: 0;
  transition: width 0.4s ease-in-out;
}

.btn_order:hover {
  gap: 22px;
  box-shadow: 0px 12px 50px #e60b0b59;
}

.btn_order:hover .btn_img {
  width: 20px;
  transition: width 0.4 ease-in-out;
}

.wrap_slide_hero {
  width: 100%;
  max-width: 800px;
  /* min-width: 650px; */
  height: 100%;
  max-height: 800px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.desctop {
  display: block;
}

.mob {
  display: none;
}

@media (max-width: 1440px) {
  .sectionhero {
    height: 800px;
  }

  .hero_title {
    font-size: 54px;
    line-height: 64px;
    margin: 0 0 50px 0;
  }
}

@media (max-width: 1100px) {
  .sectionhero {
    height: 700px;
  }

  .hero_title {
    font-size: 43px;
    line-height: 54px;
    margin: 0 0 45px 0;
  }
}

@media (max-width: 870px) {
  .sectionhero {
    height: 500px;
  }

  .hero_title {
    font-size: 32px;
    line-height: normal;
    margin: 0 0 20px 0;
  }

  .hero_description {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 25px;
  }

  .btn_order {
    width: 260px;
    font-size: 14px;
    line-height: 18px;
  }
}

@media (max-width: 650px) {
  .sectionhero::after {
    display: none;
  }

  .sectionhero {
    flex-direction: column;
    height: auto;
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .wrapper_content {
    width: 100%;
  }

  .wrapper_slider {
    width: 100%;
    margin-bottom: 40px;
  }

  .hero_title {
    font-size: 28px;
    text-align: center;
    margin: 0 0 40px 0;
  }

  .hero_description {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 30px;
  }

  .btn_order {
    margin: 0 auto;
  }

  .desctop {
    display: none;
  }

  .mob {
    display: block;
  }

  .slide_preload {
    width: 100%;
    padding-top: 0;
  }
}
