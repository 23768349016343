.overlay_show {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #0d0d1e80;
  backdrop-filter: blur(15px);
  z-index: 101;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: transform 0.2s ease-in-out, opacity 0.6s ease-in-out;
}

.overlay_hide {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #0d0d1e80;
  backdrop-filter: blur(15px);
  z-index: 101;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transform: scale(0);
  transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

.clbase_item {
  width: 100%;
  display: grid;
  grid-template-columns:
    minmax(80px, 80px)
    minmax(110px, 110px)
    minmax(100px, 100px)
    minmax(120px, 120px)
    minmax(110px, 110px)
    minmax(80px, 80px)
    minmax(140px, 140px)
    minmax(100px, 100px)
    minmax(60px, 60px)
    minmax(80px, 80px)
    minmax(70px, 70px)
    minmax(60px, 60px)
    minmax(80px, 80px)
    minmax(max-content, 60px);

  align-items: center;
  justify-content: space-between;
  padding: 30px 24px 30px 12px;
  cursor: default;
  border-radius: 10px;
  border: 1px solid transparent;
  transition: border-color 0.4s ease-in-out;
}

.clbase_item:hover {
  border-color: #fff;
}

.item_title {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  padding-left: 8px;
  padding-right: 8px;
}

.item_text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  padding-left: 6px;
  padding-right: 6px;
  opacity: 0.9;
  white-space: nowrap;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.item_text_c {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  padding-left: 6px;
  padding-right: 6px;
  opacity: 0.9;
  white-space: nowrap;
  text-align: center;
}

.item_ver {
  margin: auto;
  width: 32px;
  height: 32px;
  background-image: url('../../../../../../img/icon_verifed.svg');
  background-repeat: no-repeat;
  background-size: 32 32;
  background-position: center;
}

.item_nover {
  margin: auto;
  width: 32px;
  height: 32px;
  background-image: url('../../../../../../img/icon_noverifed.svg');
  background-repeat: no-repeat;
  background-size: 32 32;
  background-position: center;
}

.item_id {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  padding-left: 6px;
  opacity: 0.9;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.item_edit_del {
  position: relative;
  justify-self: end;
}

.item_btn {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: transparent;
  background-image: url('../../../../../../img/btn_points.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.2;
  transition: opacity 0.3s ease-in-out, background-color 0.3s ease-in-out;
  cursor: pointer;
}

.item_btn:hover {
  background-color: #06061c;
  opacity: 1;
}

.btn_menu {
  position: absolute;
  width: 164px;
  height: 104px;
  top: 40px;
  right: 35px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  justify-content: space-between;
  padding: 12px 18px;
  background-color: #0d0d1e;
  border: 1px solid #cccccc;
  box-shadow: 0px 8px 15px #0000000d;
  border-radius: 5px;
  transform: scale(0);
  opacity: 0;
  transition: opacity 0.2s ease-in-out, transform 0.4s ease-in-out;
}

.btn_menu_show {
  position: absolute;
  width: 164px;
  height: 104px;
  top: 40px;
  right: 35px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  justify-content: space-between;
  padding: 12px 18px;
  background-color: #0d0d1e;
  border: 1px solid #cccccc;
  box-shadow: 0px 8px 15px #0000000d;
  border-radius: 5px;
  opacity: 1;
  z-index: 2;
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
}

.btn_menu_item {
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  opacity: 0.6;
  padding: 6px 0;
  transition: color 0.3s ease-in-out;
}

.btn_menu_item:hover {
  color: #e60b0b;
}

.item_requisites {
  text-align: center;
}

.item_button {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  justify-self: center;
  opacity: 0.2;
  transition: opacity 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.item_button:hover {
  background-color: #06061c;
  opacity: 1;
}

.btn_bankrequisites {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: transparent;
  background-image: url('../../../../../../img/icon_requisitesbank.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  justify-self: end;
  opacity: 0.2;
  transition: opacity 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.btn_bankrequisites:hover {
  background-color: #06061c;
  opacity: 1;
}

.item_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin: auto;
  opacity: 0.2;
  transition: opacity 0.4s ease-in-out;
}

.item_icon:hover {
  opacity: 1;
}

.item_name {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
}

.item_name_p {
  display: none;
  font-weight: 100;
  font-size: 12px;
  color: #5e5c5c;
}

@media (max-width: 1570px) {
  .clbase_item {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    border-color: #262627;
    padding: 20px;
  }

  .item_ver {
    margin: 0;
  }

  .item_equip {
    margin: 0;
  }

  .item_icon {
    margin: 0;
  }

  .item_name_p {
    display: block;
    width: 70px;
    overflow: hidden;
    white-space: nowrap; /* Текст не переносится */
    text-overflow: ellipsis; /* Добавляем многоточие */
  }
}
