.privacypolicy {
  padding-bottom: 120px;
}

.privacypolicy_container {
  max-width: 1200px;
  width: 100%;
  margin: 80px auto 0;
  padding-left: 20px;
  padding-right: 20px;
}

.title1 {
  text-transform: uppercase;
  font: 700 24px/32px Montserrat;
  margin-left: 60px;
  word-break: break-all;
}

.title2 {
  text-transform: uppercase;
  font: 700 20px/32px Montserrat;
  margin-bottom: 20px;
  margin-left: 60px;
  word-break: break-all;
}

.paragraph {
  text-indent: 60px;
  text-align: justify;
  color: #9b9ba4;
  font: 500 18px/28px Montserrat;
  margin-bottom: 20px;
}

.paragraph2 {
  text-align: justify;
  color: #9b9ba4;
  font: 500 18px/28px Montserrat;
  margin-bottom: 20px;
}

.list {
  text-align: justify;
  color: #9b9ba4;
  font: 500 18px/38px Montserrat;
  margin-bottom: 20px;
  margin-left: 60px;
}

@media (max-width: 600px) {
  .title1 {
    text-align: left;
    font-size: 22px;
    line-height: 26px;
    margin-left: 20px;
  }

  .title2 {
    text-align: left;
    font-size: 17px;
    line-height: 24px;
    margin-left: 20px;
  }

  .paragraph {
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    text-indent: 20px;
  }

  .paragraph2 {
    font-size: 16px;
    line-height: 24px;
    text-align: left;
  }

  .list {
    text-align: left;
    font-size: 16px;
    line-height: 24px;
    margin-left: 20px;
  }
}
