.flashlight1 {
  position: fixed;
  top: 0;
  left: -300px;
  width: 1338px;
  height: 1338px;
  border-radius: 1338px;
  opacity: 0.25;
  background: radial-gradient(50% 50% at 50% 50%, #e60b0b 0%, #e72a4600 100%);
  z-index: -1;
}

.flashlight2 {
  position: fixed;
  top: -900px;
  right: -600px;
  width: 1338px;
  height: 1338px;
  border-radius: 1338px;
  opacity: 0.25;
  background: radial-gradient(50% 50% at 50% 50%, #e60b0b 0%, #e72a4600 100%);
  z-index: -1;
}

.main {
  padding-bottom: 60px;
}

.title {
  margin-top: 150px;
  margin-bottom: 75px;

  color: #fff;
  font-family: Orto;
  font-size: 72px;
  font-weight: 800;
  line-height: 90px;
}

.clients_list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 77px 32px;
}

.clients_item {
  max-width: 376px;
  width: 100%;
}

.clients_img {
  width: 100%;
  min-width: 376px;
  margin-bottom: 32px;
  object-fit: contain;
}

.clients_link {
  display: block;
  margin-bottom: 15px;

  color: #9b9ba4;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;

  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.clients_item_h4 {
  font-weight: 100;
  font-size: 18px;
  line-height: 24px;
  color: #fff;
  /* text-overflow: ellipsis; */

  /* ограничиваем текст двумя строчками */
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.preloader {
  width: 100%;
  height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1680px) {
  .main {
    padding-bottom: 90px;
  }

  .title {
    margin-top: 105px;
    margin-bottom: 90px;
    font-size: 54px;
    line-height: 64px;
  }

  .clients_list {
    width: 100%;
    grid-template-columns:
      minmax(260px, 33.3%)
      minmax(260px, 33.3%)
      minmax(260px, 33.3%);
    justify-items: center;
  }
}

@media (max-width: 1250px) {
  .clients_list {
    grid-template-columns:
      minmax(260px, 50%)
      minmax(260px, 50%);
    justify-items: stretch;
  }

  .clients_item {
    max-width: none;
    width: 100%;
  }

  .clients_img {
    margin-bottom: 32px;
    object-fit: contain;
  }
}

@media (max-width: 1024px) {
  .title {
    font-size: 43px;
    line-height: 54px;
    margin-top: 70px;
    margin-bottom: 70px;
  }
}

@media (max-width: 820px) {
  .clients_item {
    max-width: 350px;
    width: 100%;
    margin: 0 auto;
  }

  .clients_img {
    min-width: 350px;
    width: 100%;
  }
}

@media (max-width: 760px) {
  .title {
    font-size: 32px;
    line-height: normal;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .clients_list {
    display: block;
  }

  .clients_item {
    margin-bottom: 40px;
  }

  .clients_item_h4 {
    font-size: 16px;
    line-height: 22px;
    color: #fff;
  }

  .flashlight1 {
    left: -800px;
  }
}

@media (max-width: 500px) {
  .clients_list {
    gap: 40px 0;
  }

  .clients_img {
    min-width: 280px;
    height: auto;
    margin-bottom: 12px;
  }
}
