.autor_form {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin: 0 auto;
  max-width: 420px;
  width: 100%;
  margin-bottom: 30px;
}

.input_wrap {
  position: relative;
  height: 0;
}

.input {
  width: 100%;
  border-radius: 30px;
  padding: 18px 30px;
  background-color: transparent;

  border: none;
  outline: 1px solid #4f5152;
  outline-offset: -1px;
  caret-color: #9b9ba4;

  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.input:focus {
  outline: 1px solid #656768;
  outline-offset: -1px;
}

.input::placeholder {
  color: #9b9ba4;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.note_mail {
  padding: 0 5px 4px 5px;
  height: 22px;
  overflow: hidden;
  opacity: 1;

  color: #e60b0b;
  font-family: NotoSans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  transition: height 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

.note_pass {
  padding: 0 5px 4px 5px;
  height: 65px;
  overflow: hidden;
  opacity: 1;

  color: #e60b0b;
  font-family: NotoSans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  transition: height 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

.note_empty {
  padding: 0 5px 4px 5px;
  height: 0;
  overflow: hidden;
  opacity: 0;

  color: #e60b0b;
  font-family: NotoSans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  transition: height 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

.note_hide {
  padding: 0 5px 4px 5px;
  height: 0;
  overflow: hidden;
  opacity: 0;

  color: #e60b0b;
  font-family: NotoSans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  transition: height 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

.input_btn_closed {
  position: absolute;
  top: -46px;
  right: 20px;
  width: 32px;
  height: 32px;
  background-image: url('../../../../img/btn_password.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  opacity: 0.2;
  transition: opacity 0.4s ease-in-out;
}

.input_btn_closed:hover {
  opacity: 1;
}

.input_btn_open {
  position: absolute;
  top: -46px;
  right: 20px;
  width: 32px;
  height: 32px;
  background-image: url('../../../../img/btn_password2.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  opacity: 0.2;
  transition: opacity 0.4s ease-in-out;
}

.input_btn_open:hover {
  opacity: 1;
}

.button_submit {
  width: 100%;
  padding: 20px 30px;
  border-radius: 50px;
  background-color: #522381;
  transition: background-color 0.4s ease-in-out;
}

.button_submit:hover {
  background-color: #1f0b32;
}

.button_submit:disabled {
  width: 100%;
  padding: 20px 30px;
  border-radius: 50px;
  color: #8d8888;
  background-color: #42364e;
  cursor: default;
}

.button_forgot {
  display: block;
  margin: 0 auto;
  padding: 8px 18px;
  border-radius: 20px;
  transition: background-color 0.4s ease-in-out, border 0.4s ease-in-out;

  color: #9b9ba4;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.button_forgot:hover {
  background-color: #06061c;
}

.title {
  margin-top: 160px;
  margin-bottom: 20px;

  color: #fff;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
}

.description {
  margin-bottom: 25px;

  color: #9b9ba4;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.close_btn {
  position: absolute;
  top: 40px;
  right: 40px;
  display: block;
  width: 48px;
  height: 48px;
  background-image: url('../../../../img/btn_close2.svg');
  background-repeat: no-repeat;
  background-position: center;
}

.reg_autor_btns {
  margin: 0 auto;
  max-width: 420px;
  width: 100%;
  padding: 10px;
  border-radius: 40px;
  background-color: #000015;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 30px;
}

.reg_autor_btn {
  color: #3d3d4b;
  padding: 20px 40px;
  border-radius: 30px;
  background-color: #000015;
  transition: background-color 0.4s ease-in-out;
}

.reg_autor_btn:hover {
  background-color: #06061c;
  transition: background-color 0.4s ease-in-out;
}

.reg_autor_btn_active {
  color: #fff;
  padding: 20px 40px;
  border-radius: 30px;
  background-color: #06061c;
}

@media (max-width: 1024px) {
  .close_btn {
    top: 20px;
    right: 20px;
  }

  .title {
    margin-top: 0px;
  }
}

@media (max-width: 450px) {
  .close_btn {
    width: 32px;
    height: 32px;
  }

  .reg_autor_btns {
    flex-direction: column;
    border-radius: 20px;
  }

  .reg_autor_btn {
    text-align: center;
    width: 100%;
    border-radius: 10px;
  }

  .reg_autor_btn_active {
    text-align: center;
    width: 100%;
    border-radius: 10px;
  }
}
