.sectionwarranty {
  z-index: 1;
}

.background_1 {
  position: relative;
  background-color: #1d1029;
  width: calc(100% - 40px);
  border-radius: 30px;
  overflow: hidden;
  padding-bottom: 70px;
  margin: 0 auto;
  z-index: 1;
}

.illuminat {
  position: absolute;
  content: '';
  top: -370px;
  left: -300px;
  width: 1040px;
  height: 540px;
  background: radial-gradient(50% 50% at 50% 50%, #e60b0b 0%, rgba(231, 42, 70, 0) 100%);
  opacity: 0.25;
  z-index: -1;
}

.background_2 {
  position: sticky;
  bottom: 0;
  width: calc(100% - 40px);
  border-top-right-radius: 30px;
  border-top-left-radius: 30px;
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;
  background-color: #06061c;
  padding-bottom: 80px;
  margin: 0 auto;
  z-index: 0;
}

.background_2_title {
  font-family: 'Orto';
  font-weight: 800;
  font-size: 32px;
  line-height: 64px;
  color: var(--color);
  text-align: center;
  padding-top: 120px;
  padding-bottom: 30px;
  z-index: 1;
}

.schema_w {
  display: block;
  margin: 0 auto;
}

.schema_wm {
  display: block;
  margin: 0 auto;
}

.schema_w rect {
  transition: stroke-opacity 0.4s ease-in-out;
}

.schema_wm rect {
  transition: stroke-opacity 0.4s ease-in-out;
}

.schema_w rect:hover {
  stroke-opacity: 1;
}

.schema_wm rect:hover {
  stroke-opacity: 1;
}

.sectionwarranty_title {
  font-family: 'Orto';
  font-style: normal;
  font-weight: 800;
  font-size: 54px;
  line-height: 64px;
  color: var(--color);
  padding-top: 85px;
  margin-bottom: 60px;
}

.slider_warratny {
  margin-left: -20px;
  margin-bottom: 40px;
}

.warranty_list {
  width: calc(100% + 40px);
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-left: -20px;
  padding-bottom: 15px;
  /* overflow: auto; */
}

.warranty_item {
  box-sizing: border-box;
  width: 100%;
  height: max-content;
  padding: 30px 20px;
  border-radius: 20px;
  cursor: default;
  transition: background-color 0.6s ease-in-out;
}

.warranty_item:hover {
  background-color: #06061c80;
}

.circle_white {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 128px;
  width: 100%;
  height: 128px;
  background-image: url('../../img/ellipse-white.svg');
  background-repeat: no-repeat;
}

.circle_red {
  position: absolute;
  inset: 0;
  width: 128px;
  height: 128px;
  background-image: url('../../img/ellipse-red.svg');
  opacity: 0;
  transition: opacity 0.6s ease-in-out;
}

.warranty_item:hover > .item_wrap > .circle_white > .circle_red {
  opacity: 1;
}

.warranty_item h4 {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: var(--color);
  text-transform: uppercase;
}

.warranty_item p {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  opacity: 0.6;
  color: var(--color);
  padding-top: 20px;
}

.item_wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  margin-bottom: 30px;
}

.item_arrow {
  max-width: 137px;
  width: 50%;
  height: 22px;
  opacity: 0.2;

  background-image: url('../../img/arrow_warr.svg');
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 0.4s ease-in-out;
}

.item_arrow_hidden {
  max-width: 137px;
  width: 50%;
  height: 22px;

  background-image: url('../../img/arrow_warr.svg');
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0;
}

/* @media (max-width: 1652px) {
  .warranty_item {
    height: 420px;
  }
}

@media (max-width: 1422px) {
  .warranty_item {
    height: 450px;
  }
} */

@media (max-width: 1300px) {
  .sectionwarranty_title {
    padding-top: 65px;
    margin-bottom: 30px;
  }

  .background_1 {
    padding-bottom: 20px;
  }

  .warranty_list {
    cursor: grab;
    justify-content: space-between;
  }

  .warrancy_wrapp {
    /* width: 100%; */
    overflow-x: auto;
    scrollbar-width: auto;
    scrollbar-color: #ffffff8e #ffffff07;
  }

  .warrancy_wrapp::-webkit-scrollbar {
    height: 15px;
  }

  .warrancy_wrapp::-webkit-scrollbar-track {
    background: #ffffff07;
  }

  .warrancy_wrapp::-webkit-scrollbar-thumb {
    background-color: #ffffff8e; /* цвет плашки */
    border-radius: 20px;
    border: 3px solid #141425; /* padding вокруг плашки */
  }

  .warranty_item:hover {
    background-color: transparent;
  }

  .warranty_item:hover > .item_wrap > .item_arrow {
    opacity: 1;
  }
}

@media (max-width: 1160px) {
  .sectionwarranty_title {
    font-size: 36px;
    line-height: 52px;
    padding-top: 50px;
    margin-bottom: 15px;
  }
  .warranty_item {
    min-width: 330px;
  }

  .background_2_title {
    font-size: 24px;
    line-height: 32px;
    padding-top: 75px;
    padding-bottom: 20px;
  }

  .background_2 {
    padding-bottom: 20px;
  }

  .schema_w {
    width: 100%;
  }
}

@media (max-width: 990px) {
  .background_2_title {
    padding-top: 125px;
    padding-bottom: 0;
  }
}

@media (max-width: 900px) {
  .sectionwarranty_title {
    font-size: 22px;
    line-height: 30px;
    text-align: center;
    margin-bottom: 30px;
  }
  .background_2 {
    padding-bottom: 45px;
  }
  .background_2_title {
    font-size: 16px;
    line-height: 22px;
    padding-top: 80px;
    padding-bottom: 30px;
  }
  .warranty_item h4 {
    font-size: 16px;
    line-height: 20px;
  }
  .warranty_item p {
    font-size: 14px;
    line-height: 20px;
  }
  .schema_w {
    display: none;
  }
  .schema_wm {
    width: 100%;
  }
  .pinch {
    position: absolute;
    right: 30px;
    bottom: 30px;
  }
}

@media (min-width: 901px) {
  .schema_wm {
    display: none;
  }
  .pinch {
    display: none;
  }
}

@media (max-width: 690px) {
  .background_1 {
    width: calc(100% - 20px);
    padding-top: 0;
    padding-bottom: 0;
  }
  .background_2 {
    width: calc(100% - 20px);
  }
  .sectionwarranty_title {
    margin-bottom: 10px;
    padding-top: 40px;
  }
  .warranty_item {
    min-width: 300px;
  }
  .background_2_title {
    padding-top: 85px;
  }
}

@media (max-width: 550px) {
  .schema_wm {
    margin-top: -30px;
  }
}

@media (max-width: 480px) {
  .schema_wm {
    margin-top: -50px;
  }
  .background_2 {
    padding-bottom: 25px;
  }
  .pinch {
    width: 30px;
  }
}

@media (max-width: 430px) {
  .schema_wm {
    margin-top: -70px;
  }
  .background_2 {
    padding-bottom: 5px;
  }
}

@media (max-width: 370px) {
  .schema_wm {
    margin-top: -90px;
  }
  .background_2 {
    padding-bottom: 0;
  }
  .background_2_title {
    padding-top: 65px;
  }
}

@media (max-width: 330px) {
  .schema_wm {
    margin-top: -110px;
  }
  .background_2 {
    height: 395px;
  }
}
