.sectionclients {
  padding-top: 30px;
  padding-bottom: 100px;
}

.sectionclients h3 {
  font-family: 'Orto';
  font-weight: 800;
  font-size: 32px;
  line-height: 64px;
  color: #fff;
  margin-bottom: 45px;
}

.clients_list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-content: center;
}

.swiper_slide {
  box-sizing: border-box;
  width: 100%;
  height: 116px;
  border-radius: 6px;
  transition: outline 0.4s ease-in-out;
  margin: 0 auto;
}

.swiper_slide_img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border: 1px solid #ffffff5b;
  border-radius: 10px;
  transition: border-color 0.4s ease-in-out;
}

.swiper_slide_img:hover {
  border: 1px solid #fff;
}

@media (max-width: 900px) {
  .sectionclients h3 {
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    margin-bottom: 20px;
  }
}

@media (max-width: 430px) {
  .sectionclients {
    padding-bottom: 80px;
  }
}
