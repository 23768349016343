.yandexmaps {
  width: 100%;
}

.yandexmap {
  width: 100%;
  height: 490px;
}

/* [class*='copyrights-pane'] {
  display: none !important;
} */

[class*='ground-pane'] {
  filter: invert(83%);
}

@media (max-width: 900px) {
  .yandexmap {
    height: 390px;
  }
}

@media (max-width: 500px) {
  .yandexmap {
    height: 290px;
  }
}
