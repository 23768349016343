.link {
  width: 100%;
  max-width: 160px;
}

.clientsedit_wrap {
  width: 100%;
  max-width: 700px;
  position: relative;
}

.btn_close {
  width: 44px;
  height: 44px;
  position: absolute;
  top: 0;
  right: -55px;
  opacity: 0.2;
  transition: opacity 0.4s ease-in-out;
  z-index: 2;

  background-image: url(../../../../../../img/btn_close.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 44px 44px;
}

.btn_close:hover {
  opacity: 1;
}

.clientsedit {
  position: relative;
  width: 100%;
  max-width: 700px;
  max-height: 100vh;
  background: #06061c;
  border-radius: 20px;
  padding: 40px 40px 60px 40px;
  overflow-y: auto;

  scrollbar-color: #ffffff1f #ffffff07;
  scrollbar-width: thin;
}

.clientsedit::-webkit-scrollbar {
  width: 6px;
}

.clientsedit::-webkit-scrollbar-track {
  background: #ffffff07;
}

.clientsedit::-webkit-scrollbar-thumb {
  background-color: #ffffff1f;
  /* цвет плашки */
  border-radius: 20px;
  border: 1px solid #141425;
  /* padding вокруг плашки */
}

.form {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
}

.ft_edit_requisites {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 13px;
  border: none;
  padding: 0;
}

.edit_title {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #ffffff;
  margin-bottom: 30px;
}

.edit_input {
  width: 100%;
  max-width: 620px;
  border-bottom: 1px solid#ffffff33;
  transition: border-color 0.4s ease-in-out;
}

.edit_input:hover {
  border-bottom: 1px solid#ffffffc4;
}

.edit_field_wrap {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  padding: 0 0 15px 0;
}

.edit_field_title {
  width: 100%;
  max-width: 200px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
  opacity: 0.6;
  cursor: default;
  word-break: break-word;
}

.edit_field_text {
  width: 100%;
  /* max-width: 160px; */
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  min-height: 17px;
  height: fit-content;
  opacity: 1;
  transition: opacity 0.4s ease-in-out;
}

@media (max-width: 900px) {
  .btn_close {
    width: 22px;
    height: 22px;
    top: 20px;
    right: 20px;
    background-image: url(../../../../../../img/btn_close2.svg);
    background-size: 22px 22px;
  }
}

@media (max-width: 460px) {
  .clientsedit {
    padding: 40px 20px 60px 20px;
  }
}
