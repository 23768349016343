.section_geography {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
  margin-top: 50px;
  margin-bottom: 150px;
}

.text_block {
  max-width: 625px;
  width: 100%;
}

.image_container {
  position: relative;
  flex-grow: 1;
  min-width: 700px;
  height: 700px;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 1200px;
  height: 700px;
  background-image: url('../../../../img/map.svg');
  background-position: left;
  background-size: cover;
  background-repeat: no-repeat;
}

.title {
  color: #fff;
  font-size: 30px;
  font-weight: 500;
  line-height: 46px;
  margin-top: 60px;
  margin-bottom: 60px;
}
.description {
  color: #9b9ba4;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
}

@media (max-width: 1280px) {
  .image {
    top: -90px;
  }
}

@media (max-width: 1200px) {
  .section_geography {
    display: block;
  }
  .text_block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 66px;
    max-width: none;
    width: 100%;
  }

  .image_container {
    min-width: fit-content;
    height: 32em;
    background-image: url('../../../../img/map.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .image {
    display: none;
  }

  .title {
    margin-top: 0;
    margin-bottom: 0;
  }
}

@media (max-width: 960px) {
  .text_block {
    gap: 30px;
  }
  .title {
    font-size: 24px;
    line-height: 36px;
  }
  .description {
    font-size: 18px;
    line-height: 24px;
  }
  .image_container {
    height: 28em;
  }
}

@media (max-width: 760px) {
  .text_block {
    display: block;
  }
  .title {
    margin-bottom: 30px;
  }
  .description {
    margin-bottom: 30px;
  }
  .image_container {
    height: 22em;
  }
}

@media (max-width: 560px) {
  .image_container {
    height: 16em;
  }
}

@media (max-width: 460px) {
  .image_container {
    height: 10em;
  }
}
