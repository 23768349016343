.clientsedit_wrap {
  width: 100%;
  max-width: 890px;
  position: relative;
}

.small_width {
  max-width: 600px;
}

.btn_close {
  width: 44px;
  height: 44px;
  position: absolute;
  top: 0;
  right: -55px;
  opacity: 0.2;
  transition: opacity 0.4s ease-in-out;
  z-index: 2;

  background-image: url(../../../../../../img/btn_close.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 44px 44px;
}

.btn_close:hover {
  opacity: 1;
}

.clientsedit {
  position: relative;
  width: 100%;
  max-height: calc(100vh - 100px);
  background: #06061c;
  border-radius: 20px;
  padding: 40px 40px 40px 40px;
  overflow-y: auto;
  scrollbar-color: #ffffff1f #ffffff07;
  scrollbar-width: thin;
}

.clientsedit::-webkit-scrollbar {
  width: 6px;
}

.clientsedit::-webkit-scrollbar-track {
  background: #ffffff07;
}

.clientsedit::-webkit-scrollbar-thumb {
  background-color: #ffffff1f;
  /* цвет плашки */
  border-radius: 20px;
  border: 1px solid #141425;
  /* padding вокруг плашки */
}

.container {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
}

.window_detials {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 13px;
  border: none;
  padding: 0;
}

.hidden {
  display: none;
}

.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #ffffff;
  margin-bottom: 30px;
}

.column_names {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding: 14px 24px 14px 20px;
  background-color: #111126;
}

.column_names p {
  width: calc((100% - 250px) / 4);

  color: #9b9ba4;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.column_names p:first-child {
  width: 120px;
}

.column_names p:last-child {
  width: 110px;
}

.details {
  width: 100%;
}

.detail_wrap {
  width: 100%;
  /* display: flex;
  align-items: center;
  justify-content: space-between; */
  display: grid;
  grid-template-columns:
    minmax(90px, 1fr)
    minmax(60px, 1fr)
    minmax(60px, 1fr)
    minmax(60px, 1fr)
    minmax(60px, 1fr)
    minmax(60px, 1fr);
  align-items: center;
  gap: 10px;
  padding: 14px 24px;
  border-radius: 10px;
  border: 1px solid transparent;
  transition: border 0.4s ease-in-out;
}

.detail_wrap:hover {
  border: 1px solid #9b9ba4;
}

.wrap:first-child p {
  width: 90px;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.detail_elem {
  display: none;
  width: 40%;
}

.detail_item {
  width: 60%;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}

.detail_item_btn {
  width: 125px;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  padding: 10px 15px;
  border-radius: 10px;
  border: 1px solid #3d3d4b;
  transition: border 0.4s ease-in-out;
}

.detail_item_btn:hover {
  border: 1px solid #fff;
}

.window_order {
  width: 100%;
}

.form {
  width: 100%;
}

.form_elem {
  margin-bottom: 20px;
}

.form_elem_caption {
  color: #9b9ba4;
  font-family: NotoSans;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 4px;
}

.form_elem_data {
  width: 100%;
  padding: 18px 30px;
  border-radius: 30px;
  border: 1px solid #3d3d4b;
  background-color: #0d0d1e;
}

.addit_info {
  width: 100%;
  resize: none;
  padding: 20px 30px;
  color: #fff;
  margin-top: 20px;
  margin-bottom: 20px;

  border-radius: 30px;
  border: 1px solid #3d3d4b;
  background-color: #06061c;

  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.addit_info::placeholder {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.addit_info:focus {
  outline: 1px solid #52238180;
  outline-offset: -3px;
}

.fieldset_assent {
  border: none;
  max-width: 500px;
  width: 100%;
  padding: 0;
  margin-bottom: 30px;
}

.fieldset_assent p {
  border: none;
  max-width: 450px;
  width: 100%;
}

.label_check {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.label_check p {
  color: #9b9ba4;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-top: 6px;
}

.input_check {
  margin-top: 5px;
  position: relative;

  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  width: 24px;
  height: 24px;
  border: 1px solid #545454;
  border-radius: 5px;

  transform: translateY(0);
  background-color: transparent;
}

.input_check::before {
  position: absolute;
  content: '';
  inset: 0;
  transform: scale(0);
  background: url('../../../../../../img/checked.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 14px 14px;
  transition: transform 0.3s ease-in-out;
}

.input_check:checked::before {
  transform: scale(1);
}

.input_check:focus {
  outline: 1px solid #555555;
  outline-offset: -1px;
}

.btn_tosend {
  display: block;
  border-radius: 50px;
  background-color: #522381;
  padding: 26px 70px;
  transition: background-color 0.4s ease-in-out;
  margin: 0 0 0 auto;
}

.btn_tosend:hover {
  background-color: #1f0b32;
}

.detail_title {
  display: none;
  margin-bottom: 10px;
}
.column_names2 {
  display: none;
}

.detail_item_btn_mob {
  width: 100%;
  display: none;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  padding: 10px 15px;
  border-radius: 10px;
  border: 1px solid #3d3d4b;
  transition: border 0.4s ease-in-out;
}

.detail_item_btn_mob:hover {
  border: 1px solid #fff;
}

/* --------------------------------------------- */

@media (max-width: 1020px) {
  .btn_close {
    width: 22px;
    height: 22px;
    top: -30px;
    right: 20px;
    background-image: url(../../../../../../img/btn_close2.svg);
    background-size: 22px 22px;
  }
}

@media (max-width: 900px) {
  .detail_container {
    padding: 23px 20px;
    border-radius: 20px;
    border: 1px solid #545454;
    margin-bottom: 16px;
  }

  .detail {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .detail_wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 0;
    padding-right: 0;
  }

  .detail_wrap:hover {
    border: 1px solid transparent;
  }

  .column_names {
    display: none;
  }

  .detail_title {
    display: block;
  }

  .wrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
  }

  .wrap:first-child {
    display: none;
  }

  .detail_elem {
    display: block;
    color: #9b9ba4;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
  }

  .detail_item_btn {
    display: none;
  }

  .detail_item_btn_mob {
    display: block;
  }
}

@media (max-width: 580px) {
  .wrap {
    justify-content: space-between;
  }

  .detail_elem {
    width: max-content;
  }

  .detail_item {
    width: max-content;
  }
}

@media (max-width: 460px) {
  .clientsedit {
    padding: 40px 20px 60px 20px;
  }

  .detail {
    padding-bottom: 20px;
  }

  .label_check p {
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
  }
}

@media (max-width: 380px) {
  .payments_item p {
    font-size: 10px;
    line-height: 11px;
  }

  .column_names {
    padding: 14px 14px;
  }
  .payments_item {
    padding: 14px 14px;
  }

  .detail_wrap {
    gap: 20px;
  }
}
