.servicecenter_card {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 25px;
  border: 1px solid #3d3d4b;
  border-radius: 20px;
  background-color: #0d0d1e;
}

.servicecenter_logo {
  width: 150px;
  height: 40px;
  margin-bottom: 25px;
  object-fit: contain;
  border: 1px solid transparent;
}

.servicecenter_no_logo {
  width: 150px;
  height: 40px;
  margin-bottom: 25px;
  background-image: url('../../../../img/logo.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid transparent;
  filter: drop-shadow(2px 2px 8px #b692b5);
  /* border: 1px solid #3d3d4b; */
}

/* .servicecenter_no_logo {
  width: 150px;
  padding: 12px 0;
  margin-bottom: 25px;
  border: 1px solid #3d3d4b;
  text-align: center;
  font-size: 18px;
  font-weight: 300;
  line-height: 18px;
  color: #3d3d4b;
} */

.servicecenter_content {
  max-width: 600px;
  width: 100%;
  padding: 30px;
}

.servicecenter_map {
  max-width: 490px;
  width: 100%;
}

.wrap_title {
  width: 100%;
  height: 70px;
  position: relative;
}

.title {
  margin-bottom: 20px;

  color: #fff;
  font-size: 30px;
  font-weight: 500;
  line-height: 46px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
}

.title:hover {
  overflow: visible;
  position: absolute;
}

.servicecenter_list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.servicecenter_item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.servicecenter_item p {
  width: calc(100% - 32px);
  color: #9b9ba4;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.icon {
  width: 32px;
  height: 32px;
}

@media (max-width: 1280px) {
  .title {
    font-size: 22px;
    line-height: 34px;
  }

  .servicecenter_item p {
    font-size: 16px;
    line-height: 20px;
  }
}

@media (max-width: 1024px) {
  .servicecenter_content {
    padding: 10px;
  }
}

@media (max-width: 900px) {
  .servicecenter_card {
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
  }

  .servicecenter_content {
    padding: 0;
  }

  .servicecenter_logo {
    margin-bottom: 20px;
  }

  .servicecenter_map {
    max-width: 100%;
    height: 390px;
  }

  .servicecenter_map img {
    width: 100%;
    height: 390px;
    border-radius: 10px;
    object-fit: cover;
  }
}

@media (max-width: 500px) {
  .servicecenter_card {
    padding: 15px;
  }

  .servicecenter_item p {
    font-size: 14px;
    line-height: 18px;
  }

  .servicecenter_map {
    height: 290px;
  }

  .servicecenter_map img {
    height: 290px;
  }
}
