.docsedit_wrap {
  width: 100%;
  max-width: 600px;
  position: absolute;
}

.docsedit {
  position: relative;
  width: 100%;
  max-width: 880px;
  max-height: calc(100vh - 100px);
  background: #06061c;
  border-radius: 20px;
  padding: 42px;
  overflow-y: auto;

  scrollbar-color: #ffffff1f #ffffff07;
  scrollbar-width: thin;
}

.docsedit::-webkit-scrollbar {
  width: 6px;
}

.docsedit::-webkit-scrollbar-track {
  background: #ffffff07;
}

.docsedit::-webkit-scrollbar-thumb {
  background-color: #ffffff1f;
  /* цвет плашки */
  border-radius: 20px;
  border: 1px solid #141425;
  /* padding вокруг плашки */
}

.btn_close {
  position: absolute;
  top: 0;
  right: -58px;
  opacity: 0.2;
  width: 44px;
  height: 44px;
  border: 1px solid #fff;
  border-radius: 50%;
  background-image: url('../../../../../../img/btn_close2.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 15px 15px;
  transition: opacity 0.4s ease-in-out;
  z-index: 2;
}

.btn_close:hover {
  opacity: 1;
}

.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #fff;
  margin-bottom: 25px;
}

.sub_title {
  color: #9b9ba4;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 40px;
}

/* .form {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
} */

.fieldset {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* gap: 40px; */
}

.fieldset_hide {
  display: none;
}

.input_image {
  position: absolute;
  z-index: -1;
  opacity: 0;
  display: block;
  width: 0;
  height: 0;
}

.doc_preview_show {
  display: block;
}

.doc_preview_show_p {
  position: absolute;
  top: -22px;
  left: 10px;

  font-family: 'NotoSans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  opacity: 0.6;
}

.doc_preview_hide {
  display: none;
}

.doc_preview {
  position: relative;
  width: 330px;
  max-height: 170px;
  border: 1px solid #ffffff33;
  border-radius: 30px;
  padding: 13px 13px;

  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.pdf_preview {
  overflow: hidden;
  text-overflow: ellipsis;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;

  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  padding: 0 13px;
}

.btn_delete {
  position: absolute;
  top: -14px;
  right: -14px;
  width: 32px;
  height: 32px;
  background-image: url('../../../../../../img/btn_close.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.wrap_title {
  position: relative;
  width: 100%;
}

.wrap_title_p {
  position: absolute;
  top: -22px;
  left: 10px;

  font-family: 'NotoSans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  opacity: 0.6;
}

.wrap_title_p_hide {
  display: none;
}

.label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  width: 100%;
  border-radius: 30px;
  border: 1px solid #3d3d4b;
  padding: 18px 30px;
  background-color: transparent;
  margin-bottom: 40px;
  transition: border-color 0.4s ease-in-out;
}

.label:hover {
  border-color: #fff;
}

.input {
  border: 1px solid #ffffff33;
  border-radius: 30px;
  padding: 18px 30px;
  cursor: default;
  margin-bottom: 40px;

  min-width: 100%;
  background-color: transparent;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  transition: border-color 0.4s ease-in-out;
}

.input:disabled {
  background-color: #101025;
}

.input:hover {
  border-color: #fff;
}

.input:focus {
  outline: none;
  border-color: #fff;
}

.input::placeholder {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #9b9ba4;
}

.input_phone {
  width: 100%;
  border: none;
  background-color: transparent;

  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.input_phone:focus {
  outline: none;
}

.input_phone:focus-within {
  outline: none;
  border-color: #fff;
}

.input_phone::placeholder {
  color: #9b9ba4;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.select {
  width: max-content;
  color: #fff;
  border-radius: 4px;
  background-color: transparent;
  border: none;

  appearance: none;

  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.select:focus {
  outline: none;
  border: none;
}

.select option {
  border-radius: none;
  background-color: #1c1c2c;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #9b9ba4;
}

.description {
  margin-bottom: 40px;

  color: #9b9ba4;
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.note_empty {
  padding: 0 5px 4px 5px;
  height: 0;
  overflow: hidden;
  opacity: 0;

  color: #e60b0b;
  font-family: NotoSans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  transition: height 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

.note_hide {
  padding: 0 5px 4px 5px;
  height: 0;
  overflow: hidden;
  opacity: 0;

  color: #e60b0b;
  font-family: NotoSans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  transition: height 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

.note_mail {
  padding: 0 5px 4px 5px;
  height: 22px;
  overflow: hidden;
  opacity: 1;

  color: #e60b0b;
  font-family: NotoSans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  transition: height 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

.input_news {
  min-width: 100%;
  height: 100%;
  background-color: transparent;
  padding: 8px 10px;
  border: none;
  color: #ffffff90;
  resize: none;

  scrollbar-color: #ffffff1f #ffffff07;
  scrollbar-width: thin;
}

.input_news::-webkit-scrollbar {
  width: 6px;
}

.input_news::-webkit-scrollbar-track {
  background: #ffffff07;
}

.input_news::-webkit-scrollbar-thumb {
  background-color: #ffffff1f;
  /* цвет плашки */
  border-radius: 20px;
  border: 1px solid #141425;
  /* padding вокруг плашки */
}

.input_news:focus {
  outline: none;
}

.input_news::placeholder {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  opacity: 0.6;
}

.input_date {
  min-width: 100%;
  background-color: transparent;
  padding: 0;
  border: none;
  color: #ffffff90;
}

.input_date::-webkit-calendar-picker-indicator {
  filter: invert(0.6);
}

.input_date:focus {
  outline: none;
}

.input_date::placeholder {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  opacity: 0.6;
}

.input_date::before {
  content: attr(data-placeholder);
  width: 100%;
  height: 18px;
  background-color: #522381;
}
.input_date:focus::before,
.input_date:valid::before {
  display: none;
}

.btn_submit {
  align-self: end;
  width: max-content;
  background-color: #522381;
  border-radius: 50px;
  padding: 20px 70px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #ffffff;
  transition: background-color 0.4s ease-in-out;
}

.btn_submit:hover {
  background-color: #1f0b32;
}

.note_wrap {
  width: 100%;
  margin-bottom: 10px;
}

.input_wrap {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.input_code {
  width: 58px;
  border-radius: 20px;
  border: 1px solid #3d3d4b;
  background-color: transparent;
  padding: 20px 18px;

  color: #fff;
  font-size: 32px;
  font-weight: 400;
  line-height: normal;
}

.input_code:focus {
  outline: none;
}

.input_code::placeholder {
  color: #9b9ba4;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.btns_wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}

.btn_back {
  display: block;
  width: 42px;
  height: 42px;
  border: 1px solid #fff;
  border-radius: 50%;
  background-image: url('../../../../../../img/arrow_left.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 22px 20px;
  opacity: 0.2;
  transition: opacity 0.4s ease-in-out;
}

.btn_back:hover {
  opacity: 1;
}

.button_submit {
  width: 100%;
  max-width: 270px;
  padding: 18px 26px;
  border-radius: 50px;
  background-color: #522381;
  transition: background-color 0.4s ease-in-out;
  align-self: flex-end;

  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.button_submit:hover {
  background-color: #1f0b32;
}

.button_submit:disabled {
  color: #8d8888;
  background-color: #42364e;
  cursor: default;
}

.button_submit2 {
  width: 100%;
  max-width: 270px;
  padding: 20px 30px;
  border-radius: 50px;
  background-color: #522381;
  transition: background-color 0.4s ease-in-out;
  align-self: flex-end;

  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.button_submit2:hover {
  background-color: #1f0b32;
}

.button_submit2:disabled {
  width: 100%;
  padding: 20px 30px;
  border-radius: 50px;
  color: #8d8888;
  background-color: #42364e;
  cursor: default;
}

.note_code {
  padding: 0 5px 4px 5px;
  height: 22px;
  overflow: hidden;
  opacity: 1;
  text-align: center;

  color: #e60b0b;
  font-family: NotoSans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  transition: height 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

.note_hide {
  padding: 0 5px 4px 5px;
  height: 0;
  overflow: hidden;
  opacity: 0;

  color: #e60b0b;
  font-family: NotoSans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  transition: height 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

.button_forgot {
  /* margin: 0 auto; */
  padding: 8px 18px;
  border-radius: 20px;
  transition: background-color 0.4s ease-in-out, color 0.4s ease-in-out;

  color: #9b9ba4;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.button_forgot:hover {
  color: #fff;
  background-color: #06061c;
}

.notice {
  width: 100%;
  text-align: center;

  color: #9b9ba4;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.wrapper {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

@media (max-width: 768px) {
  .docsedit_wrap {
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    max-width: 100%;
    background-color: #06061c;
  }

  .title {
    font-size: 16px;
  }

  .input {
    font-size: 14px;
  }

  .input::placeholder {
    font-size: 14px;
  }

  .btn_submit {
    font-size: 14px;
    width: 100%;
  }

  .fieldset {
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
  }

  .btn_close {
    top: 20px;
    right: 20px;
    border: none;
    opacity: 1;
  }

  .button_submit {
    max-width: 100%;
    font-size: 14px;
  }

  .button_submit2 {
    max-width: calc(100% - 69px);
    font-size: 14px;
  }
}

@media (max-width: 420px) {
  .docsedit {
    padding-left: 20px;
    padding-right: 20px;
  }

  .label {
    padding: 18px 20px;
  }

  .input_phone::placeholder {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    padding: 20px 20px 20px 0;
  }

  .wrapper {
    height: 110px;
  }

  .notice {
    font-size: 14px;
  }

  .sub_title {
    font-size: 14px;
  }

  .button_forgot {
    font-size: 14px;
  }
}
