.main {
  padding-bottom: 60px;
}

.flashlight1 {
  position: fixed;
  top: 0;
  left: -300px;
  width: 1338px;
  height: 1338px;
  border-radius: 1338px;
  opacity: 0.25;
  background: radial-gradient(50% 50% at 50% 50%, #e60b0b 0%, #e72a4600 100%);
  z-index: -1;
}

.flashlight2 {
  position: fixed;
  top: -900px;
  right: -600px;
  width: 1338px;
  height: 1338px;
  border-radius: 1338px;
  opacity: 0.25;
  background: radial-gradient(50% 50% at 50% 50%, #e60b0b 0%, #e72a4600 100%);
  z-index: -1;
}

.title {
  margin-top: 150px;
  margin-bottom: 75px;

  color: #fff;
  font-family: Orto;
  font-size: 72px;
  font-weight: 800;
  line-height: 90px;
}

.docs_list {
  width: 100%;
  display: grid;
  /* grid-template-columns: repeat(5, 260px); */
  /* display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between; */
  /* gap: 4.6%; */

  grid-template-columns:
    minmax(260px, 20%)
    minmax(260px, 20%)
    minmax(260px, 20%)
    minmax(260px, 20%)
    minmax(260px, 20%);
  justify-items: center;
  gap: 20px;
}

.preloader {
  width: 100%;
  height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.item_fence {
  /* width: 260px;
  height: 487px; */
  pointer-events: none;
}

.item_li {
  width: 260px;
  /* height: 487px; */
  transition: transform 0.4s ease-in-out;
}

.item_li:hover {
  transform: scale(1.0036);
}

.item_li:hover .docs_item_p {
  color: #864390;
}

.docs_item_forobject {
  position: relative;
  width: 260px;
  height: 487px;
  pointer-events: none;
  overflow: hidden;
}

.object {
  position: absolute;
  width: 350px;
  height: 420px;
  top: -45px;
  left: -45px;
}

.docs_item_p {
  margin-top: 20px;
  margin-bottom: 15px;
  cursor: default;

  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;

  color: #fff;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;

  transition: color 0.4s ease-in-out;
}

.docs_item_forobject_p {
  position: absolute;
  bottom: 0;
  margin-top: 20px;
  margin-bottom: 15px;

  height: 74px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;

  color: #fff;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}

@media (max-width: 1450px) {
  .title {
    margin-top: 90px;
  }

  .docs_list {
    grid-template-columns:
      minmax(260px, 25%)
      minmax(260px, 25%)
      minmax(260px, 25%)
      minmax(260px, 25%);
  }
}

@media (max-width: 1280px) {
  .main {
    padding-bottom: 90px;
  }

  .title {
    margin-top: 105px;
    margin-bottom: 90px;
    font-size: 54px;
    line-height: 64px;
  }
}

@media (max-width: 1180px) {
  .docs_list {
    grid-template-columns:
      minmax(260px, 33.3%)
      minmax(260px, 33.3%)
      minmax(260px, 33.3%);
  }
}

@media (max-width: 1024px) {
  .title {
    font-size: 43px;
    line-height: 54px;
    margin-top: 70px;
    margin-bottom: 70px;
  }
}

@media (max-width: 860px) {
  .docs_list {
    grid-template-columns:
      minmax(260px, 50%)
      minmax(260px, 50%);
  }
}

@media (max-width: 768px) {
  .title {
    font-size: 32px;
    line-height: normal;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .docs_item_p {
    font-size: 16px;
    line-height: 22px;
  }
}

@media (max-width: 590px) {
  .docs_list {
    justify-content: space-around;
    grid-template-columns: minmax(260px, 100%);
  }
}
