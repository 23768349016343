.overlay_show {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #0d0d1e80;
  backdrop-filter: blur(15px);
  z-index: 101;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: transform 0.2s ease-in-out, opacity 0.6s ease-in-out;
}

.overlay_hide {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #0d0d1e80;
  backdrop-filter: blur(15px);
  z-index: 101;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transform: scale(0);
  transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

.lk_equipment {
  width: 100%;
  max-width: 1278px;
  padding-bottom: 80px;
}

.notification {
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: var(--color);
  margin-top: 40px;
}

.wrap_1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  margin-top: -13px;
  margin-bottom: 6px;
}

.title {
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: var(--color);
}

.btn_add {
  border-radius: 30px;
  border: 1px solid #3d3d4b;
  padding: 20px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  transition: border-color 0.4s ease-in-out;

  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}

.btn_add:hover {
  border-color: #fff;
}

.btn_add:focus {
  border-color: #fff;
}

.btn_filters {
  border-radius: 30px;
  border: 1px solid #3d3d4b;
  padding: 20px 30px;
  display: none;
  align-items: center;
  justify-content: center;
  gap: 16px;
  background-color: transparent;
  color: #fff;
  transition: background-color 0.4s ease-in-out, color 0.4s ease-in-out;

  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}

.btn_filters_active {
  border-radius: 30px;
  border: 1px solid #3d3d4b;
  padding: 20px 30px;
  display: none;
  align-items: center;
  justify-content: center;
  gap: 16px;
  background-color: #fff;
  color: #06061c;
  transition: background-color 0.4s ease-in-out, color 0.4s ease-in-out;

  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}

.btn_filters:hover {
  background-color: #fff;
  color: #06061c;
}

.filter_list {
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;

  border-radius: 5px;
  padding-right: 1px;
  padding-left: 1px;

  padding-top: 18px;
  padding-bottom: 30px;
  margin-bottom: 5px;
  transition: height 0.4s ease-in-out, padding-top 0.4s ease-in-out, padding-bottom 0.4s ease-in-out;
}

.filter_list_show {
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  flex-wrap: wrap;
  border-radius: 5px;
  padding-right: 1px;
  padding-left: 1px;

  padding-top: 18px;
  padding-bottom: 30px;
  margin-bottom: 5px;
  transition: height 0.4s ease-in-out, padding-top 0.4s ease-in-out, padding-bottom 0.4s ease-in-out;
}

.filter_item {
  position: relative;
}
.filter_item_empty {
  display: none;
}

.filter_item:nth-child(1) {
  width: 80px;
}

.filter_item:nth-child(2) {
  width: 100px;
}

.filter_item:nth-child(3) {
  width: 140px;
}

.filter_item:nth-child(4) {
  width: 100px;
}

.filter_item:nth-child(5) {
  width: 170px;
}

.filter_item:nth-child(6) {
  width: 170px;
}

.filter_item:nth-child(7) {
  width: 125px;
}

.filter_item:nth-child(8) {
  width: 140px;
}

.filter_item:nth-child(9) {
  width: 140px;
}

.filter_item p {
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}

.filter_input {
  width: 100%;
  padding: 8px 16px 8px 8px;
  color: #fff;
  border-radius: 5px;
  /* background-color: #06061c; */
  background-color: transparent;
  border: 1px solid #3d3d4b;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
}

.filter_input:focus {
  outline-color: #9b9ba4;
  outline-offset: 0;
}

.clear_input {
  display: none;
}

.filter_input:focus + .clear_input {
  position: absolute;
  top: 7px;
  right: 5px;
  cursor: default;
  display: block;
}

.inp {
  position: relative;
}

.sel {
  padding-right: 8px;
  border-radius: 5px;
  background-color: transparent;
  border: 1px solid #ffffff34;
}

.filter_select {
  width: 100%;
  padding: 8px 16px 8px 4px;
  color: #fff;
  border-radius: 5px;
  background-color: var(--background);
  border: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
}

.filter_select option {
  color: #fff;
  background-color: #1c1c2c;
}

.filter_select:focus {
  outline: 1px solid transparent;
}

.filter_select:focus + .select_focus {
  position: absolute;
  inset: 0;
  border-radius: 4px;
  border: 1px solid #9b9ba4;
}

/* .lk_equip_body {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 25px;
  margin-bottom: 25px;
} */

.lk_equip_body {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
  flex-wrap: wrap;
  margin-bottom: 25px;
}

/* --------------------------------------------- */

.pagination {
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.pagination_btn_prev {
  width: 44px;
  height: 44px;
  background-image: url('../../../../img/btn_right.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 10px 16px;
  transform: rotate(180deg);
  opacity: 0.2;
  transition: opacity 0.3s ease-in-out;
}

.pagination_btn_prev:hover {
  opacity: 1;
}

.pagination_btn_next {
  width: 44px;
  height: 44px;
  background-image: url('../../../../img/btn_right.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 10px 16px;
  opacity: 0.2;
}

.pagination_btn_next:hover {
  opacity: 1;
}

.pagination_list {
  display: flex;
  align-items: center;
  gap: 4px;
}

.pagination_item {
  width: 44px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  padding: 10px 0;
  border-radius: 50%;
  background-color: transparent;
  opacity: 0.2;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.pagination_item:hover {
  background-color: #06061c;
  opacity: 1;
}

.pagination_item_active {
  width: 44px;
  height: 44px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  padding: 10px 0;
  border-radius: 50%;
  background-color: transparent;
  color: #fff;
  cursor: pointer;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.hidden {
  display: none;
}

/* --------------------------------------------- */

@media (max-width: 1540px) {
  .filter_item {
    flex-grow: 1;
  }

  .filter_item_empty {
    display: block;
    flex-grow: 16;
    height: 35px;
  }
}

@media (max-width: 1120px) {
  .filter_list {
    /* justify-content: flex-start; */
  }
}

@media (max-width: 900px) {
  .title {
    display: none;
  }

  .wrap_1 {
    margin-bottom: 12px;
  }

  .btn_add {
    min-width: 246px;
    font-size: 12px;
    gap: 6px;
    padding: 10px 13px 10px 13px;
  }

  .btn_filters {
    display: flex;
    min-width: 246px;
    font-size: 12px;
    gap: 6px;
    padding: 10px 13px 10px 13px;
  }

  .btn_filters_active {
    display: flex;
    min-width: 246px;
    font-size: 12px;
    gap: 6px;
    padding: 10px 13px 10px 13px;
  }

  .filter_list {
    flex-direction: column;
    height: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .filter_list_show {
    flex-direction: column;
    height: 380px;

    padding-top: 1px;
    margin-bottom: 0;
    padding-bottom: 10px;
  }

  .filter_input {
    border-radius: 18px;
    padding-left: 16px;
  }

  .filter_select {
    margin-left: 12px;
    width: calc(100% - 14px);
  }

  .filter_item {
    border-radius: 18px;
  }

  .filter_select:focus + .select_focus {
    border-radius: 18px;
  }

  .filter_item:first-child {
    display: none;
  }

  .filter_input:focus + .clear_input {
    right: 15px;
  }

  .filter_item:nth-child(2) {
    width: 100%;
  }

  .filter_item:nth-child(3) {
    width: 100%;
  }

  .filter_item:nth-child(4) {
    width: 100%;
  }

  .filter_item:nth-child(5) {
    width: 100%;
  }

  .filter_item:nth-child(6) {
    width: 100%;
  }

  .filter_item:nth-child(7) {
    width: 100%;
  }

  .filter_item:nth-child(8) {
    width: 100%;
  }

  .filter_item:nth-child(9) {
    width: 100%;
  }
}

@media (max-width: 540px) {
  .wrap_1 {
    flex-wrap: wrap;
    margin-top: 3px;
    margin-bottom: 30px;
  }

  .btn_add {
    width: 100%;
  }

  .btn_filters {
    width: 100%;
  }

  .btn_filters_active {
    width: 100%;
  }
}

/* =========================================== */
