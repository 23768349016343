.fias_address_group {
  width: 100%;
}

.wrap_title {
  position: relative;
  width: 100%;
}

.mb30 {
  margin-bottom: 30px;
}

.wrap_title_h6 {
  margin-bottom: 35px;
  font-family: 'NotoSans';
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #ffffff;
  opacity: 0.6;
}
.wrap_title_p {
  position: absolute;
  top: -22px;
  left: 10px;

  font-family: 'NotoSans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  opacity: 0.6;
  z-index: 999;
}

.wrap_title_p_hide {
  display: none;
}

.input_search_wrap {
  border-radius: 30px;
  border: none;
  height: 60px;
  max-height: 375px;
  overflow: hidden;
  outline: 1px solid #4f5152;
  outline-offset: -1px;
  transition: outline 0.4s ease-in-out height 0.4s ease-in-out;
}

.input_search_wrap:focus-within {
  outline: 1px solid #9b9ba4;
  height: 100%;
}

.input_search_wrap:hover {
  outline: 1px solid #9b9ba4;
}

.input_search {
  width: 100%;
  border-radius: 30px;
  padding: 18px 30px;
  background-color: transparent;

  border: none;
  caret-color: #9b9ba4;

  color: #ffffff8e;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.input_search:focus {
  border: none;
  outline: none;
}

.input_search::placeholder {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #fff;
}

/* для мозиллы */
.input_search::-moz-placeholder {
  color: #ffffff8e !important;
}

/* для хрома и оперы */
.input_search::-webkit-input-placeholder {
  color: #ffffff8e !important;
}

/* для интернет-эксплорера */
.input_search:-ms-input-placeholder {
  color: #ffffff8e !important;
}

/* для edge */
.input_search::-ms-input-placeholder {
  color: #ffffff8e !important;
}

.input_reset {
  cursor: pointer;
  padding: 8px 30px;
  color: #ffffff4f;
  border-top: 1px solid #ffffff46;
  border-bottom: 1px solid #ffffff46;
  background-color: #0d0d1e;
  transition: color 0.2s ease-in-out;
}

.input_reset:hover {
  color: #d38383;
}
.list_search {
  width: 100%;
  margin-top: 6px;
  border-radius: 5px;
  font: 500 14px/18px Montserrat;
  cursor: pointer;

  max-height: 295px;
  overflow: auto;

  font: 600 16px/20px Montserrat;
  transition: border-color 0.4s ease-in-out;
  padding-bottom: 40px;
  background-color: #0d0d1e;
}

.item_search {
  padding: 8px 30px;
  color: #ffffff8f;
  background-color: #0d0d1e;
  transition: color 0.2s ease-in-out;
}

.item_search:hover {
  color: #fff;
}
