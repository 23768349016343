.sectionnews {
  padding-top: 75px;
  padding-bottom: 70px;
}

.sectionnews_title {
  font-family: 'Orto';
  font-weight: 800;
  font-size: 32px;
  line-height: 64px;
  color: #fff;
  margin-bottom: 25px;
}

.container {
  position: relative;
}

.control_arrows {
  position: absolute;
  top: 6px;
  right: 20px;
  display: flex;
  align-items: center;
  gap: 36px;
}

.control_arrows button {
  padding: 12px;
  border-radius: 50%;
  transition: background-color 0.4s ease-in-out;
}

.control_arrows button:hover {
  background-color: #00010c;
}

.control_arrows button:disabled {
  opacity: 0.2;
  cursor: default;
}

.slider_news {
  margin-bottom: 40px;
}

.news_list_nodata {
  width: max-content;
  display: flex;
  align-items: center;
  gap: 38px;
  margin-bottom: 25px;
  transition: transform 0.8s ease-in-out;
}

.news_list_prev {
  width: max-content;
  display: flex;
  align-items: center;
  gap: 38px;
  margin-bottom: 25px;
  transition: transform 0.8s ease-in-out;
}

.news_list_next {
  width: max-content;
  display: flex;
  align-items: center;
  gap: 38px;
  margin-bottom: 25px;
  transition: transform 0.8s ease-in-out;
}

.all_news {
  position: relative;
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  outline: 1px solid #ffffff34;
  outline-offset: -4px;
  background: transparent;
  transition: outline 0.4s ease-in-out, outline-offset 0.4s ease-in-out;
  cursor: pointer;
  border: none;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.all_news_correct {
  background-color: red;
}

.all_news_link {
  position: absolute;
  inset: 0;
}

.all_news_btn {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  border: 2px solid #fff;
  margin-bottom: 20px;
  background-image: url('../../img/arrow_right.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 18px 18px;
  transition: width 0.4s ease-in-out, height 0.4s ease-in-out, background-size 0.4s ease-in-out;
}

.all_news:hover {
  outline: 3px solid #fff;
  outline-offset: -2px;
  background: radial-gradient(38% 39% at 50% 46%, #1d1029 0%, #0d0d1e 100%);
}

.all_news:hover > .all_news_btn {
  width: 60px;
  height: 60px;
  background-size: 26px 26px;
}

.all_news_title {
  padding-top: 90px;
  font-weight: 700;
  font-size: 20px;
  line-height: 33px;
  color: #fff;
}

.scrollbar {
  width: 100%;
  height: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #ffffff0c;
}

.thumb_prev {
  width: 50%;
  height: 8px;
  border-radius: 6px;
  margin-left: 4px;
  background-color: #ffffff52;
  transform: translateX();
  transition: transform 0.8s ease-in-out;
}

.thumb_next {
  width: 50%;
  height: 8px;
  border-radius: 6px;
  margin-left: 4px;
  background-color: #ffffff52;
  transform: translateX(800px);
  transition: transform 0.8s ease-in-out;
}

@media (max-width: 900px) {
  .sectionnews {
    padding-top: 60px;
    padding-bottom: 10px;
  }

  .sectionnews_title {
    font-size: 16px;
    line-height: 22px;
    text-align: center;
  }

  .control_arrows {
    display: none;
  }

  .news_list_prev {
    gap: 20px;
  }

  .news_list_next {
    gap: 20px;
  }

  .all_news_title {
    font-size: 16px;
    line-height: 22px;
  }
}
