.newsread {
  min-height: 80vh;
}

.flashlight1 {
  position: fixed;
  top: 0;
  left: -300px;
  width: 1338px;
  height: 1338px;
  border-radius: 1338px;
  opacity: 0.25;
  background: radial-gradient(50% 50% at 50% 50%, #e60b0b 0%, #e72a4600 100%);
  z-index: -1;
}

.flashlight2 {
  position: fixed;
  top: -900px;
  right: -600px;
  width: 1338px;
  height: 1338px;
  border-radius: 1338px;
  opacity: 0.25;
  background: radial-gradient(50% 50% at 50% 50%, #e60b0b 0%, #e72a4600 100%);
  z-index: -1;
}

.btn_wrap {
  display: flex;
  align-items: center;
  gap: 18px;
  margin-top: 70px;
  margin-bottom: 55px;
  cursor: pointer;
}

.btn_wrap p {
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-transform: uppercase;
}

.btn_back {
  width: 54px;
  height: 54px;
  border: 1px solid #fff;
  border-radius: 50%;
  background-image: url('../../../../img/arrow_left.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 26px 24px;
}

.wrap_text {
  max-width: 925px;
  width: 100%;
  margin: 0 auto;
}

.title {
  color: #fff;
  font-family: Orto;
  font-size: 54px;
  font-weight: 800;
  line-height: 68px;
  margin-bottom: 25px;
}

.date {
  color: #9b9ba4;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 40px;
}

.image {
  width: 100%;
  max-width: 1200px;
  max-height: 600px;
  margin: 0 auto 40px;
  border-radius: 20px;
  object-fit: cover;
}

.text_read {
  max-width: 925px;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  white-space: pre-wrap;

  display: flex;
  align-items: flex-end;
  gap: 10px;
  flex-wrap: wrap;

  color: #9b9ba4;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
}

.text_read h1 {
  display: flex;
  margin: 0;
  padding: 0 0 6px;
  /* flex-wrap: wrap; */
}

.text_read p {
  display: block;
}

.text_read a {
  color: #00ccff;
  transition: color 0.4s ease-in-out;
}

.text_read a:hover {
  color: #b1158f;
}

@media (max-width: 1024px) {
  .title {
    font-size: 43px;
  }
}

@media (max-width: 768px) {
  .flashlight1 {
    display: none;
  }

  .btn_wrap {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .btn_wrap p {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }

  .btn_back {
    width: 24px;
    height: 24px;
    background-size: 12px 10px;
  }

  .title {
    font-size: 34px;
    line-height: 40px;
  }

  .date {
    font-size: 14px;
    line-height: 20px;
  }

  .text_read {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 90px;
  }
}

@media (max-width: 590px) {
  .title {
    font-size: 28px;
    line-height: 34px;
    word-wrap: break-word;
  }

  .date {
    font-size: 14px;
    line-height: 20px;
  }

  .text_read {
    font-size: 16px;
    line-height: 22px;
  }
}
