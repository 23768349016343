.overlay_show {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #0d0d1e80;
  backdrop-filter: blur(15px);
  z-index: 101;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: transform 0.2s ease-in-out, opacity 0.6s ease-in-out;
}

.overlay_hide {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #0d0d1e80;
  backdrop-filter: blur(15px);
  z-index: 101;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transform: scale(0);
  transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

.docs_item {
  width: 100%;
  display: grid;
  grid-template-columns:
    minmax(max-content, 50px)
    minmax(110px, 1050px)
    minmax(max-content, 70px);

  align-items: center;
  justify-content: space-between;
  padding: 30px 24px;
  cursor: default;
  border-radius: 10px;
  border: 1px solid transparent;
  transition: border-color 0.4s ease-in-out;
}

.docs_item:hover {
  border-color: #fff;
}

.news_title {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  line-clamp: 4;
  -webkit-box-orient: vertical;
}

.item_date {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}

.item_btn {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: transparent;
  background-image: url('../../../../../../img/btn_points.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  justify-self: end;
  opacity: 0.2;
  transition: opacity 0.3s ease-in-out, background-color 0.3s ease-in-out;
  cursor: pointer;
}

.item_btn:hover {
  background-color: #06061c;
  opacity: 1;
}

.item_edit_del {
  position: relative;
  justify-self: end;
}

.btn_menu_item {
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  opacity: 0.6;
  padding: 6px 0;
  transition: color 0.3s ease-in-out;
}

.btn_menu_item:hover {
  color: #e60b0b;
}

.btn_menu {
  position: absolute;
  width: 164px;
  height: 104px;
  top: 40px;
  right: 35px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  justify-content: space-between;
  padding: 12px 18px;
  background-color: #0d0d1e;
  border: 1px solid #cccccc;
  box-shadow: 0px 8px 15px #0000000d;
  border-radius: 5px;
  transform: scale(0);
  opacity: 0;
  cursor: default;
  transition: opacity 0.2s ease-in-out, transform 0.4s ease-in-out;
}

.btn_menu_show {
  position: absolute;
  width: 164px;
  height: 104px;
  top: 40px;
  right: 35px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  justify-content: space-between;
  padding: 12px 18px;
  background-color: #0d0d1e;
  border: 1px solid #cccccc;
  box-shadow: 0px 8px 15px #0000000d;
  border-radius: 5px;
  cursor: default;
  opacity: 1;
  z-index: 2;
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
}
