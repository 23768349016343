.clientsedit_wrap {
  display: flex;
  justify-content: center;
  position: relative;
}

.clientsedit {
  position: relative;
  width: 700px;
  max-height: calc(100vh - 120px);
  background: #06061c;
  border-radius: 20px;
  padding: 40px 40px 60px 40px;
  overflow-y: auto;

  scrollbar-color: #ffffff1f #ffffff07;
  scrollbar-width: thin;
}

.clientsedit::-webkit-scrollbar {
  width: 6px;
}

.clientsedit::-webkit-scrollbar-track {
  background: #ffffff07;
}

.clientsedit::-webkit-scrollbar-thumb {
  background-color: #ffffff1f;
  /* цвет плашки */
  border-radius: 20px;
  border: 1px solid #141425;
  /* padding вокруг плашки */
}

.btn_close {
  position: absolute;
  top: 0;
  right: -55px;
  opacity: 0.2;
  transition: opacity 0.4s ease-in-out;
  z-index: 2;
}

.btn_close_back {
  position: absolute;
  top: 0;
  right: -55px;
  opacity: 0.2;
  transition: opacity 0.4s ease-in-out;
  z-index: 2;
}

.btn_close:hover {
  opacity: 1;
}

.btn_close_back:hover {
  opacity: 1;
}

.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #ffffff;
}

.form {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
}

.ft_edit_client {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  border: none;
  padding: 0;
}

.ft_edit_requisites {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 13px;
  border: none;
  padding: 0;
}

.ft_edit_payments {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  border: none;
  padding: 0;
}

.hidden {
  position: absolute;
  top: -100px;
  left: 0;
  height: 0;
  overflow: hidden;
}

.label_image {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.label_image p {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.input_image {
  position: absolute;
  z-index: -1;
  opacity: 0;
  display: block;
  width: 0;
  height: 0;
  overflow: hidden;
}

.image_preview_show {
  display: block;
}

.image_preview_hide {
  display: none;
}

.image_preview_show_p {
  position: absolute;
  top: -22px;
  left: 10px;

  font-family: 'NotoSans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  opacity: 0.6;
}

.image_preview {
  font-weight: 100;
  font-size: 10px;
  position: relative;
  width: 170px;
  max-height: 170px;
  border: 1px solid #ffffff33;
  border-radius: 20px;
  padding: 15px 13px;
}

.btn_delete {
  position: absolute;
  top: -14px;
  right: -14px;
  width: 32px;
  height: 32px;
  background-image: url('../../../../../../img/btn_close.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.wrap_title {
  position: relative;
  width: 100%;
}

.wrap_title_p {
  position: absolute;
  top: -22px;
  left: 10px;

  font-family: 'NotoSans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #9b9ba4;
}

.wrap_title_p_hide {
  display: none;
}

.input_wrap {
  width: 100%;
  border: 1px solid #ffffff33;
  border-radius: 30px;
  padding: 10px 20px;
}

.input_wrap_2 {
  width: 100%;
  min-width: 220px;
  border: 1px solid #ffffff33;
  border-radius: 30px;
  padding: 10px 20px;
}

.placeholderedit {
  position: absolute;
  top: -3px;
  left: 0;
  width: 80%;
  background-color: #06061c;

  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #9b9ba4;
}

.label_title {
  position: relative;
}

.input_calendar {
  width: 100%;
  border-radius: 6px;
  background-color: #06061c;
  border: none;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  opacity: 1;
  color-scheme: dark;
}

.input_calendar::-webkit-calendar-picker-indicator {
  cursor: pointer;
}

.input_calendar::-moz-calendar-picker-indicator {
  cursor: pointer;
}

.input_calendar:focus {
  outline: none;
  outline-offset: 2px;
}

.wrap_prev {
  display: flex;
  align-items: center;
  gap: 6px;
}

.wrap_prev span {
  display: block;
  max-width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.wrap_prev button {
  padding: 0 6px;
}

.input_select {
  width: 100%;
  padding: 8px 16px 8px 8px;
  color: #fff;
  border-radius: 5px;
  background-color: #06061c;
  border: none;

  appearance: none;
  background-image: url('../../../../../../img/arrow_down2.svg');
  background-repeat: no-repeat;
  background-position: right 10px top 50%;
  background-size: 18px 10px;

  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.input_select:focus {
  outline: none;
}

.input_select option {
  background-color: #06061c;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}

.input_select2 {
  width: 100%;
  color: #9b9ba4;

  appearance: none;
  background-image: url('../../../../../../img/arrow_down2.svg');
  background-repeat: no-repeat;
  background-position: right 10px top 50%;
  background-size: 18px 10px;
  background-color: #06061c;

  display: inline-block;
  width: 100%;
  border: 1px solid #ffffff33;
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 12px;
  padding-right: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-top: 10px;
  margin-bottom: 10px;
  opacity: 1;

  font-weight: 500;
  font-size: 14px;
  height: 40px;
  line-height: 17px;

  transition: transform 0.4s ease-in-out, height 0.4s ease-in-out, margin-top 0.4s ease-in-out,
    margin-bottom 0.4s ease-in-out, padding-top 0.4s ease-in-out, padding-bottom 0.4s ease-in-out,
    opacity 0.4s ease-in-out;
}

.input_select2 option {
  background-color: #06061c;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}

.input_select2_hidden {
  width: 100%;
  color: #9b9ba4;

  appearance: none;
  background-image: url('../../../../../../img/arrow_down2.svg');
  background-repeat: no-repeat;
  background-position: right 10px top 50%;
  background-size: 18px 10px;
  background-color: #06061c;

  display: inline-block;
  width: 100%;
  border: 1px solid #ffffff33;
  border-radius: 5px;

  padding-top: 1px;
  padding-bottom: 1px;
  padding-left: 12px;
  padding-right: 12px;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  font-weight: 500;
  font-size: 14px;
  height: 40px;
  line-height: 17px;

  height: 0;
  transform: scaleY(0);
  margin-top: 0;
  margin-bottom: 0;
  opacity: 0;

  transition: transform 0.4s ease-in-out, height 0.4s ease-in-out, margin-top 0.4s ease-in-out,
    margin-bottom 0.4s ease-in-out, padding-top 0.4s ease-in-out, padding-bottom 0.4s ease-in-out,
    opacity 0.4s ease-in-out;
}

.edit_title {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #ffffff;
  margin-bottom: 30px;
}

.edit_input {
  width: 100%;
  max-width: 620px;
  border-bottom: 1px solid#ffffff33;
  transition: border-color 0.4s ease-in-out;
}

.edit_input:hover {
  border-bottom: 1px solid#ffffffc4;
}

.edit_field_wrap {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
  padding: 0;
}

.edit_field_title {
  width: 200px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
  opacity: 0.6;
  cursor: default;
}

.edit_field_text {
  width: 100%;
  max-width: 160px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  cursor: default;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  min-height: 17px;
  height: fit-content;
  opacity: 1;
  transition: opacity 0.4s ease-in-out;
}

/* .edit_field_text {
  width: 260px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  cursor: default;

  min-height: 17px;
  height: fit-content;
  opacity: 1;
  overflow: hidden;
  transition: opacity 0.4s ease-in-out;
} */

.edit_field_text_hidden {
  width: 100%;
  max-width: 160px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  cursor: default;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  max-height: 34px;
  opacity: 0;
  overflow: hidden;
  transition: opacity 0.4s ease-in-out;
}

.edit_field_btn {
  width: 70px;
  opacity: 0;
  height: 18px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #864390;
  text-align: right;
  cursor: pointer;
  transform: scaleY(1);
  transition: opacity 0.4s ease-in-out, scaleY 0.4s ease-in-out;
}

.red_text {
  color: #6c6f70;
  text-decoration: line-through;
}

.edit_field_btn_hide {
  width: 70px;
  opacity: 0;
  height: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #864390;
  text-align: right;
  cursor: pointer;
  transform: scaleY(0);
  transition: opacity 0.4s ease-in-out, scaleY 0.4s ease-in-out;
}

.edit_input:hover > .edit_field_wrap > .wrap_p > .edit_field_btn {
  height: 12px;
  transform: scaleY(1);
  opacity: 1;
}

/* .wrap_save_field_btn {
  display: flex;
  align-items: center;
  gap: 12px;
} */

.save_field_btn {
  margin-bottom: 12px;
  width: 70px;
  height: 6px;
  opacity: 1;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #864390;
  text-align: right;
  cursor: pointer;
  transition: opacity 0.4s ease-in-out, caleY 0.4s ease-in-out, height 0.4s ease-in-out,
    color 0.4s ease-in-out;
}

/* .save_field_btn:hover {
  color: #fbff00;
} */

.save_field_btn_hide {
  width: 70px;
  height: 0;
  opacity: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #864390;
  text-align: right;
  cursor: pointer;
  transform: scaleY(0);
  transition: opacity 0.4s ease-in-out, caleY 0.4s ease-in-out, height 0.4s ease-in-out;
}

.edit_entry {
  display: inline-block;
  width: 100%;
  background-color: transparent;
  border: 1px solid #ffffff33;
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 12px;
  padding-right: 12px;
  color: #fff;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 500;
  font-size: 14px;
  height: 40px;
  line-height: 17px;
  margin-top: 10px;
  margin-bottom: 10px;
  opacity: 1;
  transition: transform 0.4s ease-in-out, height 0.4s ease-in-out, margin-top 0.4s ease-in-out,
    margin-bottom 0.4s ease-in-out, padding-top 0.4s ease-in-out, padding-bottom 0.4s ease-in-out,
    opacity 0.4s ease-in-out;
}

.edit_entry_hidden {
  display: inline-block;
  width: 100%;
  background-color: transparent;
  border: 1px solid #ffffff33;
  border-radius: 5px;
  padding-top: 1px;
  padding-bottom: 1px;
  padding-left: 12px;
  padding-right: 12px;
  color: #fff;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  height: 0;
  transform: scaleY(0);
  margin-top: 0;
  margin-bottom: 0;
  opacity: 0;
  transition: transform 0.4s ease-in-out, height 0.4s ease-in-out, margin-top 0.4s ease-in-out,
    margin-bottom 0.4s ease-in-out, padding-top 0.4s ease-in-out, padding-bottom 0.4s ease-in-out,
    opacity 0.4s ease-in-out;
}

.edit_entry:focus {
  outline-color: #ffffff17;
}

.edit_entry_hidden:focus {
  outline-color: #ffffff17;
}

.fieldset_title {
  width: 100%;
  border: 1px solid #ffffff33;
  border-radius: 30px;
  padding: 10px 20px;
}

.fieldset_news {
  width: 100%;
  height: 140px;
  border: 1px solid #ffffff33;
  border-radius: 30px;
  padding: 10px 20px;
}

.fieldset_date {
  width: 100%;
  max-width: 330px;
  border: 1px solid #ffffff33;
  border-radius: 30px;
  padding: 18px 30px;
}

.input_title {
  min-width: 100%;
  background-color: transparent;
  padding: 8px 10px;
  border: none;
  color: #fff;
}

.input_title:focus {
  outline: none;
}

.input_title::placeholder {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  opacity: 0.6;
}

.input_news {
  min-width: 100%;
  height: 100%;
  background-color: transparent;
  padding: 8px 10px;
  border: none;
  color: #ffffff90;
  resize: none;

  scrollbar-color: #ffffff1f #ffffff07;
  scrollbar-width: thin;
}

.input_news::-webkit-scrollbar {
  width: 6px;
}

.input_news::-webkit-scrollbar-track {
  background: #ffffff07;
}

.input_news::-webkit-scrollbar-thumb {
  background-color: #ffffff1f;
  /* цвет плашки */
  border-radius: 20px;
  border: 1px solid #141425;
  /* padding вокруг плашки */
}

.input_news:focus {
  outline: none;
}

.input_news::placeholder {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  opacity: 0.6;
}

.input_date {
  min-width: 100%;
  background-color: transparent;
  padding: 0;
  border: none;
  color: #ffffff90;
}

.input_date::-webkit-calendar-picker-indicator {
  filter: invert(0.6);
}

.input_date:focus {
  outline: none;
}

.input_date::placeholder {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  opacity: 0.6;
}

.input_date::before {
  content: attr(data-placeholder);
  width: 100%;
  height: 18px;
  background-color: #522381;
}
.input_date:focus::before,
.input_date:valid::before {
  display: none;
}

.btns_tech_spec {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  flex-wrap: wrap;
}

.button_tech_spec {
  display: flex;
  align-items: center;
  gap: 12px;
  width: max-content;
  background-color: #52238131;
  border-radius: 50px;
  padding: 18px 36px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #ffffff;
  transition: background-color 0.4s ease-in-out;
}

.button_tech_spec:hover {
  background-color: #20124d;
}

.btn_submit {
  align-self: end;
  width: max-content;
  background-color: #522381;
  border-radius: 50px;
  padding: 20px 60px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #ffffff;
  transition: background-color 0.4s ease-in-out;
}

.btn_submit:hover {
  background-color: #1f0b32;
}

.edit_det_wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 13px;
  margin-bottom: 20px;
}

.edit_det_title {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}

.edit_title_wrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.input_wrap2 {
  width: 180px;
  border: 1px solid #ffffff33;
  border-radius: 30px;
  /* padding: 10px 20px; */
}

.input_select3 {
  width: 100%;
  padding: 12px 18px;
  color: #9b9ba4;
  border-radius: 30px;
  background-color: #06061c;
  border: none;

  appearance: none;
  background-image: url('../../../../../../img/arrow_down2.svg');
  background-repeat: no-repeat;
  background-position: right 16px top 50%;
  background-size: 18px 10px;

  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}

.input_select3 option {
  background-color: #06061c;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  color: #fff;
}

.titles_table_list {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 25px;
  background-color: #1c1c2c;

  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #9b9ba4;
}

.payment_list {
  width: 100%;
  padding: 15px 25px 0;
}

.payment_item_list {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}

.payment_list_elem {
  width: 90px;
  cursor: default;
  color: #9b9ba4;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  padding: 25px 0;
}

.payment_list_elem:last-child {
  width: max-content;
}

.item_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin: auto;
  opacity: 0.2;
  transition: opacity 0.4s ease-in-out;
}

.item_icon:hover {
  opacity: 0.6;
}

.pagination {
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.pagination_btn_prev {
  width: 44px;
  height: 44px;
  background-image: url('../../../../../../img/btn_right.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 10px 16px;
  transform: rotate(180deg);
  opacity: 0.2;
  transition: opacity 0.3s ease-in-out;
}

.pagination_btn_prev:hover {
  opacity: 1;
}

.pagination_btn_next {
  width: 44px;
  height: 44px;
  background-image: url('../../../../../../img/btn_right.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 10px 16px;
  opacity: 0.2;
}

.pagination_btn_next:hover {
  opacity: 1;
}

.pagination_list {
  display: flex;
  align-items: center;
  gap: 4px;
}

.pagination_item {
  width: 44px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  padding: 10px 0;
  border-radius: 50%;
  background-color: transparent;
  opacity: 0.2;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.pagination_item:hover {
  background-color: #06061c;
  opacity: 1;
}

.pagination_item_active {
  width: 44px;
  height: 44px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  padding: 10px 0;
  border-radius: 50%;
  background-color: transparent;
  color: #fff;
  cursor: pointer;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.wrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

@media (max-width: 820px) {
  .btn_close {
    top: 3px;
    right: 3px;
  }

  .btn_close_back {
    top: 3px;
    right: 3px;
  }
}

@media (max-width: 700px) {
  .clientsedit_wrap {
    width: 100%;
  }
  .clientsedit {
    width: 100%;
  }
}
