.clientsadd_wrap {
  display: flex;
  justify-content: center;
  position: relative;
}

.clientsadd {
  position: relative;
  width: 600px;
  max-height: calc(100vh - 120px);
  background: #06061c;
  border-radius: 20px;
  padding: 42px;
  overflow-y: auto;

  scrollbar-color: #ffffff1f #ffffff07;
  scrollbar-width: thin;
  z-index: -1px;
}

.clientsadd::-webkit-scrollbar {
  width: 6px;
}

.clientsadd::-webkit-scrollbar-track {
  background: #ffffff07;
}

.clientsadd::-webkit-scrollbar-thumb {
  background-color: #ffffff1f;
  /* цвет плашки */
  border-radius: 20px;
  border: 1px solid #141425;
  /* padding вокруг плашки */
}

.btn_close {
  position: absolute;
  top: 0;
  right: -55px;
  opacity: 0.2;
  transition: opacity 0.4s ease-in-out;
  z-index: 20;
}

.btn_close:hover {
  opacity: 1;
}

.title_wrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #ffffff;
}

.title_img1 {
  width: 40px;
  height: 30px;
  background-image: url('../../../../../../img/3_1.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 40px 30px;
}

.title_img2 {
  width: 40px;
  height: 30px;
  background-image: url('../../../../../../img/3_2.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 40px 30px;
}

.title_img3 {
  width: 40px;
  height: 30px;
  background-image: url('../../../../../../img/3_3.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 40px 30px;
}

.form {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
}

.fieldset1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  border: none;
  padding: 0;
}

.fieldset2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  border: none;
  padding: 0;
}

.fieldset3 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  border: none;
  padding: 0;
}

.hidden {
  position: absolute;
  top: -100%;
  left: 0;
  height: 0;
  overflow: hidden;
}

.label_image {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.label_image p {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  opacity: 0.6;
}

.input_image {
  position: absolute;
  z-index: -1;
  opacity: 0;
  display: block;
  width: 0;
  height: 0;
}

.image_preview {
  position: relative;
  width: 170px;
  max-height: 170px;
  border: 1px solid #ffffff33;
  border-radius: 20px;
  padding: 15px 13px;
}

.image_preview_show {
  display: block;
}

.image_preview_show_p {
  position: absolute;
  top: -22px;
  left: 10px;

  font-family: 'NotoSans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  opacity: 0.6;
}

.image_preview_hide {
  display: none;
}

.btn_delete {
  position: absolute;
  top: -14px;
  right: -14px;
  width: 32px;
  height: 32px;
  background-image: url('../../../../../../img/btn_close.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

/* ====================================== */
.fias_address_group {
  width: 100%;
}

.wrap_title {
  position: relative;
  width: 100%;
}

.mb30 {
  margin-bottom: 30px;
}

.wrap_title_h6 {
  margin-bottom: 35px;
  font-family: 'NotoSans';
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  opacity: 0.6;
}

.address_wrap {
  position: relative;
  width: 100%;
  margin-bottom: 10px;
}

.address {
  width: 100%;
  height: 60px;
  margin-bottom: 30px;
  overflow: hidden;
  white-space: nowrap;
  border-radius: 30px;
  border: 1px solid #ffffff33;
  background-color: #0d0d1e;
  cursor: default;
  transition: border 0.4s ease-in-out, height 0.4s ease-in-out;
}

.address:hover {
  border: 1px solid #ffffffd3;
}

.list_city {
  width: 100%;
  height: 100%;
  overflow: auto;
  margin-top: 6px;
  border-radius: 5px;
  font: 500 14px/18px Montserrat;
  cursor: pointer;
  font: 600 16px/20px Montserrat;
  transition: border-color 0.4s ease-in-out;
  padding-bottom: 80px;
  background-color: #0d0d1e;
}

.item_city {
  padding: 8px 30px;
  color: #ffffff8f;
  background-color: #0d0d1e;
  transition: color 0.2s ease-in-out;
}

.item_city:hover {
  color: #fff;
}

.item_city:first-child {
  color: #ffffff4f;
  border-top: 1px solid #ffffff46;
  border-bottom: 1px solid #ffffff46;
}

.sorting_item:hover {
  color: #6f3482;
  background-color: #ffffff17;
}

.address_wrapper {
  box-sizing: border-box;
  width: 100%;
  padding: 20px 30px 13px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #ffffff8f;
}

.address_p {
  color: #ffffff8f;
  font: 600 16px/20px Montserrat;
}

.address_svg {
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.address_svg_rotage {
  transform: rotate(180deg);
}
/* ===================================== */

.wrap_title_p {
  position: absolute;
  top: -22px;
  left: 10px;

  font-family: 'NotoSans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  opacity: 0.6;
  z-index: 999;
}

.wrap_title_p_hide {
  display: none;
}

.input_wrap {
  width: 100%;
  border: 1px solid #ffffff33;
  border-radius: 30px;
  padding: 10px 20px;
}

.fieldset_news {
  width: 100%;
  height: 140px;
  border: 1px solid #ffffff33;
  border-radius: 30px;
  padding: 10px 20px;
}

.fieldset_date {
  width: 100%;
  max-width: 330px;
  border: 1px solid #ffffff33;
  border-radius: 30px;
  padding: 18px 30px;
}

.input_title {
  min-width: 100%;
  background-color: transparent;
  padding: 8px 10px;
  border: none;
  color: #fff;
  caret-color: #9b9ba4;
}

.input_title:focus {
  outline: none;
}

.input_title::placeholder {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  opacity: 0.6;
}

.input_news {
  min-width: 100%;
  background-color: transparent;
  padding: 8px 10px;
  border: none;
  color: #ffffff90;
  resize: none;
}

.input_news:focus {
  outline: none;
}

.input_news::placeholder {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  opacity: 0.6;
}

.input_date {
  min-width: 100%;
  background-color: transparent;
  padding: 0;
  border: none;
  color: #ffffff90;
}

.input_date::-webkit-calendar-picker-indicator {
  filter: invert(0.6);
}

.input_date:focus {
  outline: none;
}

.input_date::placeholder {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  opacity: 0.6;
}

.input_date::before {
  content: attr(data-placeholder);
  width: 100%;
  height: 18px;
  background-color: #522381;
}
.input_date:focus::before,
.input_date:valid::before {
  display: none;
}

.btn_change_wrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.btn_change {
  align-self: end;
  width: max-content;
  background-color: #522381;
  border-radius: 50px;
  padding: 20px 70px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #ffffff;
  transition: background-color 0.4s ease-in-out;
}

.btn_change:hover {
  background-color: #1f0b32;
}

.btn_change:disabled {
  background-color: #3d3d4b;
  color: #9b9ba4;
  cursor: default;
}

.btn_back {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 1px solid #fff;
  background-image: url('../../../../../../img/arrow_left.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 22px 19px;
  opacity: 0.2;
  transition: opacity 0.4s ease-in-out;
}

.btn_back:hover {
  opacity: 1;
}

.note_wrap {
  width: 100%;
}

.note_pass {
  padding: 0 5px 4px 5px;
  height: 65px;
  overflow: hidden;
  opacity: 1;

  color: #e60b0b;
  font-family: NotoSans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  transition: height 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

.note_empty {
  padding: 0 5px 4px 5px;
  height: 0;
  overflow: hidden;
  opacity: 0;

  color: #e60b0b;
  font-family: NotoSans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  transition: height 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

.note_hide {
  padding: 0 5px 4px 5px;
  height: 0;
  overflow: hidden;
  opacity: 0;

  color: #e60b0b;
  font-family: NotoSans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  transition: height 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

.note_mail {
  padding: 0 5px 4px 5px;
  height: 22px;
  overflow: hidden;
  opacity: 1;

  color: #e60b0b;
  font-family: NotoSans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  transition: height 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

.input_wrap_pass {
  position: relative;
}

.input {
  width: 100%;
  border-radius: 30px;
  padding: 18px 30px;
  background-color: transparent;

  border: none;
  outline: 1px solid #4f5152;
  outline-offset: -1px;
  caret-color: #9b9ba4;

  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.input:focus {
  outline: 1px solid #656768;
  outline-offset: -1px;
}

.input::placeholder {
  color: #9b9ba4;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.input_search_wrap {
  border-radius: 30px;
  border: none;
  height: 60px;
  max-height: 375px;
  overflow: hidden;
  outline: 1px solid #4f5152;
  outline-offset: -1px;
  transition: outline 0.4s ease-in-out height 0.4s ease-in-out;
}

.input_search_wrap:focus-within {
  outline: 1px solid #9b9ba4;
  height: 100%;
}

.input_search_wrap:hover {
  outline: 1px solid #9b9ba4;
}

.input_search {
  width: 100%;
  border-radius: 30px;
  padding: 18px 30px;
  background-color: transparent;

  border: none;
  caret-color: #9b9ba4;

  color: #ffffff8e;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.input_search:focus {
  border: none;
  outline: none;
}

.input_search::placeholder {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #fff;
}

.list_search {
  width: 100%;
  margin-top: 6px;
  border-radius: 5px;
  font: 500 14px/18px Montserrat;
  cursor: pointer;

  max-height: 295px;
  overflow: auto;

  font: 600 16px/20px Montserrat;
  transition: border-color 0.4s ease-in-out;
  padding-bottom: 40px;
  background-color: #0d0d1e;
}

.item_search {
  padding: 8px 30px;
  color: #ffffff8f;
  background-color: #0d0d1e;
  transition: color 0.2s ease-in-out;
}

.item_search:hover {
  color: #fff;
}

.input_reset {
  cursor: pointer;
  padding: 8px 30px;
  color: #ffffff4f;
  border-top: 1px solid #ffffff46;
  border-bottom: 1px solid #ffffff46;
  background-color: #0d0d1e;
  transition: color 0.2s ease-in-out;
}

.input_reset:hover {
  color: #d38383;
}

/* .item_search:nth-child(2) {
  margin-top: 10px;
} */

.input_btn_closed {
  position: absolute;
  top: 15px;
  right: 20px;
  width: 32px;
  height: 32px;
  background-image: url('../../../../../../img/btn_password.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  opacity: 0.2;
}

.input_btn_closed:hover {
  opacity: 1;
}

.input_btn_open {
  position: absolute;
  top: 15px;
  right: 20px;
  width: 32px;
  height: 32px;
  background-image: url('../../../../../../img/btn_password2.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  opacity: 0.2;
}

.input_btn_open:hover {
  opacity: 1;
}

.note_wrap {
  width: 100%;
  /* margin-bottom: 10px; */
}

.note_empty {
  padding: 0 5px 4px 5px;
  height: 0;
  overflow: hidden;
  opacity: 0;

  color: #e60b0b;
  font-family: NotoSans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  transition: height 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

.note_hide {
  padding: 0 5px 4px 5px;
  height: 0;
  overflow: hidden;
  opacity: 0;

  color: #e60b0b;
  font-family: NotoSans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  transition: height 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

.note_mail {
  padding: 0 5px 4px 5px;
  height: 22px;
  overflow: hidden;
  opacity: 1;

  color: #e60b0b;
  font-family: NotoSans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  transition: height 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

.label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  width: 100%;
  border-radius: 30px;
  border: 1px solid #3d3d4b;
  padding: 10px 30px;
  background-color: transparent;
  /* margin-bottom: 40px; */
  transition: border-color 0.4s ease-in-out;
}

.input_phone {
  width: 100%;
  border: none;
  background-color: transparent;

  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.input_phone:focus {
  outline: none;
}

.input_phone:focus-within {
  outline: none;
  border-color: #fff;
}

.input_phone::placeholder {
  color: #9b9ba4;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.select {
  width: max-content;
  color: #fff;
  border-radius: 4px;
  background-color: transparent;
  border: none;

  appearance: none;

  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.select:focus {
  outline: none;
  border: none;
}

@media (max-width: 760px) {
  .btn_close {
    top: 5px;
    right: 5px;
  }
}

@media (max-width: 620px) {
  .clientsadd_wrap {
    width: 100%;
  }
  .clientsadd {
    width: 100%;
  }
}
