.overlay_show {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #0d0d1e80;
  backdrop-filter: blur(15px);
  z-index: 101;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: transform 0.2s ease-in-out, opacity 0.6s ease-in-out;
}

.overlay_hide {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #0d0d1e80;
  backdrop-filter: blur(15px);
  z-index: 101;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transform: scale(0);
  transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

.deptclbase {
  width: 100%;
  max-width: 1278px;
  padding-bottom: 80px;
}

.title_and_btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
}

.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 25px;
  padding-bottom: 5px;
  color: var(--color);
}

.btn_group {
  display: flex;
  align-items: center;
  gap: 12px;
}

.btn_add {
  border-radius: 30px;
  border: 1px solid #3d3d4b;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  transition: border-color 0.4s ease-in-out;

  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}

.btn_add:hover {
  border-color: #fff;
}

.btn_add:focus {
  border-color: #fff;
}

.btn_filter {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 12px 20px;
  outline: 1px solid #ffffff36;
  outline-offset: -1px;
  border-radius: 30px;

  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;

  object-fit: contain;
  transition: outline-color 0.4s ease-in-out, background-color 0.4s ease-in-out,
    color 0.4s ease-in-out;
}

.btn_filter:hover {
  outline-color: #ffffff;
}

.btn_filter:focus {
  color: #0d0d1e;
  background-color: #ffffff;
}

.table_header {
  width: 100%;
  display: grid;
  grid-template-columns:
    minmax(55px, 55px)
    minmax(100px, 100px)
    minmax(100px, 100px)
    minmax(90px, 100px)
    minmax(80px, 90px)
    minmax(70px, 80px)
    minmax(100px, 110px)
    minmax(180px, 210px)
    minmax(60px, 90px)
    minmax(70px, 130px);
  align-items: center;
  justify-content: space-between;
  padding: 12px 12px 12px 12px;
  background: #d9d9d909;
  border-radius: 5px;
}

.header_item {
  position: relative;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #9b9ba4;
  padding: 2px 4px;
  cursor: default;
}

.header_item span {
  position: absolute;
  background-color: #06061c;
  transform: scale(0);
  padding: 2px 4px;
  border-radius: 4px;
  color: #bbbaba;
  top: 0;
  left: 0;
  transition: transform 0.2s ease-in-out;
  box-shadow: 4px 2px 4px #000;
  z-index: 2;
}

.header_item:hover span {
  transform: scale(1);
}

.filter_list {
  width: 100%;
  height: 85px;
  overflow: hidden;
  display: grid;
  grid-template-columns:
    minmax(55px, 55px)
    minmax(90px, 100px)
    minmax(80px, 90px)
    minmax(90px, 90px)
    minmax(60px, 90px)
    minmax(70px, 80px)
    minmax(100px, 110px)
    minmax(180px, 210px)
    minmax(60px, 90px)
    minmax(70px, 130px);
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding-top: 18px;
  padding-bottom: 25px;
  padding-left: 12px;
  padding-right: 12px;
  margin-bottom: 25px;
  background-color: #06061c;
  border-radius: 5px;
  transition: height 0.4s ease-in-out, padding-top 0.4s ease-in-out, padding-bottom 0.4s ease-in-out;
}

.filter_list_hidden {
  width: 100%;
  height: 0;
  overflow: hidden;
  display: grid;
  grid-template-columns:
    minmax(55px, 55px)
    minmax(90px, 100px)
    minmax(80px, 90px)
    minmax(90px, 90px)
    minmax(60px, 90px)
    minmax(70px, 80px)
    minmax(100px, 110px)
    minmax(180px, 210px)
    minmax(60px, 90px)
    minmax(70px, 130px);
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding-left: 12px;
  padding-right: 12px;
  margin-bottom: 25px;
  background-color: #06061c;
  border-radius: 5px;
  transition: height 0.4s ease-in-out, padding-top 0.4s ease-in-out, padding-bottom 0.4s ease-in-out;
}

.filter_list_item {
  position: relative;
}

.filter_list_select {
  padding-right: 8px;
  border-radius: 5px;
  background-color: #06061c;
  border: 1px solid #ffffff34;
}

.filter_select {
  width: 100%;
  padding: 8px 16px 8px 6px;
  color: #fff;
  border-radius: 5px;
  background-color: #06061c;
  border: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
}

.filter_select:disabled {
  color: #616060;
}

.filter_list_select:focus-within {
  outline: 1px solid #ffffff94;
  outline-offset: -2px;
}

.filter_select option {
  color: #fff;
  background-color: #1c1c2c;
}

.wrap_filter_item {
  position: relative;
}

.placeholder_above {
  position: absolute;
  top: -22px;
  left: 0;
  color: #9b9ba4;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.filter_input {
  width: 100%;
  padding: 8px 16px 8px 8px;
  color: #fff;
  border-radius: 5px;
  background-color: #06061c;
  border: 1px solid #3d3d4b;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
}

.filter_input:focus {
  outline-color: #ffffff94;
  outline-offset: 0;
}

.filter_input::placeholder {
  color: transparent;
}

.clear_input {
  display: none;
}

.filter_input:focus + .clear_input {
  position: absolute;
  top: 7px;
  right: 5px;
  cursor: default;
  display: block;
}

.statement_list_open {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.statement_list_closed {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.pagination {
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.pagination_btn_prev {
  width: 44px;
  height: 44px;
  background-image: url('../../../../img/btn_right.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 10px 16px;
  transform: rotate(180deg);
  opacity: 0.2;
  transition: opacity 0.3s ease-in-out;
}

.pagination_btn_prev:hover {
  opacity: 1;
}

.pagination_btn_next {
  width: 44px;
  height: 44px;
  background-image: url('../../../../img/btn_right.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 10px 16px;
  opacity: 0.2;
  transition: opacity 0.3s ease-in-out;
}

.pagination_btn_next:hover {
  opacity: 1;
}

.pagination_list {
  display: flex;
  align-items: center;
  gap: 4px;
}

.pagination_item {
  width: 44px;
  height: 44px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  padding: 10px 0;
  border-radius: 50%;
  background-color: transparent;
  color: #9b9ba4;
  cursor: pointer;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.pagination_item:hover {
  background-color: #06061c;
  color: #fff;
}

.pagination_item_active {
  width: 44px;
  height: 44px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  padding: 10px 0;
  border-radius: 50%;
  background-color: transparent;
  color: #fff;
  cursor: pointer;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.title_group {
  display: flex;
  gap: 30px;
}

.title_btn_enab {
  font-weight: 700;
  font-size: 24px;
  line-height: 25px;
  padding-bottom: 5px;
  color: #3d3d4b;
  border-bottom: 2px solid transparent;
  transition: color 0.2s ease-in-out, border-bottom 0.4s ease-in-out;
}

.title_btn_enab:hover {
  color: #9b9ba4;
  border-bottom: 2px solid #9b9ba4;
}

.title_btn_disab {
  font-weight: 700;
  font-size: 24px;
  line-height: 25px;
  padding-bottom: 5px;
  color: var(--color);
  border-bottom: 2px solid var(--color);
  pointer-events: none;
  transition: color 0.2s ease-in-out, border-bottom 0.4s ease-in-out;
}

.hidden {
  display: none;
}

.clear_filters {
  display: none;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  color: #9b9ba4;
  transition: color 0.4s ease-in-out;

  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}

.clear_filters:hover {
  color: #fff;
}

.show_result {
  display: none;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  padding: 20px 40px;
  border-radius: 30px;
  background-color: #522381;
  transition: background-color 0.4s ease-in-out;

  color: #fff;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.28px;
  text-transform: uppercase;
}

.show_result:hover {
  background-color: #1f0b32;
}

.lifdown {
  width: 100%;
  max-width: 1278px;
  padding-bottom: 80px;
}

@media (max-width: 1220px) {
  .table_header {
    display: none;
  }

  .filter_list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    height: 220px;
  }

  .filter_list_hidden {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .filter_input::placeholder {
    color: #9b9ba4;
  }
}

@media (max-width: 1093px) {
  .filter_list {
    height: 260px;
  }
}

@media (max-width: 950px) {
  .title_and_btn {
    margin-bottom: 5px;
    flex-direction: column;
    align-items: flex-start;
    gap: 35px;
  }

  .title_btn_enab {
    font-size: 18px;
  }

  .title_btn_disab {
    font-size: 18px;
  }

  .btn_add {
    font-size: 12px;
    width: 245px;
  }
  .btn_filter {
    font-size: 12px;
    width: 245px;
  }
}

@media (max-width: 900px) {
  .filter_list {
    height: 330px;
  }
}

@media (max-width: 540px) {
  .btn_group {
    flex-direction: column;
    width: 100%;
  }

  .btn_filter {
    width: 100%;
    justify-content: center;
  }

  .btn_add {
    width: 100%;
  }

  .filter_list {
    flex-direction: column;
    flex-wrap: nowrap;
    height: 820px;
    gap: 24px;
  }

  .wrap_filter_item {
    width: 100%;
  }

  .filter_input {
    border-radius: 20px;
    padding: 10px 16px 10px 16px;
  }

  .filter_list_select {
    border-radius: 20px;
    padding: 4px 16px 4px 8px;
  }

  .filter_input:focus + .clear_input {
    right: 20px;
  }

  .clear_filters {
    display: flex;
  }

  .show_result {
    display: flex;
  }
}
