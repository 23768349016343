.docsedit_wrap {
  display: flex;
  justify-content: center;
  position: relative;
}

.docsedit {
  position: relative;
  width: 600px;
  max-height: 100vh;
  background: #06061c;
  border-radius: 20px;
  padding: 42px;
  overflow-y: auto;

  scrollbar-color: #ffffff1f #ffffff07;
  scrollbar-width: thin;
}

.docsedit::-webkit-scrollbar {
  width: 6px;
}

.docsedit::-webkit-scrollbar-track {
  background: #ffffff07;
}

.docsedit::-webkit-scrollbar-thumb {
  background-color: #ffffff1f;
  /* цвет плашки */
  border-radius: 20px;
  border: 1px solid #141425;
  /* padding вокруг плашки */
}

.btn_close {
  position: absolute;
  top: 0;
  right: -58px;
  opacity: 0.2;
  transition: opacity 0.4s ease-in-out;
  z-index: 2;
}

.btn_close:hover {
  opacity: 1;
}

.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #ffffff;
  margin-bottom: 40px;
}

.form {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
}

.fieldset_show {
  width: 100%;
  max-width: 330px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ffffff33;
  border-radius: 30px;
  padding: 18px 30px;
}

.fieldset_hide {
  display: none;
}

.label_image {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.label_image p {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  opacity: 0.6;
}

.input_image {
  position: absolute;
  z-index: -1;
  opacity: 0;
  display: block;
  width: 0;
  height: 0;
}

.doc_preview_show {
  display: block;
}

.doc_preview_show_p {
  position: absolute;
  top: -22px;
  left: 10px;

  font-family: 'NotoSans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  opacity: 0.6;
}

.doc_preview_hide {
  display: none;
}

.doc_preview {
  position: relative;
  width: 330px;
  max-height: 170px;
  border: 1px solid #ffffff33;
  border-radius: 30px;
  padding: 13px 13px;

  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.pdf_preview {
  overflow: hidden;
  text-overflow: ellipsis;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;

  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  padding: 0 13px;
}

.btn_delete {
  position: absolute;
  top: -14px;
  right: -14px;
  width: 32px;
  height: 32px;
  background-image: url('../../../../../../img/btn_close.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.wrap_title {
  position: relative;
  width: 100%;
}

.wrap_title_p {
  position: absolute;
  top: -22px;
  left: 10px;

  font-family: 'NotoSans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  opacity: 0.6;
}

.wrap_title_p_hide {
  display: none;
}

.fieldset_title {
  width: 100%;
  border: 1px solid #ffffff33;
  border-radius: 30px;
  padding: 10px 20px;
}

.fieldset_news {
  width: 100%;
  height: 140px;
  border: 1px solid #ffffff33;
  border-radius: 30px;
  padding: 10px 20px;
}

.fieldset_date {
  width: 100%;
  max-width: 330px;
  /* display: flex;
  align-items: center;
  justify-content: space-between; */
  border: 1px solid #ffffff33;
  border-radius: 30px;
  padding: 18px 30px;
}

.input_title {
  min-width: 100%;
  background-color: transparent;
  padding: 8px 10px;
  border: none;
  color: #fff;
}

.input_title:focus {
  outline: none;
}

.input_title::placeholder {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  opacity: 0.6;
}

.input_news {
  min-width: 100%;
  height: 100%;
  background-color: transparent;
  padding: 8px 10px;
  border: none;
  color: #ffffff90;
  resize: none;

  scrollbar-color: #ffffff1f #ffffff07;
  scrollbar-width: thin;
}

.input_news::-webkit-scrollbar {
  width: 6px;
}

.input_news::-webkit-scrollbar-track {
  background: #ffffff07;
}

.input_news::-webkit-scrollbar-thumb {
  background-color: #ffffff1f;
  /* цвет плашки */
  border-radius: 20px;
  border: 1px solid #141425;
  /* padding вокруг плашки */
}

.input_news:focus {
  outline: none;
}

.input_news::placeholder {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  opacity: 0.6;
}

.input_date {
  min-width: 100%;
  background-color: transparent;
  padding: 0;
  border: none;
  color: #ffffff90;
}

.input_date::-webkit-calendar-picker-indicator {
  filter: invert(0.6);
}

.input_date:focus {
  outline: none;
}

.input_date::placeholder {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  opacity: 0.6;
}

.input_date::before {
  content: attr(data-placeholder);
  width: 100%;
  height: 18px;
  background-color: #522381;
}
.input_date:focus::before,
.input_date:valid::before {
  display: none;
}

.btn_submit {
  align-self: end;
  width: max-content;
  background-color: #522381;
  border-radius: 50px;
  padding: 20px 70px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #ffffff;
  transition: background-color 0.4s ease-in-out;
}

.btn_submit:hover {
  background-color: #1f0b32;
}

@media (max-width: 760px) {
  .btn_close {
    top: 10px;
    right: 10px;
  }
}

@media (max-width: 620px) {
  .docsedit_wrap {
    width: 100%;
  }
  .docsedit {
    width: 100%;
  }
}