.clientsadd_wrap {
  display: flex;
  justify-content: center;
  position: relative;
}

.clientsadd {
  position: relative;
  width: 700px;
  max-height: calc(100vh - 120px);
  background: #06061c;
  border-radius: 20px;
  padding: 40px;
  overflow-y: auto;

  scrollbar-color: #ffffff1f #ffffff07;
  scrollbar-width: thin;
}

.clientsadd::-webkit-scrollbar {
  width: 6px;
}

.clientsadd::-webkit-scrollbar-track {
  background: #ffffff07;
}

.clientsadd::-webkit-scrollbar-thumb {
  background-color: #ffffff1f;
  /* цвет плашки */
  border-radius: 20px;
  border: 1px solid #141425;
  /* padding вокруг плашки */
}

.btn_close {
  position: absolute;
  top: 0;
  right: -55px;
  opacity: 0.2;
  transition: opacity 0.4s ease-in-out;
  z-index: 20;
}

.btn_close:hover {
  opacity: 1;
}

.title_wrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}

.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #ffffff;
}

.title_img1 {
  width: 40px;
  height: 30px;
  background-image: url('../../../../../../img/4_1.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 40px 30px;
}

.title_img2 {
  width: 40px;
  height: 30px;
  background-image: url('../../../../../../img/4_2.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 40px 30px;
}

.title_img3 {
  width: 40px;
  height: 30px;
  background-image: url('../../../../../../img/4_3.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 40px 30px;
}

.title_img4 {
  width: 40px;
  height: 30px;
  background-image: url('../../../../../../img/4_4.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 40px 30px;
}

.form {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
}

.fieldset1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* gap: 40px; */
  border: none;
  padding: 0;
}

.fieldset2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* gap: 40px; */
  border: none;
  padding: 0;
}

.fieldset3 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* gap: 30px; */
  border: none;
  padding: 0;
}

.fieldset4 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* gap: 40px; */
  border: none;
  padding: 0;
}

.hidden {
  position: absolute;
  top: -100%;
  left: 0;
  height: 0;
  overflow: hidden;
}

.label_image {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.label_image p {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #9b9ba4;
}

.label_image span {
  color: #fff;
}

.input_image {
  width: 100%;
  position: absolute;
  z-index: -1;
  opacity: 0;
  display: block;
  width: 0;
  height: 0;
}

.image_preview {
  position: relative;
  width: 170px;
  max-height: 170px;
  border: 1px solid #ffffff33;
  border-radius: 20px;
  padding: 15px 13px;
}

.image_preview_show {
  display: block;
}

.image_preview_show_p {
  position: absolute;
  top: -22px;
  left: 10px;

  font-family: 'NotoSans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  opacity: 0.6;
}

.image_preview_hide {
  display: none;
}

.btn_delete {
  position: absolute;
  top: -14px;
  right: -14px;
  width: 32px;
  height: 32px;
  background-image: url('../../../../../../img/btn_close.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.wrap_title {
  position: relative;
  width: 100%;
  margin-bottom: 40px;
}

.wrap_title_p {
  position: absolute;
  top: -22px;
  left: 10px;

  font-family: 'NotoSans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #9b9ba4;
}

.wrap_title_p_hide {
  display: none;
}

.input_wrap {
  width: 100%;
  border: 1px solid #ffffff33;
  border-radius: 30px;
  padding: 10px 20px;
}

.input_wrap_2 {
  width: 100%;
  min-width: 220px;
  border: 1px solid #ffffff33;
  border-radius: 30px;
  padding: 10px 20px;
}

.input_select {
  width: 100%;
  padding: 8px 16px 8px 8px;
  color: #fff;
  border-radius: 5px;
  background-color: #06061c;
  border: none;

  appearance: none;
  background-image: url('../../../../../../img/arrow_down2.svg');
  background-repeat: no-repeat;
  background-position: right 10px top 50%;
  background-size: 18px 10px;

  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.input_select:focus {
  outline: none;
}

.input_select option {
  background-color: #06061c;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}

.input_calendar {
  width: 100%;
  padding: 8px;
  border-radius: 6px;
  background-color: #06061c;
  border: none;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #fff;
}

.input_after {
  position: absolute;
  top: 12px;
  right: 26px;
  width: 32px;
  height: 32px;
  pointer-events: none;
  background-image: url(../../../../../../img/icon_calendar.svg);
  background-position: center;
  background-size: 32px 32px;
  background-repeat: no-repeat;
}

.input_calendar:focus {
  outline: none;
  outline-offset: 2px;
}

.placeholder {
  display: block;
  position: absolute;
  top: 1px;
  left: 1px;
  bottom: 1px;
  padding-left: 30px;
  width: 80%;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color: #06061c;

  pointer-events: none;

  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #9b9ba4;
}

.fieldset_news {
  width: 100%;
  height: 140px;
  border: 1px solid #ffffff33;
  border-radius: 30px;
  padding: 10px 20px;
}

.fieldset_date {
  width: 100%;
  max-width: 330px;
  border: 1px solid #ffffff33;
  border-radius: 30px;
  padding: 18px 30px;
}

.input_title {
  width: 100%;
  background-color: transparent;
  padding: 8px 10px;
  border: none;

  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #fff;
}

.input_title:focus {
  outline: none;
}

.input_title::placeholder {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #9b9ba4;
  opacity: 1;
}

.input_news {
  min-width: 100%;
  background-color: transparent;
  padding: 8px 10px;
  border: none;
  color: #ffffff90;
  resize: none;
}

.input_news:focus {
  outline: none;
}

.input_news::placeholder {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.input_date {
  min-width: 100%;
  background-color: transparent;
  padding: 0;
  border: none;
  color: #ffffff90;
}

.input_date::-webkit-calendar-picker-indicator {
  filter: invert(0.6);
}

.input_date:focus {
  outline: none;
}

.input_date::placeholder {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  opacity: 0.6;
}

.input_date::before {
  content: attr(data-placeholder);
  width: 100%;
  height: 18px;
  background-color: #522381;
}
.input_date:focus::before,
.input_date:valid::before {
  display: none;
}

.btn_change_wrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.btn_change {
  align-self: end;
  width: max-content;
  background-color: #522381;
  border-radius: 50px;
  padding: 20px 70px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #ffffff;
  transition: background-color 0.4s ease-in-out;
}

.btn_change:hover {
  background-color: #1f0b32;
}

.btn_back {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 1px solid #fff;
  background-image: url('../../../../../../img/arrow_left.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 22px 19px;
  opacity: 0.2;
  transition: opacity 0.4s ease-in-out;
}

.btn_back:hover {
  opacity: 1;
}

.passportdoc {
  width: 100%;
  max-width: 620px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ffffff33;
  border-radius: 30px;
  padding: 18px 30px;
  margin-bottom: 40px;
  /* 
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; */
}

.fieldset_hide {
  display: none;
}

.wrap_prev {
  display: flex;
  align-items: center;
  gap: 6px;
}

.wrap_prev span {
  display: block;
  max-width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.wrap_prev button {
  padding: 0 6px;
}

.wrap_input {
  position: relative;
  width: 100%;
}

.det_title {
  font-size: 20px;
  font-weight: 600;
  color: #9b9ba4;
  margin-bottom: 40px;
}

.wrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

@media (max-width: 820px) {
  .btn_close {
    top: 3px;
    right: 3px;
  }
}

@media (max-width: 700px) {
  .clientsadd_wrap {
    width: 100%;
  }
  .clientsadd {
    width: 100%;
  }
}
