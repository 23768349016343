.section_rusprod {
  width: 100%;
}

.container {
  display: flex;
  align-items: center;
  gap: 70px;
  padding: 0 180px;
  background-color: #190434;
  height: 484px;
  width: calc(100% - 40px);
  border-radius: 20px;
  overflow: hidden;
  margin: 0 auto;
  z-index: 1;
}

.image {
  max-width: 520px;
  width: 100%;
  height: 100%;
  background-image: url('../../../../img/lift_rus2.svg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.text_block {
  max-width: 890px;
  width: 100%;
}

.title {
  color: #fff;
  font-size: 30px;
  font-weight: 500;
  line-height: 46px;
  margin-top: 110px;
  margin-bottom: 30px;
}

.description {
  color: #9b9ba4;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  margin-bottom: 110px;
}

@media (max-width: 1500px) {
  .container {
    padding: 0 100px;
    height: 500px;
  }
}

@media (max-width: 1200px) {
  .container {
    padding: 70px;
    height: auto;
  }

  .image {
    width: 420px;
    height: 400px;
    background-image: url('../../../../img/lift_rus.svg');
    background-size: contain;
  }

  .title {
    margin-top: 0;
  }

  .description {
    margin-bottom: 0;
  }
}

@media (max-width: 960px) {
  .container {
    display: block;
    padding: 40px;
  }
  .image {
    width: 400px;
    height: 400px;
    margin: 0 auto;
    margin-bottom: 40px;
  }
  .title {
    font-size: 24px;
    line-height: 36px;
    word-wrap: break-word;
  }

  .description {
    font-size: 18px;
    line-height: 24px;
  }
}

@media (max-width: 500px) {
  .container {
    padding: 25px;
  }
  .image {
    width: 200px;
    height: 300px;
  }
}
