.link {
  width: 100%;
  max-width: 160px;
}

.clientsedit_wrap {
  width: 100%;
  max-width: 700px;
  position: relative;
}

.btn_close {
  width: 44px;
  height: 44px;
  position: absolute;
  top: 0;
  right: -55px;
  opacity: 0.2;
  transition: opacity 0.4s ease-in-out;
  z-index: 2;

  background-image: url(../../../../../../img/btn_close.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 44px 44px;
}

.btn_close:hover {
  opacity: 1;
}

.clientsedit {
  position: relative;
  width: 100%;
  max-width: 700px;
  /* max-height: calc(100vh - 100px); */
  background: #06061c;
  border-radius: 20px;
  padding: 40px 40px 40px 40px;
  overflow-y: auto;

  scrollbar-color: #ffffff1f #ffffff07;
  scrollbar-width: thin;
}

.clientsedit::-webkit-scrollbar {
  width: 6px;
}

.clientsedit::-webkit-scrollbar-track {
  background: #ffffff07;
}

.clientsedit::-webkit-scrollbar-thumb {
  background-color: #ffffff1f;
  /* цвет плашки */
  border-radius: 20px;
  border: 1px solid #141425;
  /* padding вокруг плашки */
}

.form {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
}

.ft_edit_requisites {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 13px;
  border: none;
  padding: 0;
}

.edit_title {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #ffffff;
  margin-bottom: 30px;
}

.column_names {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 14px 24px;
  background-color: #111126;

  color: #9b9ba4;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.payments {
  width: 100%;
}

.payments_item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 14px 24px;
}

.payments_item p {
  width: 25%;

  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}

.payments_item_pdf {
  width: 32px;
  height: 32px;
  opacity: 0.2;

  background-image: url('../../../../../../img/icon_pdf.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: opacity 0.4s ease-in-out;
}

.payments_item_pdf:hover {
  opacity: 0.6;
}

.payments_item_no_pdf {
  width: 32px;
  height: 32px;
  opacity: 0.2;

  background-image: url('../../../../../../img/icon_no_pdf.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: opacity 0.4s ease-in-out;
}

.payments_item_no_pdf:hover {
  opacity: 0.6;
}

/* --------------------------------------------- */

.pagination {
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.pagination_btn_prev {
  width: 44px;
  height: 44px;
  background-image: url('../../../../../../img/btn_right.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 10px 16px;
  transform: rotate(180deg);
  opacity: 0.2;
  transition: opacity 0.3s ease-in-out;
}

.pagination_btn_prev:hover {
  opacity: 1;
}

.pagination_btn_next {
  width: 44px;
  height: 44px;
  background-image: url('../../../../../../img/btn_right.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 10px 16px;
  opacity: 0.2;
}

.pagination_btn_next:hover {
  opacity: 1;
}

.pagination_list {
  display: flex;
  align-items: center;
  gap: 4px;
}

.pagination_item {
  width: 44px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  padding: 10px 0;
  border-radius: 50%;
  background-color: transparent;
  opacity: 0.2;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.pagination_item:hover {
  background-color: #06061c;
  opacity: 1;
}

.pagination_item_active {
  width: 44px;
  height: 44px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  padding: 10px 0;
  border-radius: 50%;
  background-color: transparent;
  color: #fff;
  cursor: pointer;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.hidden {
  display: none;
}

/* --------------------------------------------- */

@media (max-width: 900px) {
  .btn_close {
    width: 22px;
    height: 22px;
    top: 20px;
    right: 20px;
    background-image: url(../../../../../../img/btn_close2.svg);
    background-size: 22px 22px;
  }
}

@media (max-width: 460px) {
  .clientsedit {
    padding: 40px 20px 60px 20px;
  }
}

@media (max-width: 380px) {
  .payments_item p {
    font-size: 10px;
    line-height: 11px;
  }

  .column_names {
    padding: 14px 14px;
  }
  .payments_item {
    padding: 14px 14px;
  }
}
