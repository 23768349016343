.header {
  position: fixed;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: #2c2c2c0d;
  backdrop-filter: blur(15px);
  z-index: 100;
}

.header_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  height: 120px;
}

.logo {
  min-width: 160px;
  height: 30px;
}

.menu_list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 36px;
}

.menu_item {
  position: relative;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--color);
}

.menu_link {
  cursor: pointer;
}

.item_hover {
  position: absolute;
  top: 26px;
  left: 0;
  width: 0;
  height: 1px;
  opacity: 0;
  background-color: var(--color);
  transition: width 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

.menu_item:hover .item_hover {
  width: 100%;
  opacity: 1;
}

.wrap_btn {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-right: 73px;
}

.btn_pers_account {
  display: flex;
  align-items: center;
  white-space: nowrap;
  width: 200px;
  height: 60px;
  border: 1px solid var(--color);
  border-radius: 30px;
  padding: 20px 30px;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  order: 1;
}

.btn_pers_account:hover {
  color: var(--background);
  background-color: var(--color);
}

.menu_item_mob {
  display: none;
}

.btn_pers_account_mob {
  display: none;
  align-items: center;
  white-space: nowrap;
  width: 200px;
  height: 60px;
  border: 1px solid var(--color);
  border-radius: 30px;
  padding: 16px 28px;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.btn_pers_account_mob:hover {
  color: var(--background);
  background-color: var(--color);
}

.copyright {
  display: none;
  width: max-content;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 40px;
}

.copyright p {
  font-weight: 500;
  font-size: 11px;
  line-height: 22px;
  text-align: left;
  color: #ffffff;
  opacity: 0.3;
}

.copyright a {
  font-weight: 500;
  font-size: 11px;
  line-height: 22px;
  text-decoration: underline;
}

.navlink_active {
  cursor: pointer;
}

.navlink_active + .item_hover {
  width: 100%;
  opacity: 1;
}

.navlink {
  cursor: pointer;
}

.menu_item_temp {
  position: relative;
  display: flex;
  gap: 4px;
}

.menu_item_temp {
  top: 95px;
  right: 230px;
  position: absolute;
  color: #4f515a;
}

.temp_link {
  display: block;
  font-size: 12px;
  line-height: 12px;
  padding: 2px;
  border: 1px solid transparent;
  transition: color 0.4s ease-in-out, border-color 0.4s ease-in-out;
}

.temp_link:nth-child(1) {
  left: 0;
  top: -7px;
}

.temp_link:nth-child(2) {
  left: 26px;
  top: -7px;
}

.temp_link:first-child:hover {
  border: 1px solid yellow;
  color: yellow;
}

.temp_link:last-child:hover {
  border: 1px solid green;
  color: green;
}

@media (max-width: 1500px) {
  .logo {
    min-width: 107px;
    height: 20px;
  }
}

@media (max-width: 1450px) {
  .menu_list {
    gap: 20px;
  }
}

@media (max-width: 1300px) {
  .menu_list {
    gap: 12px;
  }
}

@media (max-width: 1220px) {
  .copyright {
    display: flex;
  }

  .wrap_mob {
    display: none;
  }

  .menu_list {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 30px;
  }

  .menu_item:last-child {
    margin-top: 20px;
  }

  .menu_item {
    font-size: 24px;
    line-height: 29px;
  }

  .item_hover {
    top: 32px;
  }

  .menu_item_mob {
    display: block;
    margin-bottom: 70px;
    font-size: 16px;
    line-height: 20px;
  }

  .btn_pers_account_mob {
    display: flex;
  }
}

@media (max-width: 900px) {
  .header_container {
    height: 60px;
  }

  .btn_pers_account {
    display: none;
  }
}
