.overlay_show {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #0d0d1e80;
  backdrop-filter: blur(15px);
  z-index: 101;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: transform 0.2s ease-in-out, opacity 0.6s ease-in-out;
}

.overlay_hide {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #0d0d1e80;
  backdrop-filter: blur(15px);
  z-index: 101;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transform: scale(0);
  transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

.deptclients {
  width: 100%;
  max-width: 1278px;
  padding-bottom: 80px;
}

.title_and_btn {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 25px;
}

.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: var(--color);
}

.add_news {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 14px 30px;
  border: 1px solid #ffffff33;
  border-radius: 30px;

  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: #ffffff;
  transition: border-color 0.4s ease-in-out;
}

.add_news:hover {
  border-color: #ffffff;
}

.table_header {
  width: 100%;
  display: grid;
  grid-template-columns:
    minmax(max-content, 50px)
    minmax(110px, 180px)
    minmax(150px, 1000px)
    minmax(40px, 70px);

  align-items: center;
  justify-content: space-between;
  padding: 12px 24px 12px 12px;
  margin-bottom: 25px;

  background: #d9d9d909;
  border-radius: 5px;
}

.header_item {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
  opacity: 0.6;
  padding: 2px 12px;
}

.header_item:last-child {
  width: 24px;
  height: 24px;
  padding-top: 0;
  padding-left: 0;
  justify-self: end;
}

.clients_list {
  margin-bottom: 30px;
}

.pagination {
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.pagination_btn_prev {
  width: 44px;
  height: 44px;
  background-image: url('../../../../img/btn_right.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 10px 16px;
  transform: rotate(180deg);
  opacity: 0.2;
  transition: opacity 0.3s ease-in-out;
}

.pagination_btn_prev:hover {
  opacity: 1;
}

.pagination_btn_next {
  width: 44px;
  height: 44px;
  background-image: url('../../../../img/btn_right.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 10px 16px;
  opacity: 0.2;
}

.pagination_btn_next:hover {
  opacity: 1;
}

.pagination_list {
  display: flex;
  align-items: center;
  gap: 4px;
}

.pagination_item {
  width: 44px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  padding: 10px 0;
  border-radius: 50%;
  background-color: transparent;
  opacity: 0.2;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.pagination_item:hover {
  background-color: #06061c;
  opacity: 1;
}

.pagination_item_active {
  width: 44px;
  height: 44px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  padding: 10px 0;
  border-radius: 50%;
  background-color: transparent;
  color: #fff;
  cursor: pointer;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.hidden {
  display: none;
}

@media (max-width: 790px) {
  .title_and_btn {
    align-items: center;
  }

  .add_news {
    font-weight: 500;
    font-size: 12px;
    line-height: 13px;
  }

  .table_header {
    display: none;
  }
}
