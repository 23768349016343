.adminpanel {
  background-color: var(--background);
  min-height: 100vh;
  padding-top: 90px;
  position: relative;
  overflow: hidden;
}

.adminpanel h2 {
  font-family: 'Orto';
  font-weight: 800;
  font-size: 32px;
  line-height: 43px;
  color: var(--color);
  margin-bottom: 30px;
}

.wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
}

.depts_list {
  max-width: 250px;
  min-width: 210px;
  padding-top: 6px;
  margin-bottom: 60px;
}

.dept_item {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: var(--color);
  margin-bottom: 30px;
  transition: opacity 0.4s ease-in-out;
}

.dept_item:nth-last-child(2) {
  margin-bottom: 60px;
}

.dept_item:last-child {
  margin-bottom: 0;
}

.dept_link {
  display: flex;
  align-items: center;
  gap: 15px;
}

.dept_link_active {
  display: flex;
  align-items: center;
  gap: 15px;
}

.dept_link p {
  opacity: 0.6;
  transition: opacity 0.4s ease-in-out;
}
.dept_link .red_circle {
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
}

.dept_link p:hover {
  opacity: 1;
}

.dept_link:hover > .red_circle {
  opacity: 1;
}

.red_circle_exit {
  opacity: 0;
}
