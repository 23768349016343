.news_test {
  width: 100%;
  height: 376px;
  border: 1px solid blue;
}

.news_item {
  position: relative;
  width: 100%;
  height: 300px;
  border-radius: 20px;
  outline: 1px solid #ffffff34;
  outline-offset: -4px;
  background: transparent;
  transition: background 0.4s ease-in-out, outline-offset 0.4s ease-in-out;
  cursor: default;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.news_item_wrap {
  height: 228px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 42px 36px 32px;
}

.news_item_link {
  position: absolute;
  inset: 0;
  cursor: default;
  z-index: 2;
}

.wrap_text_btn {
  height: 135px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}

.news_item_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 20px;
  bottom: 20px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #fff;
  opacity: 0.2;
  transition: opacity 0.4s ease-in-out, width 0.4s ease-in-out, height 0.4s ease-in-out;
}

.news_item_border {
  position: absolute;
  border-radius: 20px;
  /* left: -6px;
  top: -6px; */
  /* width: 376px;
  height: 376px; */
  inset: -2px;
  /* background-image: url('../../../../img/news-border.svg'); */
  background: linear-gradient(309deg, #e60b0b 3%, #ffffff 56%);
  /* background-repeat: no-repeat;
  background-size: 376px 300px; */
  opacity: 0;
  /* transition: opacity 0.4s ease-in-out; */
  z-index: -1;
}

.news_item_btn svg {
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
}

.news_item:hover {
  outline-color: transparent;
  outline-offset: -2px;
  background: linear-gradient(309deg, #1d1029 3%, #0d0d1e 56%);
}

.news_item:hover > .news_item_border {
  opacity: 1;
  transition: opacity 0.6s ease-in-out;
}

.news_item:hover > .news_item_wrap > .wrap_text_btn > .news_item_btn {
  opacity: 1;
  width: 44px;
  height: 44px;
  border: 2px solid #fff;
}

.news_item:hover > .news_item_wrap > .wrap_text_btn > .news_item_btn svg {
  opacity: 1;
}

.news_title {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;

  font-weight: 700;
  font-size: 20px;
  line-height: 33px;
  color: #fff;
}

.wrap_news_text {
width: 100%;
}

.news_text {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffffb3;
  opacity: 0.6;
}

@media (max-width: 900px) {
  .news_title {
    font-size: 16px;
    line-height: 22px;
  }

  .news_text {
    font-size: 14px;
    line-height: 20px;
  }

  .news_item_btn {
    display: none;
  }
}
