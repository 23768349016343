.error404 {
  height: calc(100vh - 120px - 191px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 1px;
  text-align: center;
}

.error404 h4 {
  width: 100%;
}

.image {
  width: 400px;
  height: 300px;
  background-image: url('../../img/404_image.svg');
  background-repeat: no-repeat;
  background-position: center;
}
