.newscard {
  position: relative;
  width: 100%;
  max-width: 505px;
  height: 100%;
  max-height: 666px;
  border-radius: 20px;
  background: transparent;

  outline: 1px solid #3d3d4b;
  outline-offset: 0px;
  cursor: pointer;
  /* overflow: hidden; */
  transition: background 0.4s ease-in-out, outline 0.4s ease-in-out;
  z-index: 1;
}
.newscard:hover {
  outline: 1px solid transparent;
  background: linear-gradient(315deg, #1d1029 0%, #0d0d1e 100%);
}

.newscardborder {
  position: absolute;
  inset: -2px;
  background: linear-gradient(317.51deg, #e60b0b 0%, #ffffff 86.67%);
  border-radius: 20px;
  z-index: -2;
  /* background-image: url('../../../../img/news_border2.svg');
  background-position: 0 0 0 0;
  background-repeat: no-repeat;
  background-size: 100% 100%; */
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
  box-shadow: 2px 2px 20px 4px #0c0c0c;
}

.newscardborder_back {
  position: absolute;
  top: 2px;
  right: 2px;
  bottom: 2px;
  left: 2px;
  background: linear-gradient(315deg, #1d1029 0%, #0d0d1e 100%);
  border-radius: 20px;
}

.newscard:hover .newscardborder {
  opacity: 1;
}

.news_img {
  width: 100%;
  height: 290px;
  object-fit: cover;
  /* outline: 1px solid #3d3d4b; */
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom: 1px solid #3d3d4b;
  margin-bottom: -1px;
}

.newswrap {
  padding: 40px;
}

.news_date {
  color: #9e9ea5;
  font-size: 16px;
  font-family: Montserrat;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 20px;
}

.news_title {
  color: #fff;
  font-size: 20px;
  font-family: Montserrat;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 20px;
  display: block;
  height: 60px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.news_announse {
  font-size: 16px;
  font-family: Montserrat;
  font-weight: 500;
  line-height: 24px;
  color: #9b9ba4;
  height: 50px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.newscardfooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px;
}

.line {
  width: 100%;
  height: 1px;
  background-color: #3d3d4b;
}

.news_more {
  color: #fff;
  font-size: 16px;
  font-family: Montserrat;
  font-weight: 700;
  line-height: 24px;
  text-transform: uppercase;
}

.news_btn {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  border: 1px solid #3d3d4b;
  transition: border 0.4s ease-in-out;
}

.news_btn_arrow {
  width: 100%;
  height: 100%;
  background-image: url('../../../../img/arrow_right.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 24px 24px;
  transition: opacity 0.4s ease-in-out;
  opacity: 0;
}

.newscard:hover .news_btn_arrow {
  opacity: 1;
}

.newscard:hover .news_btn {
  border: 1px solid #fff;
}

@media (max-width: 760px) {
  .newswrap {
    padding: 20px;
  }

  .news_date {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .news_title {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .news_announse {
    font-size: 14px;
  }

  .news_more {
    font-size: 14px;
  }

  .news_btn {
    width: 40px;
    height: 40px;
  }

  .news_btn_arrow {
    background-size: 20px 20px;
    opacity: 1;
  }

  .newscardfooter {
    padding: 20px;
  }
}

@media (max-width: 460px) {
  .newscard {
    border-radius: 12px;
  }
}
