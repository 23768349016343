.main_signin {
  width: 100%;
  height: 100vh;
  display: flex;
}

.background_img {
  position: relative;
  max-width: 1100px;
  width: 100%;
  background-image: url('../../img/escalator.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  margin-left: 90px;
  margin-bottom: 50px;
}

.background_img::after {
  content: '';
  position: absolute;
  inset: 0;
  background-color: #06061c;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  opacity: 0.8;
}

.logo {
  position: absolute;
  left: 70px;
  top: 70px;
  z-index: 12;
  filter: drop-shadow(3px 3px 4px #000);
}

.reg_autor {
  max-width: 730px;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 20px;
  padding-bottom: 90px;
  scrollbar-width: none;
}

@media (max-width: 1024px) {
  .main_signin {
    flex-direction: column;
  }

  .background_img {
    height: 300px;
    margin-left: 0;
    margin-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;

    background-image: none;
    display: flex;
    justify-content: center;
  }

  .background_img::after {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    background-color: transparent;
  }

  .logo {
    position: relative;
    left: 0;
    top: 110px;
  }

  .reg_autor {
    max-width: 100%;
    padding-top: 40px;
  }
}
