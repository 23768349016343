.section_main {
  padding-bottom: 90px;
}

.flashlight1 {
  position: fixed;
  top: 2000px;
  left: -400px;
  width: 1338px;
  height: 1338px;
  border-radius: 1338px;
  opacity: 0.25;
  background: radial-gradient(50% 50% at 50% 50%, #e60b0b 0%, #e72a4600 100%);
  z-index: -1;
}

.flashlight2 {
  position: fixed;
  top: -100px;
  right: -600px;
  width: 1338px;
  height: 1338px;
  border-radius: 1338px;
  opacity: 0.25;
  background: radial-gradient(50% 50% at 50% 50%, #e60b0b 0%, #e72a4600 100%);
  z-index: -1;
}

.main {
  min-height: 80vh;
}

.wrap_sl {
  position: relative;
  max-width: 1920px;
  width: 100%;
  min-width: 1300px;
  margin: 0 auto;
}

.signal_line {
  width: 100%;
  height: 400px;
  top: 530px;
  left: 0;
  position: absolute;
  background-image: url('../../img/line_gradient.svg');
  background-repeat: no-repeat;
  background-position: left;
  background-size: contain;
}

.title {
  color: #fff;
  font-family: Orto;
  font-size: 72px;
  font-weight: 800;
  line-height: 90px;
  margin-top: 150px;
  margin-bottom: 140px;
}

.advantages_list {
  display: flex;
  flex-wrap: wrap;
  gap: 270px 150px;
}

.wrap_item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 80px;
}

.advantages_item {
  max-width: 650px;
  width: 100%;

  color: #fff;
  font-family: Montserrat;
  font-size: 30px;
  font-weight: 500;
  line-height: 46px;
}

.advantages_item_2 {
  max-width: 650px;
  width: 100%;
  color: #9b9ba4;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
}

@media (max-width: 1280px) {
  .section_main {
    padding-bottom: 60px;
  }

  .title {
    font-size: 54px;
    line-height: 64px;
    margin-top: 105px;
    margin-bottom: 90px;
  }

  .signal_line {
    top: 420px;
  }
}

@media (max-width: 1024px) {
  .section_main {
    padding-bottom: 30px;
  }
  .title {
    font-size: 43px;
    line-height: 54px;
    margin-top: 70px;
    margin-bottom: 70px;
  }

  .signal_line {
    top: 380px;
  }
}

@media (max-width: 960px) {
  .flashlight1 {
    top: 2700px;
    left: -600px;
  }
  .signal_line {
    width: 10%;
    height: 700px;
    top: 245px;
    left: 6px;
    background-image: url('../../img/line_gradient_mob.svg');
    background-position: center;
  }

  .advantages_list {
    flex-direction: column;
    gap: 40px 0;
    padding-left: 100px;
  }

  .advantages_item {
    max-width: 100%;
  }

  .wrap_item {
    align-items: flex-start;
    max-width: none;
    flex-direction: column;
    gap: 40px;
  }
}

@media (max-width: 768px) {
  .flashlight1 {
    top: 700px;
  }
  .flashlight2 {
    top: -300px;
    right: -900px;
  }
  .signal_line {
    width: 11%;
    height: 640px;
    top: 190px;
    left: -20px;
  }
  .title {
    font-size: 32px;
    line-height: normal;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .advantages_item {
    font-size: 24px;
    line-height: 36px;
    word-wrap: break-word;
  }
  .advantages_item_2 {
    font-size: 18px;
    line-height: 24px;
  }
}

@media (max-width: 500px) {
  .flashlight1 {
    top: 300px;
    left: -1000px;
  }
  .flashlight2 {
    display: none;
  }
  .signal_line {
    height: 1000px;
    top: 270px;
    left: -16px;
    background-image: url('../../img/line_gradient_mob2.svg');
  }
  .advantages_list {
    padding-left: 80px;
  }
}

@media (max-width: 460px) {
  .section_main {
    padding-bottom: 10px;
  }
  .title {
    font-size: 28px;
    line-height: normal;
  }
}

@media (max-width: 400px) {
  .signal_line {
    height: 1300px;
    top: 300px;
    left: -40px;
  }
  .advantages_list {
    padding-left: 50px;
  }
}
