.overlay_show {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #0d0d1e80;
  backdrop-filter: blur(15px);
  z-index: 101;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: transform 0.2s ease-in-out, opacity 0.6s ease-in-out;
}

.overlay_hide {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #0d0d1e80;
  backdrop-filter: blur(15px);
  z-index: 101;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transform: scale(0);
  transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

.docs {
  width: 100%;
  max-width: 1278px;
  padding-bottom: 80px;
}

.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: var(--color);
  margin-bottom: 30px;
}

.add_news {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 14px 30px;
  border: 1px solid #ffffff33;
  border-radius: 30px;

  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: #ffffff;
  transition: border-color 0.4s ease-in-out;
}

.add_news:hover {
  border-color: #ffffff;
}

.table_header {
  width: 100%;
  display: grid;
  grid-template-columns:
    minmax(100px, 110px)
    minmax(60px, 70px)
    minmax(160px, 165px)
    minmax(110px, 120px)
    minmax(200px, 310px);
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding: 12px 0;
  margin-bottom: 25px;
  background: #d9d9d909;
  border-radius: 5px;
}

.header_item {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
  opacity: 0.6;
  padding: 2px 12px;
}

.header_item:last-child {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.icon_setting {
  width: 24px;
  height: 24px;
  background-image: url('../../../../img/icon_setting.svg');
}

.docs_list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 22px;
  margin-bottom: 30px;
}

/* ================== */
.news_item {
  width: 100%;
  display: grid;
  grid-template-columns:
    minmax(max-content, 190px)
    minmax(110px, 630px)
    minmax(max-content, 190px)
    minmax(max-content, 70px);

  align-items: center;
  justify-content: space-between;
  padding: 20px 24px;
}
/* ================== */

/* ================== */
.news_title {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  padding-left: 3px;
}
/* ================== */

/* ================== */
.item_date {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}
/* ================== */

/* ================== */
.item_btn {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: transparent;
  background-image: url('../../../../img/btn_points.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  justify-self: end;
  opacity: 0.2;
  transition: opacity 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.item_btn:hover {
  background-color: #06061c;
  opacity: 1;
}
/* ================== */

.pagination {
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.pagination_btn_prev {
  width: 44px;
  height: 44px;
  background-image: url('../../../../img/btn_right.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 10px 16px;
  transform: rotate(180deg);
  opacity: 0.2;
  transition: opacity 0.3s ease-in-out;
}

.pagination_btn_prev:hover {
  opacity: 1;
}

.pagination_btn_next {
  width: 44px;
  height: 44px;
  background-image: url('../../../../img/btn_right.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 10px 16px;
  opacity: 0.2;
}

.pagination_btn_next:hover {
  opacity: 1;
}

.pagination_list {
  display: flex;
  align-items: center;
  gap: 4px;
}

.pagination_item {
  width: 44px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  padding: 10px 0;
  border-radius: 50%;
  background-color: transparent;
  opacity: 0.2;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.pagination_item:hover {
  background-color: #06061c;
  opacity: 1;
}

.pagination_item_active {
  width: 44px;
  height: 44px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  padding: 10px 0;
  border-radius: 50%;
  background-color: transparent;
  color: #fff;
  cursor: pointer;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.hidden {
  display: none;
}

@media (max-width: 1280px) {
  .table_header {
    grid-template-columns:
      minmax(100px, 110px)
      minmax(70px, 80px)
      minmax(170px, 175px)
      minmax(110px, 120px)
      minmax(200px, 310px);
  }
}

@media (max-width: 1024px) {
  .table_header {
    grid-template-columns:
      minmax(100px, 110px)
      minmax(70px, 80px)
      minmax(120px, 140px)
      minmax(110px, 120px)
      minmax(120px, 310px);
    gap: 1px;
  }
}

@media (max-width: 900px) {
  .title {
    display: none;
  }

  .table_header {
    display: none;
  }
}
