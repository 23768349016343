.edit_det_wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 13px;
  margin-bottom: 20px;
}

.edit_det_title {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}

.edit_input {
  width: 100%;
  max-width: 620px;
  border-bottom: 1px solid#ffffff33;
  transition: border-color 0.4s ease-in-out;
}

.edit_input:hover {
  border-bottom: 1px solid#ffffffc4;
}

.edit_field_wrap {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
  padding: 0;
}

.edit_field_btn {
  width: 70px;
  opacity: 0;
  height: 18px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #864390;
  text-align: right;
  cursor: pointer;
  transform: scaleY(1);
  transition: opacity 0.4s ease-in-out, scaleY 0.4s ease-in-out;
}

.edit_field_btn_hide {
  width: 70px;
  opacity: 0;
  height: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #864390;
  text-align: right;
  cursor: pointer;
  transform: scaleY(0);
  transition: opacity 0.4s ease-in-out, scaleY 0.4s ease-in-out;
}

.edit_input:hover > .edit_field_wrap > .wrap_p > .edit_field_btn {
  height: 12px;
  transform: scaleY(1);
  opacity: 1;
}

.edit_field_title {
  width: 200px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
  opacity: 0.6;
  cursor: default;
}

.red_text {
  color: #6c6f70;
  text-decoration: line-through;
}

.edit_field_text {
  width: 100%;
  max-width: 160px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  cursor: default;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  min-height: 17px;
  height: fit-content;
  opacity: 1;
  transition: opacity 0.4s ease-in-out;
}

.edit_field_text_hidden {
  width: 100%;
  max-width: 160px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  cursor: default;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  max-height: 34px;
  opacity: 0;
  overflow: hidden;
  transition: opacity 0.4s ease-in-out;
}

.save_field_btn {
  width: 70px;
  height: 6px;
  opacity: 1;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #864390;
  text-align: right;
  cursor: pointer;
  transition: opacity 0.4s ease-in-out, caleY 0.4s ease-in-out, height 0.4s ease-in-out;
}

.save_field_btn_hide {
  width: 70px;
  height: 0;
  opacity: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #864390;
  text-align: right;
  cursor: pointer;
  transform: scaleY(0);
  transition: opacity 0.4s ease-in-out, caleY 0.4s ease-in-out, height 0.4s ease-in-out;
}

.edit_entry {
  display: inline-block;
  width: 100%;
  background-color: transparent;
  border: 1px solid #ffffff33;
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 12px;
  padding-right: 12px;
  color: #fff;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 500;
  font-size: 14px;
  height: 40px;
  line-height: 17px;
  margin-top: 10px;
  margin-bottom: 10px;
  opacity: 1;
  transition: transform 0.4s ease-in-out, height 0.4s ease-in-out, margin-top 0.4s ease-in-out,
    margin-bottom 0.4s ease-in-out, padding-top 0.4s ease-in-out, padding-bottom 0.4s ease-in-out,
    opacity 0.4s ease-in-out;
}

.edit_entry_hidden {
  display: inline-block;
  width: 100%;
  background-color: transparent;
  border: 1px solid #ffffff33;
  border-radius: 5px;
  padding-top: 1px;
  padding-bottom: 1px;
  padding-left: 12px;
  padding-right: 12px;
  color: #fff;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  height: 0;
  transform: scaleY(0);
  margin-top: 0;
  margin-bottom: 0;
  opacity: 0;
  transition: transform 0.4s ease-in-out, height 0.4s ease-in-out, margin-top 0.4s ease-in-out,
    margin-bottom 0.4s ease-in-out, padding-top 0.4s ease-in-out, padding-bottom 0.4s ease-in-out,
    opacity 0.4s ease-in-out;
}

.edit_entry:focus {
  outline-color: #ffffff17;
}

.edit_entry_hidden:focus {
  outline-color: #ffffff17;
}

.edit_entry {
  display: inline-block;
  width: 100%;
  background-color: transparent;
  border: 1px solid #ffffff33;
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 12px;
  padding-right: 12px;
  color: #fff;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 500;
  font-size: 14px;
  height: 40px;
  line-height: 17px;
  margin-top: 10px;
  margin-bottom: 10px;
  opacity: 1;
  transition: transform 0.4s ease-in-out, height 0.4s ease-in-out, margin-top 0.4s ease-in-out,
    margin-bottom 0.4s ease-in-out, padding-top 0.4s ease-in-out, padding-bottom 0.4s ease-in-out,
    opacity 0.4s ease-in-out;
}

.edit_entry_hidden {
  display: inline-block;
  width: 100%;
  background-color: transparent;
  border: 1px solid #ffffff33;
  border-radius: 5px;
  padding-top: 1px;
  padding-bottom: 1px;
  padding-left: 12px;
  padding-right: 12px;
  color: #fff;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  height: 0;
  transform: scaleY(0);
  margin-top: 0;
  margin-bottom: 0;
  opacity: 0;
  transition: transform 0.4s ease-in-out, height 0.4s ease-in-out, margin-top 0.4s ease-in-out,
    margin-bottom 0.4s ease-in-out, padding-top 0.4s ease-in-out, padding-bottom 0.4s ease-in-out,
    opacity 0.4s ease-in-out;
}

.edit_entry:focus {
  outline-color: #ffffff17;
}

.edit_entry_hidden:focus {
  outline-color: #ffffff17;
}

.label_title {
  position: relative;
}

.input_calendar {
  width: 100%;
  border-radius: 6px;
  background-color: #06061c;
  border: none;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  opacity: 1;
  color-scheme: dark;
}

.input_calendar::-webkit-calendar-picker-indicator {
  cursor: pointer;
}

.input_calendar::-moz-calendar-picker-indicator {
  cursor: pointer;
}

.input_calendar:focus {
  outline: none;
  outline-offset: 2px;
}

.placeholderedit {
  position: absolute;
  top: -3px;
  left: 0;
  width: 80%;
  background-color: #06061c;

  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #9b9ba4;
}

.wrap_title_p_hide {
  display: none;
}
