.addslideadd {
  position: relative;
  width: 600px;
  height: auto;
  background: #06061c;
  border-radius: 20px;
  padding: 42px;
}

.btn_close {
  position: absolute;
  top: 0;
  right: -65px;
  opacity: 0.2;
  transition: opacity 0.4s ease-in-out;
}

.btn_close:hover {
  opacity: 1;
}

.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #ffffff;
  margin-bottom: 40px;
}

.form {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
}

.fieldset_show {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ffffff33;
  border-radius: 30px;
  padding: 18px 30px;
}

.fieldset_hide {
  display: none;
}

.label_image {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.label_image p {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  opacity: 0.6;
}

.input_image {
  position: absolute;
  z-index: -1;
  opacity: 0;
  display: block;
  width: 0;
  height: 0;
}

.image_preview {
  position: relative;
  width: 170px;
  max-height: 170px;
  border: 1px solid #ffffff33;
  border-radius: 20px;
  padding: 15px 13px;
}

.image_preview img {
  width: 144px;
  height: 140px;
  object-fit: contain;
}

.image_preview_show {
  display: block;
}

.image_preview_show_p {
  position: absolute;
  top: -22px;
  left: 10px;

  font-family: 'NotoSans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  opacity: 0.6;
}

.image_preview_hide {
  display: none;
}

.btn_delete {
  position: absolute;
  top: -14px;
  right: -14px;
  width: 32px;
  height: 32px;
  background-image: url('../../../../../../img/btn_close.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.wrap_link {
  position: relative;
  width: 100%;
}

.wrap_link_p {
  position: absolute;
  top: -22px;
  left: 10px;

  font-family: 'NotoSans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  opacity: 0.6;
}

.wrap_link_p_hide {
  display: none;
}

.fieldset_link {
  width: 100%;
  border: 1px solid #ffffff33;
  border-radius: 30px;
  padding: 10px 20px;
}

.input_link {
  min-width: 100%;
  background-color: transparent;
  padding: 8px 10px;
  border: none;
  color: #ffffff;
}

.input_link:focus {
  outline: none;
}

.input_link::placeholder {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  opacity: 0.6;
}

.btn_submit {
  width: max-content;
  background-color: #522381;
  border-radius: 50px;
  padding: 20px 70px;
  align-self: end;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #ffffff;
  transition: background-color 0.4s ease-in-out;
}

.btn_submit:hover {
  background-color: #1f0b32;
}

.btn_submit:disabled {
  background-color: #3d3d4b;
  cursor: default;
  color: #c0bbbb;
}

@media (max-width: 760px) {
  .btn_close {
    top: 10px;
    right: 10px;
  }
}
