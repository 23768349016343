.clientsedit_wrap {
  display: flex;
  justify-content: center;
  position: relative;
}

.clientsedit {
  position: relative;
  width: 700px;
  max-height: calc(100vh - 120px);
  background: #06061c;
  border-radius: 20px;
  padding: 42px 42px 60px 42px;
  overflow-y: auto;

  scrollbar-color: #ffffff1f #ffffff07;
  scrollbar-width: thin;
}

.clientsedit::-webkit-scrollbar {
  width: 6px;
}

.clientsedit::-webkit-scrollbar-track {
  background: #ffffff07;
}

.clientsedit::-webkit-scrollbar-thumb {
  background-color: #ffffff1f;
  /* цвет плашки */
  border-radius: 20px;
  border: 1px solid #141425;
  /* padding вокруг плашки */
}

.btn_close {
  position: absolute;
  top: 0;
  right: -55px;
  opacity: 0.2;
  transition: opacity 0.4s ease-in-out;
  z-index: 2;
}

.btn_close_back {
  position: absolute;
  top: 0;
  right: -55px;
  opacity: 0.2;
  transition: opacity 0.4s ease-in-out;
  z-index: 2;
}

.btn_close:hover {
  opacity: 1;
}

.btn_close_back:hover {
  opacity: 1;
}

.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #ffffff;
  margin-bottom: 40px;
}

.form {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
}

.ft_edit_client {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  border: none;
  padding: 0;
}

.ft_edit_requisites {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 13px;
  border: none;
  padding: 0;
}

.hidden {
  position: absolute;
  top: -100px;
  left: 0;
  height: 0;
  overflow: hidden;
}

.label_image {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.label_image p {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  opacity: 0.6;
}

.input_image {
  position: absolute;
  z-index: -1;
  opacity: 0;
  display: block;
  width: 0;
  height: 0;
}

.image_preview_show {
  display: block;
}

.image_preview_hide {
  display: none;
}

.image_preview_show_p {
  position: absolute;
  top: -22px;
  left: 10px;

  font-family: 'NotoSans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  opacity: 0.6;
}

.image_preview {
  font-weight: 100;
  font-size: 10px;
  position: relative;
  width: 170px;
  max-height: 170px;
  border: 1px solid #ffffff33;
  border-radius: 20px;
  padding: 15px 13px;
}

.btn_delete {
  position: absolute;
  top: -14px;
  right: -14px;
  width: 32px;
  height: 32px;
  background-image: url('../../../../../../img/btn_close.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.wrap_title {
  position: relative;
  width: 100%;
}

.wrap_title_p {
  position: absolute;
  top: -22px;
  left: 10px;

  font-family: 'NotoSans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  opacity: 0.6;
}

.wrap_title_p_hide {
  display: none;
}

.edit_title {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #ffffff;
}

.edit_input {
  width: 100%;
  border-bottom: 1px solid#ffffff33;
  transition: border-color 0.4s ease-in-out;
}

.edit_input:hover {
  border-bottom: 1px solid#ffffffc4;
}

.edit_field_wrap {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
  padding: 0;
  transition: height 0.6s ease-in-out;
}

.edit_field_title {
  width: 200px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
  opacity: 0.6;
  cursor: default;
}

.edit_field_text {
  width: 260px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  cursor: default;

  min-height: 17px;
  height: fit-content;
  opacity: 1;
  overflow: hidden;
  transition: opacity 0.4s ease-in-out;

  /* overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; */
  padding-bottom: 10px;
}

.edit_field_text_hidden {
  width: 260px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  cursor: default;

  max-height: 34px;
  opacity: 0;
  overflow: hidden;
  transition: opacity 0.4s ease-in-out;
}

.edit_field_btn {
  width: 70px;
  opacity: 0;
  height: 18px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #864390;
  text-align: right;
  cursor: pointer;
  transform: scaleY(1);
  transition: opacity 0.4s ease-in-out, scaleY 0.4s ease-in-out;
}

.edit_field_btn_hide {
  width: 70px;
  opacity: 0;
  height: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #864390;
  text-align: right;
  cursor: pointer;
  transform: scaleY(0);
  transition: opacity 0.4s ease-in-out, scaleY 0.4s ease-in-out;
}

.edit_input:hover > .edit_field_wrap > .wrap_p > .edit_field_btn {
  height: 12px;
  transform: scaleY(1);
  opacity: 1;
}

.save_field_btn {
  width: 70px;
  height: 6px;
  opacity: 1;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #864390;
  text-align: right;
  cursor: pointer;
  transition: opacity 0.4s ease-in-out, caleY 0.4s ease-in-out, height 0.4s ease-in-out;
}

.save_field_btn_hide {
  width: 70px;
  height: 0;
  opacity: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #864390;
  text-align: right;
  cursor: pointer;
  transform: scaleY(0);
  transition: opacity 0.4s ease-in-out, caleY 0.4s ease-in-out, height 0.4s ease-in-out;
}

.edit_entry {
  display: inline-block;
  width: 100%;
  background-color: transparent;
  border: 1px solid #ffffff33;
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 12px;
  padding-right: 12px;
  color: #fff;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 500;
  font-size: 14px;
  height: 40px;
  line-height: 17px;
  margin-top: 10px;
  margin-bottom: 10px;
  opacity: 1;
  transition: transform 0.4s ease-in-out, height 0.4s ease-in-out, margin-top 0.4s ease-in-out,
    margin-bottom 0.4s ease-in-out, padding-top 0.4s ease-in-out, padding-bottom 0.4s ease-in-out,
    opacity 0.4s ease-in-out;
}

.edit_entry_hidden {
  display: inline-block;
  width: 100%;
  background-color: transparent;
  border: 1px solid #ffffff33;
  border-radius: 5px;
  padding-top: 1px;
  padding-bottom: 1px;
  padding-left: 12px;
  padding-right: 12px;
  color: #fff;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 500;
  font-size: 14px;
  height: 0;
  transform: scaleY(0);
  line-height: 17px;
  margin-top: 0;
  margin-bottom: 0;
  opacity: 0;
  transition: transform 0.4s ease-in-out, height 0.4s ease-in-out, margin-top 0.4s ease-in-out,
    margin-bottom 0.4s ease-in-out, padding-top 0.4s ease-in-out, padding-bottom 0.4s ease-in-out,
    opacity 0.4s ease-in-out;
}

.edit_entry:focus {
  outline-color: #ffffff17;
}

.edit_entry_hidden:focus {
  outline-color: #ffffff17;
}

.fieldset_title {
  width: 100%;
  border: 1px solid #ffffff33;
  border-radius: 30px;
  padding: 10px 20px;
}

.fieldset_news {
  width: 100%;
  height: 140px;
  border: 1px solid #ffffff33;
  border-radius: 30px;
  padding: 10px 20px;
}

.fieldset_date {
  width: 100%;
  max-width: 330px;
  /* display: flex;
  align-items: center;
  justify-content: space-between; */
  border: 1px solid #ffffff33;
  border-radius: 30px;
  padding: 18px 30px;
}

.input_title {
  min-width: 100%;
  background-color: transparent;
  padding: 8px 10px;
  border: none;
  color: #fff;
}

.input_title:focus {
  outline: none;
}

.input_title::placeholder {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  opacity: 0.6;
}

.input_news {
  min-width: 100%;
  height: 100%;
  background-color: transparent;
  padding: 8px 10px;
  border: none;
  color: #ffffff90;
  resize: none;

  scrollbar-color: #ffffff1f #ffffff07;
  scrollbar-width: thin;
}

.input_news::-webkit-scrollbar {
  width: 6px;
}

.input_news::-webkit-scrollbar-track {
  background: #ffffff07;
}

.input_news::-webkit-scrollbar-thumb {
  background-color: #ffffff1f;
  /* цвет плашки */
  border-radius: 20px;
  border: 1px solid #141425;
  /* padding вокруг плашки */
}

.input_news:focus {
  outline: none;
}

.input_news::placeholder {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  opacity: 0.6;
}

.input_date {
  min-width: 100%;
  background-color: transparent;
  padding: 0;
  border: none;
  color: #ffffff90;
}

.input_date::-webkit-calendar-picker-indicator {
  filter: invert(0.6);
}

.input_date:focus {
  outline: none;
}

.input_date::placeholder {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  opacity: 0.6;
}

.input_date::before {
  content: attr(data-placeholder);
  width: 100%;
  height: 18px;
  background-color: #522381;
}
.input_date:focus::before,
.input_date:valid::before {
  display: none;
}

.btns_requisites {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}

.button_requisites {
  display: flex;
  align-items: center;
  gap: 12px;
  width: max-content;
  background-color: #52238131;
  border-radius: 50px;
  padding: 18px 36px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #ffffff;
  transition: background-color 0.4s ease-in-out;
}

.button_requisites:hover {
  background-color: #20124d;
}

.btn_submit {
  align-self: end;
  width: max-content;
  background-color: #522381;
  border-radius: 50px;
  padding: 20px 60px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #ffffff;
  transition: background-color 0.4s ease-in-out;
}

.btn_submit:hover {
  background-color: #1f0b32;
}

.btn_submit:disabled {
  background-color: #3d3d4b;
  color: #9b9ba4;
  cursor: default;
}

.filter_select {
  appearance: none;
  background-image: url('../../../../../../img/arrow_down2.svg');
  background-repeat: no-repeat;
  background-color: #fff;
  background-position: right center;

  width: 100%;
  padding: 8px 16px 8px 8px;
  color: #fff;
  border-radius: 5px;
  background-color: #06061c;
  border: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
}

.filter_select:focus {
  outline: none;
}

.filter_select option {
  color: #fff;
  background-color: #1c1c2c;
}

.note_wrap {
  width: 100%;
  margin-bottom: 10px;
}

.note_empty {
  padding: 0 5px 4px 5px;
  height: 0;
  overflow: hidden;
  opacity: 0;

  color: #e60b0b;
  font-family: NotoSans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  transition: height 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

.note_hide {
  padding: 0 5px 4px 5px;
  height: 0;
  overflow: hidden;
  opacity: 0;

  color: #e60b0b;
  font-family: NotoSans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  transition: height 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

.note_mail {
  padding: 0 5px 4px 5px;
  height: 22px;
  overflow: hidden;
  opacity: 1;

  color: #e60b0b;
  font-family: NotoSans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  transition: height 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

.label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  width: 100%;
  border-radius: 30px;
  border: 1px solid #3d3d4b;
  padding: 18px 30px;
  background-color: transparent;
  /* margin-bottom: 40px; */
  transition: border-color 0.4s ease-in-out;
}

.input_phone {
  width: 100%;
  border: none;
  background-color: transparent;

  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.input_phone:focus {
  outline: none;
}

.input_phone:focus-within {
  outline: none;
  border-color: #fff;
}

.input_phone::placeholder {
  color: #9b9ba4;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.select {
  width: max-content;
  color: #fff;
  border-radius: 4px;
  background-color: transparent;
  border: none;

  appearance: none;

  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.select:focus {
  outline: none;
  border: none;
}

/* ====================================== */
/* ====================================== */
.address_group {
  width: 100%;
  height: 100%;
  /* overflow: hidden; */
  transition: height 0.4s ease-in-out;
  padding-bottom: 20px;
}

.address_group_hidden {
  height: 0;
  overflow: hidden;
}

.wrap_title {
  position: relative;
  width: 100%;
}

.mb20 {
  margin-bottom: 20px;
}

.mb30 {
  margin-bottom: 30px;
}

.wrap_title_h6 {
  margin-bottom: 35px;
  font-family: 'NotoSans';
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #ffffff;
  opacity: 0.6;
}

.address_wrap {
  position: relative;
  width: 100%;
  margin-bottom: 10px;
}

.address {
  width: 100%;
  height: 60px;
  margin-bottom: 30px;
  overflow: hidden;
  white-space: nowrap;
  border-radius: 30px;
  border: 1px solid #ffffff33;
  background-color: #0d0d1e;
  cursor: default;
  transition: border 0.4s ease-in-out, height 0.4s ease-in-out;
}

.address:hover {
  border: 1px solid #ffffffd3;
}

.list_city {
  width: 100%;
  height: 100%;
  overflow: auto;
  margin-top: 6px;
  border-radius: 5px;
  font: 500 14px/18px Montserrat;
  cursor: pointer;
  font: 600 16px/20px Montserrat;
  transition: border-color 0.4s ease-in-out;
  padding-bottom: 80px;
  background-color: #0d0d1e;
}

.item_city {
  padding: 8px 30px;
  color: #ffffff8f;
  background-color: #0d0d1e;
  transition: color 0.2s ease-in-out;
}

.item_city:hover {
  color: #fff;
}

.item_city:first-child {
  color: #ffffff4f;
  border-top: 1px solid #ffffff46;
  border-bottom: 1px solid #ffffff46;
}

.sorting_item:hover {
  color: #6f3482;
  background-color: #ffffff17;
}

.address_wrapper {
  box-sizing: border-box;
  width: 100%;
  padding: 20px 30px 13px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #ffffff8f;
}

.address_p {
  color: #ffffff8f;
  font: 600 16px/20px Montserrat;
}

.address_svg {
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.address_svg_rotage {
  transform: rotate(180deg);
}

.wrap_title_p {
  position: absolute;
  top: -22px;
  left: 10px;

  font-family: 'NotoSans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  opacity: 0.6;
  z-index: 999;
}

.wrap_title_p_hide {
  display: none;
}

.input_search_wrap {
  border-radius: 30px;
  border: none;
  height: 60px;
  max-height: 375px;
  overflow: hidden;
  outline: 1px solid #4f5152;
  outline-offset: -1px;
  transition: outline 0.4s ease-in-out height 0.4s ease-in-out;
}

.input_search_wrap:focus-within {
  outline: 1px solid #9b9ba4;
  height: 100%;
}

.input_search_wrap:hover {
  outline: 1px solid #9b9ba4;
}

.input_search {
  width: 100%;
  border-radius: 30px;
  padding: 18px 30px;
  background-color: transparent;

  border: none;
  caret-color: #9b9ba4;

  color: #ffffff8e;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.input_search:focus {
  border: none;
  outline: none;
}

.input_search::placeholder {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #fff;
}

.list_search {
  width: 100%;
  margin-top: 6px;
  border-radius: 5px;
  font: 500 14px/18px Montserrat;
  cursor: pointer;

  max-height: 295px;
  overflow: auto;

  font: 600 16px/20px Montserrat;
  transition: border-color 0.4s ease-in-out;
  padding-bottom: 20px;
  background-color: #0d0d1e;
}

.item_search {
  padding: 8px 30px;
  color: #ffffff8f;
  background-color: #0d0d1e;
  transition: color 0.2s ease-in-out;
}

.item_search:hover {
  color: #fff;
}

.item_search:first-child {
  color: #ffffff4f;
  border-top: 1px solid #ffffff46;
  border-bottom: 1px solid #ffffff46;
}

.item_search:nth-child(2) {
  margin-top: 10px;
}

/* ===================================== */
/* ===================================== */

@media (max-width: 820px) {
  .btn_close {
    top: 10px;
    right: 10px;
  }

  .btn_close_back {
    top: 10px;
    right: 10px;
  }

  .btns_requisites {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (max-width: 720px) {
  .clientsedit_wrap {
    width: 100%;
  }
  .clientsedit {
    width: 100%;
  }
}
