.overlay_show {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #0d0d1e80;
  backdrop-filter: blur(15px);
  z-index: 101;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: transform 0.2s ease-in-out, opacity 0.6s ease-in-out;
}

.overlay_hide {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #0d0d1e80;
  backdrop-filter: blur(15px);
  z-index: 101;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transform: scale(0);
  transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

.lk_about_company {
  width: 100%;
  max-width: 1278px;
  padding-bottom: 80px;
}

.title {
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: var(--color);
  margin-top: -6px;
}

.notification_true {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 20px 30px;
  border-radius: 6px;
  background-color: purple;
  font-weight: 700;
  font-size: 22px;
  line-height: normal;
  text-align: center;
  color: var(--color);
}

.notification_false {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 20px 30px;
  border-radius: 6px;
  background-color: red;
  font-weight: 700;
  font-size: 22px;
  line-height: normal;
  text-align: center;
  color: var(--color);
}

.notification_hide {
  display: none;
}

.wrap {
  margin-bottom: 50px;
}

.data_list {
  width: 100%;
  max-width: 700px;
  margin-bottom: 20px;
}

.data_item {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 12px 10px 12px 0;
  border-bottom: 1px solid #3d3d4b;
}

.item_name {
  display: block;
  width: 100%;
  width: 180px;
  padding-right: 10px;

  color: #9b9ba4;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.item_content {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  width: calc(100% - 180px);
}

.list_btn {
  color: #864390;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  transition: color 0.4s ease-in-out;
}

.list_btn:hover {
  color: #b277bb;
}

.list_btn:active {
  color: red;
}

.autor_form {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 30px;
  /* margin: 0 auto; */
  max-width: 420px;
  width: 100%;
  margin-bottom: 30px;
}

.note_pass2 {
  padding: 0 5px 4px 5px;
  height: 22px;
  overflow: hidden;
  opacity: 1;

  color: #e60b0b;
  font-family: NotoSans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  transition: height 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

.note_pass {
  padding: 0 5px 4px 5px;
  height: 67px;
  overflow: hidden;
  opacity: 1;

  color: #e60b0b;
  font-family: NotoSans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  transition: height 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

.note_empty {
  padding: 0 5px 4px 5px;
  height: 0;
  overflow: hidden;
  opacity: 0;

  color: #e60b0b;
  font-family: NotoSans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  transition: height 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

.note_hide {
  padding: 0 5px 4px 5px;
  height: 0;
  overflow: hidden;
  opacity: 0;

  color: #e60b0b;
  font-family: NotoSans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  transition: height 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

.input {
  width: 100%;
  border-radius: 30px;
  border: 1px solid #3d3d4b;
  padding: 18px 30px;
  background-color: transparent;

  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.input:focus {
  outline: 1px solid #ffffff36;
  outline-offset: -1px;
}

.input::placeholder {
  color: #9b9ba4;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.input_wrap {
  position: relative;
  height: 0;
}

.input_btn_closed {
  position: absolute;
  top: -46px;
  right: 20px;
  width: 32px;
  height: 32px;
  background-image: url('../../../../img/btn_password.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  opacity: 0.2;
  transition: opacity 0.4s ease-in-out;
}

.input_btn_closed:hover {
  opacity: 1;
}

.input_btn_open {
  position: absolute;
  top: -46px;
  right: 20px;
  width: 32px;
  height: 32px;
  background-image: url('../../../../img/btn_password2.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  opacity: 0.2;
  transition: opacity 0.4s ease-in-out;
}

.input_btn_open:hover {
  opacity: 1;
}

.button_submit {
  width: 100%;
  padding: 20px 30px;
  border-radius: 50px;
  background-color: #522381;
  transition: background-color 0.4s ease-in-out;
}

.button_submit:hover {
  background-color: #1f0b32;
}

.button_submit:disabled {
  width: 100%;
  padding: 20px 30px;
  border-radius: 50px;
  color: #8d8888;
  background-color: #42364e;
  cursor: default;
}

@media (max-width: 768px) {
  .title {
    font-size: 16px;
  }

  .item_content {
    font-size: 12px;
  }

  .input {
    font-size: 14px;
  }

  .input::placeholder {
    font-size: 14px;
  }

  .button_submit {
    font-size: 14px;
  }

  .note_pass {
    font-size: 12px;
    line-height: normal;
  }

  .note_pass2 {
    font-size: 12px;
    line-height: normal;
  }
}

@media (max-width: 420px) {
  .item_name {
    word-wrap: break-word;
    width: 130px;
  }

  .item_content {
    width: calc(100% - 130px);
  }
}
