.overlay_show {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #0d0d1e80;
  backdrop-filter: blur(15px);
  z-index: 101;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: transform 0.2s ease-in-out, opacity 0.6s ease-in-out;
}

.overlay_hide {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #0d0d1e80;
  backdrop-filter: blur(15px);
  z-index: 101;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transform: scale(0);
  transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

.liftdown_item {
  width: 100%;
  display: grid;
  grid-template-columns:
    minmax(55px, 55px)
    minmax(90px, 100px)
    minmax(90px, 90px)
    minmax(90px, 90px)
    minmax(90px, 90px)
    minmax(70px, 80px)
    minmax(100px, 110px)
    minmax(180px, 210px)
    minmax(60px, 90px)
    minmax(70px, 130px);
  align-items: center;
  justify-content: space-between;
  padding: 30px 18px 30px 12px;
  border-radius: 10px;
  border: 1px solid transparent;
  transition: border-color 0.4s ease-in-out;
}

.liftdown_item:hover {
  border-color: #fff;
}

.wrap_item {
  position: relative;
}

.item_title {
  display: none;
  color: #9b9ba4;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
}

.item_text {
  border-radius: 3px;
  padding-left: 8px;
  padding-right: 8px;
  color: #fff;
  cursor: default;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  background-color: transparent;
  transition: box-shadow 0.4s ease-in-out;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: normal;
}

.item_text:hover {
  position: absolute;
  top: -9px;
  overflow: visible;
  box-shadow: 0 0 4px #ffffff;
  background-color: #1c1d1d;
  z-index: 100;
}

.nowrap {
  white-space: nowrap;
}

.item_ver {
  margin: auto;
  width: 32px;
  height: 32px;
  background-image: url('../../../../../../img/icon_verifed.svg');
  background-repeat: no-repeat;
  background-size: 32 32;
  background-position: center;
}

.item_nover {
  margin: auto;
  width: 32px;
  height: 32px;
  background-image: url('../../../../../../img/icon_noverifed.svg');
  background-repeat: no-repeat;
  background-size: 32 32;
  background-position: center;
}

.item_id {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  padding-left: 8px;
}

.item_edit_del {
  position: relative;
  justify-self: end;
}

.item_btn {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: transparent;
  background-image: url('../../../../../../img/btn_points.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  opacity: 0.2;
  transition: opacity 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.item_btn:hover {
  background-color: #06061c;
  opacity: 1;
}

.btn_menu {
  position: absolute;
  width: 164px;
  height: 104px;
  top: 40px;
  right: 35px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  justify-content: space-between;
  padding: 12px 18px;
  background-color: #0d0d1e;
  border: 1px solid #cccccc;
  box-shadow: 0px 8px 15px #0000000d;
  border-radius: 5px;
  transform: scale(0);
  opacity: 0;
  cursor: default;
  transition: opacity 0.2s ease-in-out, transform 0.4s ease-in-out;
}

.btn_menu_show {
  position: absolute;
  width: 164px;
  height: 104px;
  top: 40px;
  right: 35px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  justify-content: space-between;
  padding: 12px 18px;
  background-color: #0d0d1e;
  border: 1px solid #cccccc;
  box-shadow: 0px 8px 15px #0000000d;
  border-radius: 5px;
  cursor: default;
  opacity: 1;
  z-index: 2;
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
}

.btn_menu_item {
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  opacity: 0.6;
  padding: 6px 0;
  transition: color 0.3s ease-in-out;
}

.btn_menu_item:hover {
  color: #e60b0b;
}

.item_requisites {
  text-align: center;
}

.btn_requisites {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: transparent;
  background-image: url('../../../../../../img/icon_requisites.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  justify-self: end;
  opacity: 0.2;
  transition: opacity 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.btn_requisites:hover {
  background-color: #06061c;
  opacity: 1;
}

.btn_bankrequisites {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: transparent;
  background-image: url('../../../../../../img/icon_requisitesbank.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  justify-self: end;
  opacity: 0.2;
  transition: opacity 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.btn_bankrequisites:hover {
  background-color: #06061c;
  opacity: 1;
}

.item_equip {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin: auto;
  border-radius: 50%;
  border: 1px solid #ffffff1e;

  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
}

@media (max-width: 1220px) {
  .liftdown_item {
    display: block;
    border-radius: 20px;
    border: 1px solid #3d3d4b;
    padding: 20px;
    margin-bottom: 10px;
  }

  .wrap_item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 18px;
  }

  .wrap_item:last-child {
    margin-bottom: 0;
  }

  .item_title {
    display: block;
    max-width: 200px;
    width: 100%;
  }
}

@media (max-width: 450px) {
  .item_title {
    max-width: 120px;
  }

  .item_text {
    max-width: calc(100% - 120px);
  }
}
