.sectionbrands {
  position: relative;
  padding-top: 80px;
}

.sectionbrands::after {
  position: absolute;
  content: '';
  top: 220px;
  right: -660px;
  width: 1340px;
  height: 1340px;
  background: radial-gradient(50% 50% at 50% 50%, #e60b0b 0%, #e72a4600 100%);
  opacity: 0.25;
  z-index: -1;
}

.sectionbrands h2 {
  font-family: 'Orto';
  font-weight: 800;
  font-size: 32px;
  line-height: 43px;
  color: var(--color);
  margin-bottom: 65px;
}

.hidden {
  display: none;
}

.brands_container {
  display: flex;
  padding-bottom: 100px;
}

.wrap_btns {
  width: 25%;
  border-right: 1px solid #fff;
}

.btns_list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.brand_link {
  max-width: 335px;
  width: 84%;
  height: 95px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 20px;
  border: 1px solid #fff;
  opacity: 0.5;
  transition: opacity 0.4s ease-in-out;
}

.brand_link:hover {
  opacity: 1;
}

.brand_link_active {
  position: relative;
  max-width: 335px;
  width: 84%;
  height: 95px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 20px;
  border: 1px solid #fff;
  opacity: 1;
  transition: opacity 0.4s ease-in-out;
}

.brand_img {
  max-width: 335px;
  width: 84%;
  height: 95px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 20px;
  border: 1px solid #fff;
  opacity: 0.5;
  transition: opacity 0.4s ease-in-out;
}

.brand_img_active {
  position: relative;
  max-width: 335px;
  width: 84%;
  height: 95px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 20px;
  border: 1px solid #fff;
  opacity: 1;
  transition: opacity 0.4s ease-in-out;
}

.brand_img_active::before {
  position: absolute;
  content: '';
  top: 47px;
  right: calc(-20% + 2px);
  width: calc(20% - 3px);
  height: 2px;
  background-color: #fff;
}

.brand_link_active::before {
  position: absolute;
  content: '';
  top: 47px;
  right: calc(-20% + 2px);
  width: calc(20% - 3px);
  height: 2px;
  background-color: #fff;
}

.wrap_info {
  width: 75%;
  padding-left: 50px;
  padding-top: 6px;
}

.wrap_info h3 {
  font-weight: 700;
  font-size: 22px;
  line-height: 33px;
  color: #fff;
  margin-bottom: 40px;
}

.info_btn_list {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-bottom: 30px;
}

.info_btn_list_show {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-bottom: 30px;
}

.info_item_btn {
  padding: 12px 20px;
  border: 1px solid transparent;
  border-radius: 10px;
  font-weight: 700;
  font-size: 18px;
  line-height: 33px;
  text-transform: uppercase;
  color: #ffffff;
  opacity: 0.5;
  transition: opacity 0.4s ease-in-out, border-color 0.8s ease-in-out;
}

.info_item_btn_active {
  padding: 12px 20px;
  border: 1px solid #fff;
  border-radius: 10px;
  font-weight: 700;
  font-size: 18px;
  line-height: 33px;
  text-transform: uppercase;
  color: #ffffff;
  opacity: 1;
  transition: opacity 0.4s ease-in-out, border-color 0.8s ease-in-out;
}

.info_item_btn:hover {
  border-color: #fff;
  opacity: 1;
}

.description {
  width: 100%;
  max-width: 1000px;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #fff;
  opacity: 0.6;
  margin-bottom: 40px;
}

.warranty_parts_list {
  width: 100%;
  max-width: 1000px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 46px 20px;
}

.warranty_part_item {
  width: 450px;
  display: flex;
  /* align-items: center; */
  align-items: flex-start;
  gap: 30px;
}

.warranty_part_item img {
  width: 120px;
}

.warranty_part_item h4 {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #fff;
  margin-bottom: 20px;
}

.warranty_part_item p {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  opacity: 0.6;
}

.drop_down_mob {
  display: none;
}

@media (max-width: 1300px) {
  .sectionbrands {
    padding-top: 70px;
  }

  .warranty_parts_list {
    width: 100%;
    max-width: none;
    display: block;
  }

  .warranty_part_item {
    width: 530px;
    margin-bottom: 30px;
  }
}

@media (max-width: 1160px) {
  .sectionbrands h2 {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 50px;
  }

  .warranty_part_item {
    width: 570px;
  }
}

@media (max-width: 900px) {
  .sectionbrands::after {
    display: none;
  }

  .sectionbrands h2 {
    text-align: center;
    font-size: 16px;
    line-height: 22px;
    padding-top: 0;
    margin-bottom: 30px;
  }

  .brands_container {
    flex-direction: column;
    padding-bottom: 60px;
  }

  .wrap_btns {
    width: 100%;
    height: 95px;
    border-right: none;
    border-bottom: 1px solid #fff;
    padding-bottom: 25px;
    margin-bottom: 120px;
    overflow: auto;
    scrollbar-width: none;
  }

  .wrap_btns::-webkit-scrollbar {
    display: none;
  }

  .btns_list {
    flex-direction: row;
    justify-content: center;
  }

  .brand_img {
    width: 170px;
    height: 60px;
    border-radius: 10px;
  }

  .brand_img_active {
    width: 170px;
    height: 60px;
    border-radius: 10px;
  }

  .brand_img_active::before {
    top: 60px;
    right: 50%;
    width: 2px;
    height: 33px;
  }

  .brand_link {
    width: 170px;
    height: 60px;
    border-radius: 8px;
    padding: 12px 24px;
  }

  .brand_link_active {
    width: 170px;
    height: 60px;
    border-radius: 8px;
    padding: 12px 24px;
  }

  .brand_link_active::before {
    position: absolute;
    content: '';
    top: 60px;
    right: 80px;
    width: 2px;
    height: 23px;
    background-color: #fff;
  }

  .btn_item img {
    width: 70%;
    height: 70%;
    object-fit: contain;
  }

  .wrap_info {
    width: 100%;
    max-width: 550px;
    padding-left: 0;
    margin: 0 auto;
  }

  .wrap_info h3 {
    font-size: 16px;
    line-height: 22px;
  }

  /* =================================== */

  .wrap_btn_group {
    position: relative;
  }

  .drop_down_mob {
    position: absolute;
    top: -79px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 24px;
    padding-right: 24px;
    width: 100%;
    height: 50px;
    border: 1px solid #ffffff3a;
    border-radius: 30px;
    margin-bottom: -52px;
    background-color: #0d0d1e;
    z-index: 1;
  }

  .btn_dropdown_down {
    padding: 2px;
    cursor: pointer;
    transform: rotate(0deg);
    transition: transform 0.4s ease-in-out;
  }

  .btn_dropdown_up {
    padding: 2px;
    cursor: pointer;
    transform: rotate(180deg);
    transition: transform 0.4s ease-in-out;
  }

  .info_btn_list {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    position: absolute;
    top: -79px;
    flex-direction: column;
    width: 100%;
    height: 0;
    border: 1px solid transparent;
    border-radius: 24px;
    align-items: stretch;
    gap: 0;
    background-color: #0d0d1e;
    overflow: hidden;
    opacity: 0;
  }

  .info_btn_list_show {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    position: absolute;
    top: -80px;
    flex-direction: column;
    width: 100%;
    height: 176px;
    border: 1px solid transparent;
    border-radius: 24px;
    align-items: stretch;
    gap: 0;
    background-color: #0d0d1e;
    overflow: hidden;
    display: flex;
    opacity: 1;
  }

  .info_item {
    padding: 6px 0;
    border-radius: 30px;
  }

  .info_item:nth-child(1) {
    margin-top: 75px;
  }

  .info_item_empty {
    position: absolute;
    inset: 0;
    border: 1px solid #ffffff;
    border-radius: 24px;
    pointer-events: none;
    z-index: 2;
  }

  .info_item_btn {
    padding: 0 24px;
    border: none;
    border-radius: 10px;
    font-size: 14px;
    line-height: 24px;
    opacity: 1;
    transition: color 0.4s ease-in-out;
  }

  .info_item_btn_active {
    padding: 0 24px;
    border: none;
    border-radius: 10px;
    font-size: 14px;
    line-height: 24px;
    opacity: 1;
    transition: color 0.4s ease-in-out;
  }

  .info_item_btn:hover {
    color: #864390;
  }

  /* =================================== */

  .description {
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    opacity: 1;
    margin-bottom: 40px;
  }

  .warranty_parts_list {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .warranty_part_item {
    width: 270px;
    display: flex;
    align-items: center;
    gap: 30px;
    margin-bottom: 0;
  }

  .warranty_part_item img {
    width: 90px;
  }

  .warranty_part_item h4 {
    font-size: 14px;
    line-height: 18px;
    margin-left: 0;
    margin-bottom: 9px;
  }

  .warranty_part_item p {
    font-size: 12px;
    line-height: 18px;
  }
}

@media (max-width: 690px) {
  .sectionbrands {
    padding-top: 55px;
  }

  .brands_container {
    padding-bottom: 30px;
  }

  .warranty_parts_list {
    display: flex;
    overflow-x: auto;
    padding-bottom: 20px;
    scrollbar-width: none;
    scrollbar-color: #9b9ba4 #3d3d4b;
  }

  .warranty_parts_list::-webkit-scrollbar {
    height: 10px;
  }

  .warranty_parts_list::-webkit-scrollbar-track {
    background-color: #2222328f;
    border-radius: 20px;
  }

  .warranty_parts_list::-webkit-scrollbar-thumb {
    background-color: #9b9ba4bd;
  }

  .warranty_part_item {
    flex-direction: column;
    padding: 10px;
    align-items: start;
    padding-left: 0;
    min-width: 150px;
  }

  .warranty_part_item:first-child {
    padding-left: 0;
  }
}

@media (max-width: 590px) {
  .btns_list {
    justify-content: flex-start;
  }
}

/* @media (max-width: 460px) {
  sectionbrands {
    padding-top: 85px;
  }
} */
