.slide_previews_item {
  position: relative;
  width: 376px;
  height: 376px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ffffff3f;
  border-radius: 20px;
  color: #36214a;
  transition: border 0.4s ease-in-out, color 0.4s ease-in-out;
  overflow: hidden;
}

.slide_previews_item:hover {
  border: 1px solid #fff;
  color: #864390;
}

.slide_previews_item:hover > .num_and_btn > .btn_menu {
  opacity: 1;
}

.slide_previews_item:hover > .btns_change_wrap {
  opacity: 1;
}

.img {
  max-width: 200px;
  max-height: 200px;
}

.menu_list {
  position: absolute;
  width: 164px;
  height: 104px;
  top: 55px;
  right: 5px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  justify-content: space-between;
  padding: 12px 18px;
  background: #0d0d1e;
  border: 1px solid #cccccc;
  box-shadow: 0px 8px 15px #0000000d;
  border-radius: 5px;
  transform: scale(0);
  opacity: 0;
  transition: opacity 0.2s ease-in-out, transform 0.4s ease-in-out;
}

.menu_list_show {
  position: absolute;
  width: 164px;
  height: 104px;
  top: 55px;
  right: 5px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  justify-content: space-between;
  padding: 12px 18px;
  background: #0d0d1e;
  border: 1px solid #cccccc;
  box-shadow: 0px 8px 15px #0000000d;
  border-radius: 5px;
  opacity: 1;
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
}

.menu_item {
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  opacity: 0.6;
  padding: 6px 0;
  transition: color 0.3s ease-in-out;
}

.menu_item:hover {
  color: #e60b0b;
}

.num_and_btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px 14px 7px 20px;

  font-family: 'Orto';
  font-weight: 800;
  font-size: 35px;
  line-height: 47px;
}

.btn_menu {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-image: url('../../../../../../img/btn_points.svg');
  background-color: #06061c;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  opacity: 0.2;
  transition: opacity 0.4s ease-in-out;
}

.btns_change_wrap {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0 14px 14px 0;
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
}

.btns_change {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  width: 68px;
  height: 32px;
  background: #06061c;
  border-radius: 20px;
}

.change_prev {
  opacity: 0.2;
  transition: opacity 0.4s ease-in-out;
}

.change_prev:hover {
  opacity: 1;
}

.change_prev:disabled {
  opacity: 0.07;
  cursor: default;
}

.change_next {
  opacity: 0.2;
  transition: opacity 0.4s ease-in-out;
}

.change_next:hover {
  opacity: 1;
}

.change_next:disabled {
  opacity: 0.07;
  cursor: default;
}

.overlay_show {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #0d0d1e80;
  backdrop-filter: blur(15px);
  z-index: 101;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: transform 0.2s ease-in-out, opacity 0.6s ease-in-out;
}

.overlay_hide {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #0d0d1e80;
  backdrop-filter: blur(15px);
  z-index: 101;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transform: scale(0);
  transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
}
