/* fonts ===========================*/

@font-face {
  font-family: 'Montserrat';
  font-weight: 500;
  src: url('./fonts/Montserrat-Medium.woff2') format('woff2'),
    url('./fonts/Montserrat-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 700;
  src: url('./fonts/Montserrat-Bold.woff2') format('woff2'),
    url('./fonts/Montserrat-Bold.woff') format('woff');
}

@font-face {
  font-family: 'NotoSans';
  font-weight: 400;
  src: url('./fonts/NotoSans.woff2') format('woff2'), url('./fonts/NotoSans.woff') format('woff');
}

@font-face {
  font-family: 'Orto';
  font-weight: 800;
  src: url('./fonts/orto.woff2') format('woff2'), url('./fonts/orto.woff') format('woff');
}

@font-face {
  font-family: 'Crystalrevived';
  font-weight: 400;
  src: url('./fonts/crystalrevived.woff') format('woff');
}

/* 
Thin / Hair 	100
Extra Light 	200
Light 	300
Regular 	400
Medium 	500
Semi Bold 	600
Bold 	700
Extra Bold 	800
Black / Heavy / Ultra 	900 
*/

:root {
  --background: #0d0d1e;
  --color: #ffffff;
}

/* normolize ===========================*/

html {
  line-height: 1.15;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

a {
  background-color: transparent;
}

abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

button,
input {
  overflow: visible;
}

/* Сброс стилей для автозаполнения в Chrome, Safari и Edge */
input:-webkit-autofill,
input:-webkit-autofill:focus {
  -webkit-text-fill-color: #9b9ba4; /* Цвет текста */
  -webkit-box-shadow: 0 0 0px 100px #0d0d1e inset; /* Убираем фоновый цвет */
}

/* Сброс стилей для автозаполнения в Firefox */
input:-moz-autofill {
  -moz-appearance: textfield; /* Сброс стилей для Firefox */
  -moz-box-shadow: 0 0 0px 1000px #0d0d1e inset; /* Убираем фоновый цвет */
}

button,
select {
  text-transform: none;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  appearance: button;
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box;
  /* 1 */
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  white-space: normal;
  /* 1 */
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type='checkbox'],
[type='radio'] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  appearance: textfield;
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
}

[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

details {
  display: block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

/* normolize ===========================*/

/* default ===========================*/

* {
  scrollbar-color: #ffffff1f #ffffff07;
  scrollbar-width: thin;
}

*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  background: #ffffff07;
}

*::-webkit-scrollbar-thumb {
  background-color: #ffffff1f;
  /* цвет плашки */
  border-radius: 20px;
  border: 1px solid #141425;
  /* padding вокруг плашки */
}

html {
  box-sizing: border-box;
  /* scroll-behavior: auto; */
  scroll-behavior: smooth;
  font-size: 100%;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

body {
  min-width: 320px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  color: var(--color);
  background-color: var(--background);
  margin: 0 auto;
  overflow-x: hidden;
}

/* не удалять!!! */
body::after {
  position: fixed;
  content: '';
  inset: 0;
  background-image: url('./img/noise.webp');
  background-repeat: repeat;
  opacity: 0.04;
  pointer-events: none;
  z-index: 999;
}

a {
  text-decoration: none;
  color: inherit;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

button {
  cursor: pointer;
  color: inherit;
  border-color: transparent;
  background-color: transparent;
}

/* =============================== */
/* открывает календарь при клике в любом месте всего поля инпута */
input[type='date']::-webkit-calendar-picker-indicator {
  position: absolute;
  width: auto;
  height: auto;
  inset: 0;
  color: transparent;
  background: transparent;
}

/* =============================== */

input:focus {
  outline: 1px solid #ccc;
  outline-offset: -3px;
}

fieldset {
  border: none;
}

.body_container {
  position: relative;
  max-width: 100%;
  margin: 0 auto;
  contain: paint;
  /* background-color: blue; */
}

/* .body_container::before {
  position: absolute;
  content: '';
  bottom: -940px;
  left: 50%;
  transform: translateX(-50%);
  width: 1110px;
  height: 1110px;
  background-color: red;
  border-radius: 50%;
  z-index: 1;
  filter: blur(600px);
  opacity: 0.1;
  pointer-events: none;
} */

.outer_container {
  max-width: 1848px;
  margin: 0 auto;
}

.container {
  max-width: 1680px;
  padding: 0 40px;
  margin: 0 auto;
}

.hidden {
  position: absolute;
  top: 0;
  left: -160%;
  width: 0;
  height: 0;
  overflow: hidden;
}

.visually-hidden {
  position: fixed;
  transform: scale(0);
}

/* слайдер секции hero */
.hero_swiper {
  height: 800px;
}

@media (max-width: 1330px) {
  .hero_swiper {
    height: 660px;
  }
}

@media (max-width: 1100px) {
  .hero_swiper {
    height: 600px;
  }
}

@media (max-width: 870px) {
  .hero_swiper {
    height: 450px;
  }
}

@media (max-width: 480px) {
  .hero_swiper {
    height: 300px;
  }
}
/* слайдер секции hero */

/* секция Warranty */
.warranty_swiper {
  overflow: visible !important;
}

.warranty_swiper > .swiper-scrollbar-horizontal {
  position: relative !important;
  margin-left: 20px;
  width: calc(100% - 6 * var(--swiper-scrollbar-sides-offset, 0.5%)) !important;
  height: 10px !important;

  left: var(--swiper-scrollbar-sides-offset, 0%) !important;
  background: #ffffff11 !important;
}

.warranty_swiper > .swiper-scrollbar-horizontal > .swiper-scrollbar-drag {
  height: 7px !important;
  top: 1px !important;
  background: #fff !important;
  opacity: 0.6;
}

.warranty_swiper > .swiper-wrapper {
  /* width: 3230px; */
  width: calc(100% + 40px);
  justify-content: space-between;
  margin-bottom: 10px;
}
/* секция Warranty */

/* секция About */
.about_swiper {
  overflow: visible !important;
}

.about_swiper > .swiper-scrollbar-horizontal {
  position: relative !important;
  margin-left: 20px;
  width: calc(100% - 6 * var(--swiper-scrollbar-sides-offset, 0.5%)) !important;
  height: 10px !important;

  left: var(--swiper-scrollbar-sides-offset, 0%) !important;
  background: #ffffff11 !important;
}

.about_swiper > .swiper-scrollbar-horizontal > .swiper-scrollbar-drag {
  height: 7px !important;
  top: 1px !important;
  background: #fff !important;
  opacity: 0.6;
}

.about_swiper > .swiper-wrapper {
  /* width: 100%; */
  width: calc(100% + 40px);
  justify-content: space-between;
  margin-bottom: 10px;
}
/* секция About */

/* слайдер секции clients */
.clients_swiper {
  overflow: visible !important;
}

.clients_swiper > .swiper-scrollbar-horizontal {
  width: calc(100% - 2 * var(--swiper-scrollbar-sides-offset, 0.5%)) !important;
  height: 10px !important;
  top: 140px !important;
  left: var(--swiper-scrollbar-sides-offset, 0%) !important;
  background: #ffffff11 !important;
}

.clients_swiper > .swiper-scrollbar-horizontal > .swiper-scrollbar-drag {
  height: 7px !important;
  top: 1px !important;
  background: #fff !important;
  opacity: 0.6;
}

.clients_swiper > .swiper-wrapper {
  width: 3850px;
}
/* секция Clients */

/* секция News */
.news_swiper {
  overflow: visible !important;
}

.news_swiper > .swiper-scrollbar-horizontal {
  /* width: calc(100% - 2 * var(--swiper-scrollbar-sides-offset, 0.5%)) !important; */
  width: 100% !important;
  height: 10px !important;
  top: 328px !important;
  left: var(--swiper-scrollbar-sides-offset, 0%) !important;
  background: #ffffff11 !important;
}

.news_swiper > .swiper-scrollbar-horizontal > .swiper-scrollbar-drag {
  height: 7px !important;
  top: 1px !important;
  background: #fff !important;
  opacity: 0.6;
}

/* .news_swiper > .swiper-wrapper {
  width: 3230px;
} */

@media (max-width: 900px) {
  .news_swiper > .swiper-scrollbar-horizontal {
    top: 318px !important;
  }
}
/* секция News */

/* текстовый редактор скрывает лого */
.tox .tox-statusbar__branding svg {
  display: none;
}

@media (max-width: 1300px) {
  .body_container::before {
    display: none;
  }
}

@media (max-width: 900px) {
  .container {
    padding: 0 20px;
  }
}
