.linedisapp {
  width: 100%;
  height: 121px;
  border-bottom: 1px solid #ffffff33;
}

@media (max-width: 900px) {
  .linedisapp {
    height: 61px;
  }
}