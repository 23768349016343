.overlay_show {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #0d0d1e80;
  backdrop-filter: blur(15px);
  z-index: 101;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: transform 0.2s ease-in-out, opacity 0.6s ease-in-out;
}

.overlay_hide {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #0d0d1e80;
  backdrop-filter: blur(15px);
  z-index: 101;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transform: scale(0);
  transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

.deptclbase {
  width: 100%;
  max-width: 1278px;
  padding-bottom: 80px;
}

.title_and_btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
  gap: 20px;
}

.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 25px;
  padding-bottom: 5px;
  color: var(--color);
}

.btn_group {
  display: flex;
  align-items: center;
  gap: 12px;
}

.btn_add_news {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 14px 30px;
  border: 1px solid #ffffff33;
  border-radius: 30px;

  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: #ffffff;
  transition: border-color 0.4s ease-in-out;
}

.btn_add_news:hover {
  border-color: #ffffff;
}

.btn_filter {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 12px 20px;
  outline: 1px solid #ffffff36;
  outline-offset: -1px;
  border-radius: 30px;

  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;

  object-fit: contain;
  transition: outline-color 0.4s ease-in-out, background-color 0.4s ease-in-out,
    color 0.4s ease-in-out;
}

.btn_filter:hover {
  outline-color: #ffffff;
}

.btn_filter:focus {
  color: #0d0d1e;
  background-color: #ffffff;
}

.table_header {
  width: 100%;
  display: grid;
  grid-template-columns:
    minmax(60px, 70px)
    minmax(140px, 150px)
    minmax(110px, 120px)
    minmax(170px, 190px)
    minmax(180px, 190px)
    minmax(180px, 190px)
    minmax(66px, 66px)
    minmax(90px, 90px)
    minmax(75px, 75px)
    minmax(40px, 40px);

  align-items: center;
  justify-content: space-between;
  padding: 12px 24px 12px 12px;
  background: #d9d9d909;
  border-radius: 5px;
}

.header_item {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
  opacity: 0.6;
  padding: 2px 8px;
}

.header_item:last-child {
  width: 24px;
  height: 24px;
  padding-top: 0;
  padding-left: 0;
  justify-self: end;
}

.filter_list {
  width: 100%;
  height: 85px;
  overflow: hidden;
  display: grid;
  grid-template-columns:
    minmax(50px, 70px)
    minmax(140px, 150px)
    minmax(75px, 95px)
    minmax(190px, 210px)
    minmax(150px, 160px)
    minmax(140px, 160px)
    minmax(180px, 200px);
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding-top: 18px;
  padding-bottom: 25px;
  padding-left: 12px;
  padding-right: 12px;
  margin-bottom: 25px;
  background-color: #06061c;
  border-radius: 5px;
  transition: height 0.4s ease-in-out, padding-top 0.4s ease-in-out, padding-bottom 0.4s ease-in-out;
}

.filter_list_hidden {
  width: 100%;
  height: 0;
  overflow: hidden;
  display: grid;
  grid-template-columns:
    minmax(50px, 70px)
    minmax(140px, 150px)
    minmax(75px, 95px)
    minmax(190px, 210px)
    minmax(150px, 160px)
    minmax(140px, 160px)
    minmax(180px, 200px);
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding-left: 12px;
  padding-right: 12px;
  margin-bottom: 25px;
  background-color: #06061c;
  border-radius: 5px;
  transition: height 0.4s ease-in-out, padding-top 0.4s ease-in-out, padding-bottom 0.4s ease-in-out;
}

.filter_list_item {
  position: relative;
}

.filter_list_select {
  padding-right: 8px;
  border-radius: 5px;
  background-color: #06061c;
  border: 1px solid #ffffff34;
}

.filter_select {
  appearance: none;
  background-image: url('../../../../img/arrow_down_select.svg');
  background-repeat: no-repeat;
  background-color: #fff;
  background-position: right center;
  
  width: 100%;
  padding: 8px 16px 8px 8px;
  color: #fff;
  border-radius: 5px;
  background-color: #06061c;
  border: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
}

.filter_select:focus {
  outline: none;
}

.filter_list_select:focus-within {
  /* box-shadow: 0 0 1px 1px #ffffffab; */
  outline: 1px solid #ffffff94;
  outline-offset: -2px;
}

.filter_select option {
  color: #fff;
  background-color: #1c1c2c;
}

.filter_input {
  width: 100%;
  padding: 8px 16px 8px 8px;
  color: #fff;
  border-radius: 5px;
  background-color: #06061c;
  border: 1px solid #ffffff34;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
}

.filter_input:focus {
  outline-color: #ffffff94;
  outline-offset: 0;
}

.clear_input {
  display: none;
}

.filter_input:focus + .clear_input {
  position: absolute;
  top: 7px;
  right: 7px;
  cursor: default;
  display: block;
}

.clbase_list {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.pagination {
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.pagination_btn_prev {
  width: 44px;
  height: 44px;
  background-image: url('../../../../img/btn_right.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 10px 16px;
  transform: rotate(180deg);
  opacity: 0.2;
  transition: opacity 0.3s ease-in-out;
}

.pagination_btn_prev:hover {
  opacity: 1;
}

.pagination_btn_next {
  width: 44px;
  height: 44px;
  background-image: url('../../../../img/btn_right.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 10px 16px;
  opacity: 0.2;
}

.pagination_btn_next:hover {
  opacity: 1;
}

.pagination_list {
  display: flex;
  align-items: center;
  gap: 4px;
}

.pagination_item {
  width: 44px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  padding: 10px 0;
  border-radius: 50%;
  background-color: transparent;
  opacity: 0.2;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.pagination_item:hover {
  background-color: #06061c;
  opacity: 1;
}

.pagination_item_active {
  width: 44px;
  height: 44px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  padding: 10px 0;
  border-radius: 50%;
  background-color: transparent;
  color: #fff;
  cursor: pointer;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.hidden {
  display: none;
}

@media (max-width: 1520px) {
  .table_header {
    display: none;
  }

  .filter_list {
    display: none;
  }

  .filter_list_hidden {
    display: none;
  }

  .btn_filter {
    display: none;
  }

  .clbase_list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
  }
}

@media (max-width: 990px) {
  .clbase_list {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 790px) {
  .btn_add_news {
    font-weight: 500;
    font-size: 12px;
    line-height: 13px;
  }
}
